import React from "react";
import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import Jumbotron from "../../common/Jumbotron";
import { MEDIA_MIN_WIDTH_768 } from "../../common/widths";
import { MAIN, MAIN_H3 } from "../../constants/styles.ts";
import HostSignUp from "./HostSignUp";
import Testimonials from "./Testimonials";
import TrendingCountries from "./TrendingCountries";
import TrendingDestinations from "./TrendingDestinations";

// TODO duplicated from destination/component/Content
const useStyles = makeStyles(
  (theme: Theme) => (
    {
      main: MAIN("0.1rem"),
      h3: { // modified from what is used in the destination FAQ section
        ...MAIN_H3,
        marginBottom: "14px",
      },
      bottomPoint: {
        clipPath: "polygon(100% 0%, 100% 75%, 50% 100%, 0% 75%, 0% 0%)",
        background: "#eee",
        paddingBottom: "20px",
      },
      nomatch: {
        margin: "2rem 0 1.5rem 0",
        fontSize: "1rem",
        [MEDIA_MIN_WIDTH_768]: {
          fontSize: "1.4rem",
        },
      },
    }
  ),
);

export function Content({ homePageData, urlParams, t }) {
  const classes = useStyles();
  const photo = { // 1440x500
    urls: [
      {
        width: 480,
        url: "https://s3.amazonaws.com/hsb-static/images/comfortable-king-sized-bed_480.jpg",
      },
      {
        width: 1280,
        url: "https://s3.amazonaws.com/hsb-static/images/comfortable-king-sized-bed_1280.jpg",
      },
      {
        width: 1440,
        url: "https://s3.amazonaws.com/hsb-static/images/comfortable-king-sized-bed_1440.jpg",
      },
      {
        width: 1920,
        url: "https://s3.amazonaws.com/hsb-static/images/comfortable-king-sized-bed_1920.jpg",
      },
    ],
    rgb: "#260c0c",
  };

  const { countryName, trendingDestinations, trendingCountries, testimonials } = homePageData;

  const structuredOrganization = {
    "@context": "https://schema.org",
    "@id": "https://www.homestaybay.com#about",
    "@type": "Organization",
    "areaServed": "Worldwide",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "email": "service@homestaybay.com",
        "contactType": "customer service",
        "areaServed": "Worldwide",
        "availableLanguage": ["English", "español", "한국어", "日本語", "简化"],
      },
    ],
    "description": t("homepage.trustedP"),
    "email": "service@homestaybay.com",
    "foundingDate": "2010-12-15",
    "image": "https://s3.amazonaws.com/hsb-static/images/logo-24.0.png",
    "logo": "https://s3.amazonaws.com/hsb-static/images/logo-24.0.png",
    "name": "HomestayBay",
    "numberOfEmployees": { "@type": "QuantitativeValue", "minValue": 1, "maxValue": 10 },
    "sameAs": ["https://twitter.com/homestaybay"],
    "slogan": t("homepage.trustedH"),
    "url": "https://www.homestaybay.com",
  };

  return (
    <>
      <Jumbotron
        h1Text={t("homepage.h")}
        photo={photo}
        photoAlt="Comfortable homestay bed"  // TODO translate
        t={t}
      />
      <main className={classes.main}>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredOrganization) }}
        />
        <h2>{t("homepage.trustedH")}</h2>
        <p>{t("homepage.trustedP")}</p>
        {
          trendingDestinations && <TrendingDestinations
            countryName={countryName}
            trendingDestinations={trendingDestinations}
            urlParams={urlParams}
            classes={classes}
            t={t}
          />
        }
        {
          trendingCountries && <TrendingCountries
            trendingCountries={trendingCountries}
            urlParams={urlParams}
            classes={classes}
            t={t}
          />
        }

        <HostSignUp lng={urlParams.lng} t={t}/>

        {testimonials && <Testimonials testimonials={testimonials}/>}
        {/*<h2>Help and tips to get you started</h2>*/}
        {/*<ul>*/}
        {/*  <li>How it works</li>*/}
        {/*  <li>Help for guests</li>*/}
        {/*  <li>Help for hosts</li>*/}
        {/*  <li>Book a homestay</li>*/}
        {/*  <li>Offer your home</li>*/}
        {/*  <li>Trust and safety</li>*/}
        {/*</ul>*/}
        {/*<p>*/}
        {/*  Try: <a href="#">&#x1f1e8;&#x1f1e6; Vancouver</a> · <a*/}
        {/*  href="#">&#x1f1e6;&#x1f1fa; Melbourne</a>*/}
        {/*</p>*/}
      </main>
    </>
  );
}
