import CreateCommonsSvg from "../component/CreativeCommonsSvg";
import React from "react";
import { Trans } from "react-i18next";

export default function HeroImageAttribution({ provenance, t, ...props }) {
  if (!provenance) {
    return null;
  }

  const { license, licenseUrl, contributor, imageBank } = provenance;

  if (license === "photo-bank") {
    return <div {...props}>
      <Trans
        i18nKey="Destination.PhotoByOn"
        values={{ name: contributor.name, site: imageBank.name }}
        t={t}
      > <a href={contributor.url} target="_blank"/><a href={imageBank.url} target="_blank"/>
      </Trans>
    </div>;
  }

  if (license.startsWith("cc-")) {
    return <div {...props}>
      <a href={contributor.url} target="_blank">{contributor.name}</a> <a
      href={licenseUrl} target="_blank"><CreateCommonsSvg/></a>
    </div>;
  }

  return null;
}
