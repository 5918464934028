import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) => (
    {
      root: {
        background: "#fff",
      },
    }
  ),
);

// Flatten markets into a structure usable by Autocomplete.
function flattenMarkets(
  t,
  markets: { [country: string]: string[] },
): { market: string, countryUiName: string, uiName: string }[] {
  if (Object.keys(markets).length === 0) {
    return [];
  }
  return Object.entries(markets)
    .map(([country: string, markets: string[]]) => {
      const countryUiName = t(`countries:${country}`);
      return markets.map(
        market => {
          return {
            market,
            countryUiName,
            uiName: t(`markets:${market}`),
          };
        },
      );
    })
    .reduce(
      (acc, val) => acc === null ? val : [...acc, ...val],
      null,
    )
    .sort(
      (a, b) => (
        a.countryUiName.localeCompare(b.countryUiName)
        || a.uiName.localeCompare(b.uiName)
      ),
    );
}

function selectMarketFromOptions(options, market): object | null {
  return options.find(option => option.market === market) || null;
}

export default function LocationSelection(
  {
    autofocus,
    openOnFocus,
    markets,
    market,
    onMarketChange,
    label = null, // resource key for the TextField label
    t,
  },
) {
  const classes = useStyles();
  const handleChange = (event, value, reason: string) => {
    onMarketChange(value.market);
  };

  const getOptionSelected = (
    { market: optionMarket },
    { market: valueMarket },
  ): boolean => {
    return optionMarket === valueMarket;
  };

  const options = flattenMarkets(t, markets);
  const selectedValue = selectMarketFromOptions(options, market);
  const i = <Autocomplete
    id="market"
    renderInput={
      params => (
        <TextField
          {...params}
          autoFocus={autofocus}
          label={t(label || "Location")}
          variant="outlined"
          className={classes.root}
          // inputRef={el => {
          //   if (el) {
          //     inputRef = el;
          //   }
          // }}
        />
      )
    }
    options={options}
    groupBy={option => option.countryUiName}
    getOptionLabel={option => option.uiName}
    getOptionSelected={getOptionSelected}
    defaultValue={selectedValue}
    disableClearable={true}
    openOnFocus={openOnFocus}
    selectOnFocus
    onChange={handleChange}
    style={{ width: 200 }}
    value={selectedValue}
  />;

  // const keyHandlers = {
  //   FOCUS_PRIMARY_SEARCH: event => {
  //     inputRef.focus();
  //     event.preventDefault();
  //   },
  // };

  // return <GlobalHotKeys handlers={keyHandlers}>{i}</GlobalHotKeys>;
  return i;
}
