import React from "react";
import { Link as RouterLink } from "react-router-dom";
import parse from "html-react-parser";
import { Button, Divider, Grid, Link, makeStyles } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { makeContactLink, makeHelpAudience } from "../../common/links";
import { encodeUrlParams } from "../../common/url";
import { REGISTER_HOST_PATH } from "../../constants/app";
import { MAIN } from "../../constants/styles.ts";
import ArticleBoxes from "./ArticleBoxes";
import { HelpBreadcrumb } from "./Breadcrumbs";
import { HELP_LINK } from "./styles";

const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      root: {
        flexGrow: 1,
      },
      helpLink: HELP_LINK,
      inlineLink: {
        // link inlined with article text
        // very similar to HELP_LINK above, can we merge them?
        fontWeight: 600,
        color: "#212529",
        textDecoration: "underline",
        textDecorationColor: "#717579",
        textDecorationThickness: "0.1em",
        textUnderlineOffset: "0.1em",
        "&:hover": {
          color: "#212529",
          textDecorationColor: "#717579",
        },
      },
      boxLink: {
        padding: "1.5em 0",
        borderBottom: "1px solid #ddd",
        color: "#212529",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none", // affects <Link>
          backgroundColor: "#f8f8f8", // affects <Box>
        },
        "&:hover svg": {
          color: "#fff",
          backgroundColor: "#6ac82c",
          borderRadius: "50%",
        },
        "& p": {
          marginLeft: "0.5em",
        },
      },
      boxLinkArrow: {
        color: "#6ac82c",
      },
      excerpt: {
        color: "#818589",
        margin: 0,
        fontSize: "0.9em",
      },
      articleLink: {
        margin: 0,
      },
      tabs: {
        position: "absolute",
        bottom: "0",
        display: "flex",
      },
      main: MAIN(),
    },
  ),
);

// Parse a block of HTML, replacing <a> tags with react target=_blank links.
// This avoids the "<div dangerouslySetInnerHTML={{ __html: content }}/>" approach.
function Content({ content, onSignUpGuestOpen, lng, classes }) {
  // Source:
  // https://stackoverflow.com/questions/68503936/html-react-parser-replace-dom-node-with-another-dom-node-containing-inner-text
  return parse(content, {
      replace: domNode => {
        const { name, attribs, children } = domNode;
        if (name !== "a" || !attribs || !attribs.href) {
          return domNode;
        }

        // Handle magic strings which resolve to modal dialogs.
        if (attribs.href === "host-sign-up") {
          return <RouterLink
            to={{
              pathname: REGISTER_HOST_PATH,
              search: encodeUrlParams({ lng }),
            }}
          >{children[0].data}</RouterLink>;
        }
        if (attribs.href === "guest-sign-up") {
          return <Link
            href="#"
            onClick={onSignUpGuestOpen}
            className={classes.inlineLink}
          >{children[0].data}</Link>;
        }

        return <Link
          href={attribs.href}
          target="_blank"
          className={classes.inlineLink}
        >
          {children[0].data}
        </Link>;
      },
    },
  );
}

function Sections(
  { sections, setPath, urlParams, onSignUpGuestOpen, classes },
) {
  return sections.map(
    (section, i: Number) => <section key={`section-${i}`}>
      <h2 id={section.title}>{section.title}</h2>
      <Content
        content={section.content}
        onSignUpGuestOpen={onSignUpGuestOpen}
        classes={classes}
      />
      {
        section.articles && <ArticleBoxes
          articles={section.articles}
          setPath={setPath}
          urlParams={urlParams}
          classes={classes}
        />
      }
    </section>,
  );
}

export function ArticleContent(
  {
    data,
    setPath,
    urlParams,
    market,
    markets,
    onSignUpGuestOpen,
    t,
    language,
  },
) {
  const classes = useStyles();
  const { article } = data;
  const { title, topic, content, sections, related } = article;

  const handleSignUpGuestOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onSignUpGuestOpen(e, market, markets);
  };

  return (
    <>
      <main className={classes.main}>
        <HelpBreadcrumb
          parents={topic || []}
          title={title}
          urlParams={urlParams}
          setPath={setPath}
          t={t}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <h1>{title}</h1>
            {
              content && <Content
                content={content}
                onSignUpGuestOpen={handleSignUpGuestOpen}
                lng={language}
                classes={classes}
              />
            }
            {
              sections && <Sections
                sections={sections}
                setPath={setPath}
                urlParams={urlParams}
                onSignUpGuestOpen={handleSignUpGuestOpen}
                classes={classes}
              />
            }
            {
              related && <>
                <Divider/>
                <h2>{t("RelatedArticles")}</h2>
                <ArticleBoxes
                  articles={related}
                  setPath={setPath}
                  urlParams={urlParams}
                  classes={classes}
                />
              </>
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <h2>{t("StillNeedHelp")}</h2>
            <Button
              variant="outlined"
              color="primary"
              href={makeContactLink(market, language)}
              target="_blank"
            >{t("ContactUs")}</Button>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
