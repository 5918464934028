import { format } from "util";
import { HELP_PATH, REGISTER_HOST_PATH } from "../constants/app";

// These are languages that are supported on the webserver. If the user is
// interacting in a language not in this list, any webserver links should
// instead map to the 'en' endpoint.
export const LEGACY_LANGUAGES = ["en", "ko", "ja", "zh", "es"];

export function mapLocale(locale: string): string {
  if (LEGACY_LANGUAGES.includes(locale)) {
    return locale;
  }
  return "en";
}

export function makeHomeLink(locale: string): string {
  return format(process.env.REACT_APP_URL_HOME, mapLocale(locale));
}

export function makeHostSignUpMarketLink(locale: string, market: string): string {
  return format(process.env.REACT_APP_URL_HOST_SIGN_UP, mapLocale(locale), market);
}

export function makeGuestSignUpMarketLink(locale: string, market: string): string {
  return format(process.env.REACT_APP_URL_GUEST_SIGN_UP, mapLocale(locale), market);
}

export function makeForgotPasswordLink(locale: string): string {
  return format(process.env.REACT_APP_URL_FORGOT_PASSWORD, mapLocale(locale));
}

export function makeMyAccountLink(locale: string): string {
  return format(process.env.REACT_APP_URL_MY_ACCOUNT, mapLocale(locale));
}

export function makeAboutLink(locale: string): string {
  return format(process.env.REACT_APP_URL_ABOUT, mapLocale(locale));
}

export function makePrivacyLink(locale: string): string {
  return format(process.env.REACT_APP_URL_PRIVACY, mapLocale(locale));
}

export function makeTermsLink(locale: string): string {
  return format(process.env.REACT_APP_URL_TERMS, mapLocale(locale));
}

export function makeGuestListLink(market: string, locale: string): string {
  return format(process.env.REACT_APP_URL_GUESTS, mapLocale(locale), market);
}

interface IUser {
  roles: string[], // HOST, GUEST
}

export function makeHelpAudience(user: null | IUser): string {
  const {roles = []} = user || {};
  const isHost = roles.includes("HOST");
  if (isHost) {
    return "hosts";
  }
  return "guests";
}

export function makeHelpLink(user: null | IUser, locale: string): string {
  const audience = makeHelpAudience(user);
  return `${HELP_PATH}?lng=${locale}&audience=${audience}`;
}

export function makeRegisterHostLink(locale: string): string {
  return `${REGISTER_HOST_PATH}?lng=${locale}`;
}

export function makeContactLink(subdomain: string, locale: string): string {
  return format(process.env.REACT_APP_URL_CONTACT, mapLocale(locale), subdomain);
}
