import { makeApiserverClient } from "./factory";
import { object } from "prop-types";

export type AuthenticationResult = "authenticated" | "invalid_credentials" | "network_error";
export type User = object;

export default async function authenticateUser(
  email: string,
  password: string,
): Promise<{ user: ?User, result: AuthenticationResult }> {
  const client = makeApiserverClient(
    process.env.REACT_APP_JAVA_API_SERVER_BASE_URL,
    {},
  );
  console.log("authenticating user:", email);
  const data = { email, password };
  try {
    const response = await client.post("/auth/sign-in", data);
    if (response.status === 200) {
      console.log("authenticated user:", email);
      return { result: "authenticated", user: response.data };
    }
    console.error("network error authenticating", response);
    return { result: "network_error", user: null };
  } catch (error) {
    if (!error.response || error.response.status !== 403) {
      console.error("network error authenticating", error);
      return { result: "network_error", user: null };
    }
    console.log("invalid authentication credentials");
    return { result: "invalid_credentials", user: null };
  }
};
