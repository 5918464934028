import React from "react";
import { CityPile } from "../../destination/component/CityPile";

export default function TrendingCountries({ trendingCountries, urlParams, classes, t }) {
  return <>
    <h2>{t("homepage.countriesH")}</h2>
    <h3 className={classes.h3}>{t("homepage.countriesP")}</h3>
    <CityPile cities={trendingCountries} urlParams={urlParams}/>
  </>;
}
