import { Trans } from "react-i18next";
import { makeGuestListLink } from "../../common/links";
import React from "react";

function makeGuestHomeCountries(placeName, guestHomeCountries, t): string {
  return t(
    "Destination.S.HomeCountries",
    {
      name: placeName,
      countries: [
        guestHomeCountries.slice(0, guestHomeCountries.length - 1).join(t(", ")),
        guestHomeCountries[guestHomeCountries.length - 1],
      ].join(t(" and ")),
    },
  );
}

function makeHostsWithPet(placeName: string, hostsWithPet, t): string {
  return t(
    "Destination.S.HostsWithPet",
    { name: placeName, pct: hostsWithPet },
  );
}

function makeHostsWithChildren(placeName: string, hostsWithChildren, t): string {
  return t(
    "Destination.S.HostsWithChildren",
    { name: placeName, pct: hostsWithChildren },
  );
}

function makeNonSmoking(placeName: string, nonSmoking, t): string {
  return t(
    "Destination.S.NonSmoking",
    { name: placeName, pct: nonSmoking },
  );
}

function makeSingleOccupancy(placeName: string, singleOccupancy, t): string {
  return t(
    "Destination.S.SingleOccupancy",
    { name: placeName, pct: singleOccupancy },
  );
}

function makeGuestsSearchingInMarket(
  placeName: string, guestsSearchingInMarket, marketName, t, language,
) {
  return <Trans
    i18nKey="Destination.S.GuestsSeeking"
    values={{ count: guestsSearchingInMarket, name: placeName }}
    t={t}
  > <a href={makeGuestListLink(marketName, language)} target="_blank"/>
  </Trans>;
}

export default function Stats(
  {
    placeName,
    placePageData: {
      guestHomeCountries,
      guestsSearchingInMarket,
      market: { name: marketName } = {},
      stats: {
        hostsWithPet,
        hostsWithChildren,
        nonSmoking,
        singleOccupancy,
      } = {},
    } = {},
    t,
    i18n,
  },
) {
  const items = [];
  if (guestHomeCountries && guestHomeCountries.length >= 2) {
    items.push(
      <li key="statsGuestHomeCountries">
        {makeGuestHomeCountries(placeName, guestHomeCountries, t)}
      </li>,
    );
  }
  if (!!hostsWithPet) {
    items.push(
      <li key="statsHostsWithPet">
        {makeHostsWithPet(placeName, hostsWithPet, t)}
      </li>,
    );
  }
  if (!!hostsWithChildren) {
    items.push(
      <li
        key="statsHostsWithChildren">
        {makeHostsWithChildren(placeName, hostsWithChildren, t)}
      </li>,
    );
  }
  if (!!nonSmoking) {
    items.push(
      <li key="statsNonSmoking">{makeNonSmoking(placeName, nonSmoking, t)}</li>,
    );
  }
  if (!!singleOccupancy) {
    items.push(
      <li key="statsSingleOccupancy">
        {makeSingleOccupancy(placeName, singleOccupancy, t)}
      </li>,
    );
  }
  if (!!guestsSearchingInMarket) {
    items.push(
      <li key="statsGuestsSearchingInMarket">
        {makeGuestsSearchingInMarket(
          placeName, guestsSearchingInMarket, marketName, t, i18n.language,
        )}
      </li>,
    );
  }

  if (!items.length) {
    return null;
  }
  return <>
    <h2>{t("Destination.Stats", { name: placeName })}</h2>
    <ul>{items}</ul>
  </>;
}
