import { format } from "date-fns";

export function parseDate(s?: string): ?Date {
  if (s === null || s === "") {
    return null;
  }
  return new Date(s);
}

export function marshalDate(d?: Date): ?string {
  if (d === null) {
    return "";
  }
  return format(d, "yyyy-MM-dd");
}

// Format a date as "d MMM yyyy" for a given locale.
export function formatDateDMY(d: Date, locale: string): string {
  if (locale === "ko" || locale === "ja" || locale === "zh") {
    return formatDateAsian(d, locale);
  }
  const month = d.toLocaleString(locale, { month: "short" });
  return `${d.getDate()} ${month} ${d.getFullYear()}`;
}

export function formatDateAsian(d: Date, locale: string): string {
  // ko: 2023년 05월 08일, ja: 2008年12月31日
  return d.toLocaleString(
    locale,
    { year: "numeric", month: "short", day: "numeric" },
  );
}
