import React from "react";
import { HowItWorksCards } from "./HowItWorksCards";

export function HowItWorks({ cards, classes, t }) {
  return <section>
    <h2 className={classes.h2}>{t("starthosting:howItWorks.h1")}</h2>
    <p>{t("starthosting:howItWorks.h2")}</p>
    <HowItWorksCards cards={cards} classes={classes}/>
  </section>;
}
