import { Link } from "react-router-dom";
import React from "react";
import { encodeUrlParams } from "../common/url";

export default function InternalLink(
  {
    path,
    setPath,
    urlParams, // lng, audience, ccy
    children,
    ...params
  },
): Link {
  return <Link
    to={{
      pathname: path,
      search: encodeUrlParams(urlParams),
    }}
    onClick={
      (e: Event) => {
        if (setPath) {
          setPath(e, path, encodeUrlParams(urlParams));
        }
        window.scrollTo(0, 0);  // scroll to the top when re-rendering
      }
    }
    id={`link-${path}`}
    {...params}
  >{children}</Link>;
}
