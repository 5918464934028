import React, { Component } from "react";
import SearchOption from "../../component/SearchOption.jsx";
import Checkbox from "../../component/Checkbox.jsx";

const mealChoices = ["BREAKFAST", "LUNCH", "DINNER"];

export default class SearchMealsOption extends Component {
  constructor(props) {
    super(props);
    this.isDirtyState = this.isDirtyState.bind(this);
    this.clearState = this.clearState.bind(this);
    this.revertState = this.revertState.bind(this);
    this.saveState = this.saveState.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    const { meals } = props;
    this.state = { meals };
  }

  isDirtyState() {
    const { meals } = this.state;
    return meals.length > 0;
  }

  clearState() {
    this.setState({ meals: [] });
  }

  revertState() {
    const { meals } = this.props;
    this.setState({ meals });
  }

  saveState() {
    const { onStateChange } = this.props;
    const { meals } = this.state;
    onStateChange({ meals });
  }

  handleCheckboxChange(event) {
    const { meals } = this.state;
    const { name, checked } = event.target;
    if (checked) {
      this.setState({ meals: [...meals, name] });
    } else {
      this.setState({ meals: meals.filter(meal => meal !== name) });
    }
  }

  makeButtonLabel() {
    const { t } = this.props;
    const { meals } = this.state;
    if (meals.length === 0) {
      return t("Meals");
    }
    return mealChoices
      .filter(meal => meals.includes(meal))
      .map(meal => t(`meal.${meal}`))
      .join(", ");
  }

  render() {
    const { t } = this.props;
    const { meals } = this.state;
    return (
      <SearchOption
        t={t}
        label={this.makeButtonLabel()}
        title={t("SearchMealsTitle")}
        isDirtyState={this.isDirtyState}
        clearState={this.clearState}
        revertState={this.revertState}
        saveState={this.saveState}
      >
        <form>
          {
            mealChoices.map(
              (meal: string) => <Checkbox
                key={meal}
                name={meal}
                label={t(`meal.${meal}`)}
                isSelected={meals.includes(meal)}
                onCheckboxChange={this.handleCheckboxChange}
              />,
            )
          }
        </form>
      </SearchOption>
    );
  }
}
