import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  HERO_IMAGE,
  HERO_IMAGE_CONTAINER,
  HERO_IMAGE_SCREEN,
  JUMBOTRON,
  JUMBOTRON_CONTENT,
} from "../constants/styles";
import { MEDIA_MIN_WIDTH_600 } from "./widths";
import HeroImage from "./HeroImage";
import HeroImageAttribution from "./HeroImageAttribution";

const useStyles = makeStyles(
  {
    jumbotron: JUMBOTRON("500px", "320px"),
    jumbotronContent: JUMBOTRON_CONTENT,
    heroImageContainer: HERO_IMAGE_CONTAINER,
    heroImage: HERO_IMAGE,
    heroImageScreen: HERO_IMAGE_SCREEN,
    heroImageAttribution: {
      position: "absolute",
      bottom: "5px",
      right: "5px",
      background: "rgba(0, 0, 0, 0.3)",
      padding: "0 5px",
      color: "#bbb",
      fontSize: "0.6rem",
      [MEDIA_MIN_WIDTH_600]: {
        color: "#aaa",
        fontSize: "0.75rem",
      },
      borderRadius: "3px",
      "& a": {
        textDecoration: "underline",
        color: "#bbb",
        [MEDIA_MIN_WIDTH_600]: {
          color: "#aaa",
        },
        "& svg": {
          display: "inline-block",
          verticalAlign: "middle",
          height: "12px",
          [MEDIA_MIN_WIDTH_600]: {
            height: "16px",
          },
        },
      },
    },
  },
);

export default function Jumbotron(
  { h1Text, h2Text, photo, photoAlt, t },
) {
  const classes = useStyles();
  const { provenance } = photo;
  return <div className={classes.jumbotron}>
    <div className={classes.heroImageContainer}>
      <HeroImage photo={photo} alt={photoAlt} classes={classes}/>
    </div>
    <div className={classes.heroImageScreen}/>
    <HeroImageAttribution
      provenance={provenance}
      t={t}
      className={classes.heroImageAttribution}
    />
    <div className={classes.jumbotronContent}>
      <h1>{h1Text}</h1>
      {h2Text && <h2>{h2Text}</h2>}
    </div>
  </div>;
}
