// Common widths where elements transition between mobile/desktop.

// 1050 px is when the nav bar height reduces
export const NAVBAR_HEIGHT_REDUCES = 1050;
export const NAVBAR_SHOW_HOST_SIGNUP = 700;

// Displayport width at which the "Enter your dates for availability" string
// can be shown. Otherwise a more compact form is rendered.
export const SEARCHBAR_ENTER_DATES_WIDTH = 440;

export const WIDTH_480 = 480;
export const IMAGE_WIDTHS = [WIDTH_480, 1280, 1440, 1920];

export const MEDIA_MIN_WIDTH_480 = "@media (min-width: 480px)";
export const MEDIA_MIN_WIDTH_600 = "@media (min-width: 600px)";
export const MEDIA_MIN_WIDTH_768 = "@media (min-width: 768px)";
export const MEDIA_MIN_WIDTH_960 = "@media (min-width: 960px)";
export const MEDIA_MIN_WIDTH_1128 = "@media (min-width: 1128px)";
export const MEDIA_MIN_WIDTH_1256 = "@media (min-width: 1256px)";
