import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import { isMarketSelected } from "../common/market";
import CheckInSelection from "./CheckInSelection";
import GuestCountSelection from "./GuestCountSelection";
import LocationSelection from "./LocationSelection";

export default function PrimarySearchDialog(props) {
  const { saveTransKey, onClose, onChange, openFocus, locale, markets, t } = props;
  const [market, setMarket] = useState(props.market);
  const [checkIn, setCheckIn] = useState(props.checkIn);
  const [guestCount, setGuestCount] = useState(props.guestCount);
  const minDate = new Date();

  const resetState = () => {
    setMarket(props.market);
    setCheckIn(props.checkIn);
    setGuestCount(props.guestCount);
  };

  const handleCancel = () => {
    resetState();
    onClose();
  };

  const isCheckInValid = () => {
    return !checkIn || checkIn >= minDate;
  };

  const isSaveButtonEnabled = (): boolean => {
    return isMarketSelected(market, markets) && isCheckInValid();
  };

  const handleSave = () => {
    if (!isSaveButtonEnabled()) {
      return;
    }
    onChange(market, checkIn, guestCount);
    onClose();
  };

  const handleMarketChange = (market) => {
    setMarket(market);
  };

  const handleCheckInChange = (checkIn) => {
    setCheckIn(checkIn);
  };

  const handleGuestCountChange = (guestCount) => {
    setGuestCount(guestCount);
  };

  return (
    <Dialog disableBackdropClick open={openFocus !== null} onClose={onClose}>
      <DialogTitle>{t("WhatsYourPlan")}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <LocationSelection
                autofocus={openFocus === "market"}
                openOnFocus={false}
                markets={markets}
                market={market}
                onMarketChange={handleMarketChange}
                t={t}
              />
            </Grid>
            <Grid item>
              <CheckInSelection
                style={{ width: 200 }}
                autofocus={openFocus === "checkIn"}
                checkIn={checkIn}
                minDate={minDate}
                locale={locale}
                onCheckInChange={handleCheckInChange}
                t={t}
              />
            </Grid>
            <Grid item>
              <GuestCountSelection
                style={{ width: 200 }}
                autofocus={openFocus === "guestCount"}
                guestCount={guestCount}
                onGuestCountChange={handleGuestCountChange}
                maxGuests={5}
                t={t}
              />
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!isSaveButtonEnabled()}
        >
          {t(saveTransKey)}
        </Button>
      </DialogActions>
    </Dialog>

  );
}
