export function findCookie(name: string): string | null {
  const cookie = document.cookie
    .split("; ")
    .find(row => row.startsWith(`${name}=`));
  if (cookie) {
    return cookie.split("=", 2)[1];
  }
  return null;
}

// Fetch the market cookie. This is the market ID, not the slug.
export function readMarketCookie(): string | null {
  return findCookie("mkt");
}

export function makeLanguageCookie(language: string): string {
  return makeCookie("lng", language);
}

// Create a cookie with a 90 day expiration.
export function makeCookie(name: string, value: string): string {
  return `${name}=${value}; Max-Age=7776000; Domain=.homestaybay.com; Secure; SameSite=Strict; Path=/`;
}
