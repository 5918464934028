import React from "react";
import LinkPile from "./LinkPile";

export default function NearbyCities(
  { cities, language, currency, setPlaceSlug, t },
) {
  return <LinkPile
    items={cities}
    makeSecondaryText={
      item => `${item.distanceKm} km · ${t("NHomestay", { count: item.count })}`
    }
    language={language}
    currency={currency}
    setPlaceSlug={setPlaceSlug}
    t={t}
  />;
}
