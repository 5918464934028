import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  AccountBalance,
  Explore,
  Height,
  Hotel,
  PeopleAlt,
  Today,
  TrendingUp,
} from "@material-ui/icons";
import { H3, MAIN } from "../../constants/styles";
import { Benefits } from "./Benefits";
import { HowItWorks } from "./HowItWorks";
import { Jumbotron } from "./Jumbotron";
import { Questions } from "./Questions";

const useStyles = makeStyles(
  {
    main: MAIN("0.1rem"), // H3 used with bold
    h3: H3,
    // card styling
    card: {},
    cardMedia: {
      height: 0,
    },
    cardHeader: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      objectFit: "cover",
      backgroundPosition: "50% 50%",
      width: "100%",
      height: "100%",
      zIndex: 0,
      color: "#fff",
    },
    cardAvatar: {
      backgroundColor: "#6ac82c",
    },
  },
);

const HOW_IT_WORKS_IMAGES = ["pencil", "texting", "calendar", "tea"];
const QUESTIONS = ["right-home", "how-much", "payment", "service-cost", "welcome"];

export function Content({ lng, defaultSelectedMarket, markets, t }) {
  const classes = useStyles();
  const howItWorksCards = HOW_IT_WORKS_IMAGES.map(
    (name: string, i: number) => (
      {
        title: t(`starthosting:howItWorks.card${i}T`),
        description: t(`starthosting:howItWorks.card${i}D`),
        img: `https://s3.amazonaws.com/hsb-static/images/banner-${name}_960.jpg`,
      }
    ),
  );
  const benefits = [
    {
      icon: <Today/>,
      title: t("starthosting:benefits.fs1"),
      value: t("starthosting:benefits.fs2"),
    },
    {
      icon: <Height/>,
      title: t("starthosting:benefits.sr1"),
      value: t("starthosting:benefits.sr2"),
    },
    {
      icon: <Hotel/>,
      title: t("starthosting:benefits.sg1"),
      value: t("starthosting:benefits.sg2"),
    },
    {
      icon: <PeopleAlt/>,
      title: t("starthosting:benefits.lc1"),
      value: t("starthosting:benefits.lc2"),
    },
    {
      icon: <Explore/>,
      title: t("starthosting:benefits.ce1"),
      value: t("starthosting:benefits.ce2"),
    },
    {
      icon: <AccountBalance/>,
      title: t("starthosting:benefits.le1"),
      value: t("starthosting:benefits.le2"),
    },
    {
      icon: <TrendingUp/>,
      title: t("starthosting:benefits.ei1"),
      value: t("starthosting:benefits.ei2"),
    },
  ];
  return <>
    <Jumbotron
      lng={lng}
      defaultSelectedMarket={defaultSelectedMarket}
      markets={markets}
      t={t}
    />
    <main className={classes.main}>
      <HowItWorks cards={howItWorksCards} classes={classes} t={t}/>
      <Benefits benefits={benefits} classes={classes} t={t}/>
      <Questions questions={QUESTIONS} lng={lng} classes={classes} t={t}/>
    </main>
  </>;
}
