import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import React from "react";

export function HowItWorksCard({ stepNumber, title, description, img, classes }) {
  return <Card className={classes.card}>
    <CardHeader
      avatar={
        <Avatar aria-label={stepNumber} className={classes.cardAvatar}>{stepNumber}</Avatar>
      }
      title={title}
      titleTypographyProps={{ variant: "subtitle1" }}
      className={classes.cardHeader}
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(32, 32, 32, 0.9), rgba(64, 64, 64, 0.5), rgba(128, 128, 128, 0.4), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('${img}')`,
      }}
    />
    <CardContent>
      <Typography variant="p" color="textPrimary" component="p">
        {description}
      </Typography>
    </CardContent>
  </Card>;
}
