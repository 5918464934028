import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#fefefe",
    border: "1px solid #ddd",
    color: "#6ac82c",
  },
}));

export default function CircleIcon({ icon }) {
  const classes = useStyles();
  return <Avatar className={classes.avatar}>{icon}</Avatar>;
}
