import { makeLanguageCookie } from "./cookie";

export type paramsObj = {
  language: string
}

// Set a language cookie from user-selected params to remember the
// language on future page loads.
export function setLngCookie(params: paramsObj) {
  const {language} = params;
  if (language) {
    document.cookie = makeLanguageCookie(language);
  }
}
