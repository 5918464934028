import React from "react";
import { DialogContentText } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeGuestSignUpMarketLink } from "../common/links";
import LocationSelection from "./LocationSelection";

export default function SignUpGuestDialog(
  { markets, market, setMarket, t, onClose, open, locale },
) {
  const isMarketValid = () => {
    return !!market;
  };

  const isSaveDisabled = () => {
    return !isMarketValid();
  };

  const handleSave = () => {
    if (isSaveDisabled()) {
      return;
    }
    window.open(makeGuestSignUpMarketLink(locale, market), "_blank");
    onClose();
  };

  const handleMarketChange = (market) => {
    setMarket(market);
  };

  return (
    <Dialog disableBackdropClick open={open} onClose={onClose}>
      <DialogTitle>{t("guestSignUp.Where1")}</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">{t("guestSignUp.Where2")}</DialogContentText>
        <FormControl component="fieldset">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <LocationSelection
                autofocus={true}
                openOnFocus={false}
                markets={markets}
                market={market}
                onMarketChange={handleMarketChange}
                t={t}
              />
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={isSaveDisabled()}
        >
          {t("GetStarted")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
