import axios, { AxiosInstance } from "axios";
import https from "https";

export function makeApiserverClient(
  baseUrl: string,
  headers,
): AxiosInstance {
  const agent = new https.Agent();
  return axios.create({
    httpsAgent: agent,
    baseURL: baseUrl,
    timeout: 4000,
    headers,
    withCredentials: true, // this includes the cookie containing the session
  });
}
