import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles";
import { makeHostSignUpMarketLink } from "../../common/links";
import { isMarketSelected } from "../../common/market";
import { WIDTH_480 } from "../../common/widths";
import LocationSelection from "../../component/LocationSelection";
import { useViewport } from "../../component/ViewportProvider";
import { MAIN_H1, MAIN_H2, MAIN_H3 } from "../../constants/styles";

const useStyles = makeStyles(
  (theme: Theme) => (
    {
      hostSignUp: {
        backgroundColor: "#f8f8f8",
        // backgroundColor: "#f0f0f0",
        // backgroundColor: "#fff6e2",
        // backgroundColor: "#ffefd5",
        // backgroundColor: "#f8f0e3", // off white
        // backgroundColor: "#fff",
        // border: "1px solid #6ac82c",
        border: "1px solid #888",
        textAlign: "left",
        textWrap: "wrap",  // undo 'balance' in parent
        borderRadius: "6px",
        boxShadow: "rgba(255, 255, 255, 0.5) 0px 5px 15px",
        // boxShadow: "rgba(106, 200, 44, 0.25) 0px 5px 15px",
        display: "block",
        padding: "20px 20px",
        "& a": {
          color: "#fff",
          margin: "18px 0 0 0",
        },
        "& h1": MAIN_H1,
        "& h2": MAIN_H2("0.1rem"),
        "& h3": MAIN_H3,
      },
      submit: {
        marginTop: "1rem",
      },
      hostSignUpGrid: {
        padding: "1rem 0",
      },
    }
  ),
);

export default function Dialog({ lng, defaultSelectedMarket, markets, t }) {
  const classes = useStyles();
  const { width } = useViewport();
  const [market, setMarket] = useState(defaultSelectedMarket);

  const isSaveButtonEnabled = (): boolean => {
    return isMarketSelected(market, markets);
  };

  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!isSaveButtonEnabled()) {
      return;
    }
    window.open(makeHostSignUpMarketLink(lng, market), "_blank");
  };

  // The h2 has a hardcoded margin to override the h2 margin set by the Content parent.
  return <div className={classes.hostSignUp}>
    <h2 style={{ margin: 0 }} className={classes.h2}>{t("starthosting:cta1")}</h2>
    <h3 className={classes.textleaf}>{t("starthosting:cta2")}</h3>
    <LocationSelection
      autofocus={width > WIDTH_480} // don't autofocus on small screens
      openOnFocus={false}
      markets={markets}
      market={market}
      onMarketChange={setMarket}
      label="hostSignUp.chooseLabel"
      t={t}
    />
    <Button
      onClick={handleSave}
      color="primary"
      variant="contained"
      className={classes.submit}
      disabled={!isSaveButtonEnabled()}
    >
      {t("starthosting:starthosting")}
    </Button>
  </div>;
}
