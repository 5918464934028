import { readMarketCookie } from "./cookie";

// Map of country to its markets.
export interface CountryMarketsMap {
  [key: string]: string[]
}

// Check if a selected market matches one of the system markets.
export function isMarketSelected(
  market: string | null,
  markets: CountryMarketsMap | null,
): boolean {
  if (!market || !markets) {
    return false;
  }
  return !![...Object.entries(markets)].find( // str => list of str (country to list of markets)
    ([_, countryMarkets]) => !!countryMarkets.find(countryMarket => market === countryMarket),
  );
}

export function resolveMarket(
  user: Object | null,
  defaultMarket: string | null = "vancouver",
): string | null {
  if (user !== null) {
    return user.market;
  }
  return readMarketCookie() || defaultMarket;
}
