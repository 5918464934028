import React from "react";
import { Box, Grid } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import InternalLink from "../../component/InternalLink";

function ArticleLinks({ articles, urlParams, setPath, classes }) {
  return articles.map(
    // TODO cannot enclose Grid inside P
    article => <p key={`topic-article-${article.path}`}>
      <Grid container direction="row" alignItems="baseline" wrap="nowrap" spacing={1}>
        <Grid item>
          <DescriptionOutlined color="action" fontSize="small"/>
        </Grid>
        <Grid item>
          <InternalLink
            path={article.path}
            urlParams={urlParams}
            setPath={setPath}
            className={classes.helpLink}
          >
            {article.label}
          </InternalLink>
        </Grid>
      </Grid>
    </p>,
  );
}

function TopicLinks({ topics, urlParams, setPath, classes }) {
  return topics.map(
    topic => <p key={`topic-child-${topic.path}`}>
      <InternalLink
        path={topic.path}
        urlParams={urlParams}
        setPath={setPath}
        className={classes.helpLink}
      >
        {topic.label}
      </InternalLink>
    </p>,
  );
}

export default function TopicsGrid({ topics, urlParams, setPath, audience, classes }) {
  return topics.filter(
    topic => !topic.audiences || topic.audiences.some(
      topicAudience => topicAudience.path === audience,
    ),
  ).map(
    topic => {
      return (
        <Grid item xs={12} md={6} lg={4} key={`topic-${topic.path}`}>
          <Box>
            <section>
              <h2>{topic.label}</h2>
              {
                topic.children &&
                <TopicLinks
                  topics={topic.children}
                  urlParams={urlParams}
                  setPath={setPath}
                  classes={classes}
                />
              }
              {
                topic.articles &&
                <ArticleLinks
                  articles={topic.articles}
                  urlParams={urlParams}
                  setPath={setPath}
                  classes={classes}
                />
              }
            </section>
          </Box>
        </Grid>
      );
    },
  );
}
