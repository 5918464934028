export function parseIntWithDefault(
  value?: string,
  defaultValue: number,
): number {
  const i = parseInt(value, 10);
  if (isNaN(i)) {
    return defaultValue;
  }
  return i;
}

// Parse a string into a float, handling empty strings as nan.
export function parseFloatStrict(value: string) {
  if (value.trim() === "") {
    return NaN;
  }
  return Number(value);
}