import React, { Component } from "react";
import Modal from "react-modal";
import classNames from "classnames/bind";

const modalStyleContent = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  border: "0",
  padding: "0",
};

export default class SearchOption extends Component {
  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleCancelRequest = this.handleCancelRequest.bind(this);
    this.handleSaveRequest = this.handleSaveRequest.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = { open: false, windowWidth: window.innerWidth };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleButtonClick() {
    this.setState({ open: true });
  }

  handleCancelRequest() {
    const { revertState } = this.props;
    if (revertState !== undefined) {
      revertState();
    }
    this.setState({ open: false });
  }

  handleSaveRequest() {
    const { saveState } = this.props;
    if (saveState !== undefined) {
      saveState();
    }
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const {
      t,
      label,
      title,
      isDirtyState,
      children,
      clearState,
      optimalWidth,
      actionLabel,
    } = this.props;
    const dirty = isDirtyState !== undefined && isDirtyState();
    const buttonClasses = classNames("btn", "searchChip", "mr-3", { "active": dirty });
    const style = { "content": modalStyleContent };
    if (optimalWidth !== undefined) {
      style.content.width = this.state.windowWidth > optimalWidth ? `${optimalWidth}px` : "95%";
    }
    return (
      <>
        <button type="button" className={buttonClasses} onClick={this.handleButtonClick}>
          {label}
        </button>
        <Modal
          isOpen={open}
          contentLabel={label}
          onRequestClose={this.handleCancelRequest}
          shouldCloseOnOverlayClick={true}
          style={style}
          overlayClassName="Overlay"
        >
          {
            title && (
              <div class="pt-3 pl-3 pr-3">
                <strong>{title}</strong>
              </div>
            )
          }
          <div class="p-3">{children}</div>
          <hr class="solid m-0"/>
          <div class="container pt-3 pb-3">
            <div class="d-flex row no-gutters">
              {
                (clearState !== undefined) && (
                  <div>
                    <button
                      onClick={clearState}
                      class="btn btn-link"
                      disabled={!dirty}
                    >{t("Clear")}</button>
                  </div>
                )
              }
              <div class="ml-auto">
                <button
                  onClick={this.handleSaveRequest}
                  class="btn btn-primary"
                >{actionLabel !== undefined ? actionLabel : t("Save")}</button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
