import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Popup } from "react-leaflet";
import { localizeRate } from "../../common/money";
import { AttrsLine } from "../common/strings";

const popupBorderRadius = "6px";

const useStyles = makeStyles(
  (theme: Theme) => createStyles({
    // leaflet popup tag structure is:
    // <div class="leaflet-popup">
    //   <div class="leaflet-popup-content-wrapper">
    //     <div class="leaflet-popup-content">
    //       {children}
    //     </div>
    //   </div>
    // </div>
    leafletPopup: {
      "& .leaflet-popup-content-wrapper": {
        borderRadius: popupBorderRadius,
        boxShadow: "0 3px 14px rgba(0,0,0,0.6)",
        padding: "0",
      },
      "& .leaflet-popup-content": {
        margin: "0",
      },
      "& .leaflet-popup-tip-container": {
        visibility: "hidden",
      },
    },
    root: {
      borderRadius: popupBorderRadius,
    },
    name: {
      margin: "0 0 6px 0",
    },
    content: {
      margin: "3px 0 !important",
    },
    media: {
      height: "140px",
      paddingTop: "56.25%", // 16:9
      backgroundColor: "#eee",
    },
  }),
);

export function HostMapCard(
  { homestay, billingPeriod, currency, nameComponent = "h2", t },
) {
  const classes = useStyles();
  const { attrs } = homestay;
  return (
    <Card className={classes.root}>
      <Link
        href={homestay.url}
        target="_blank"
        underline="none"
        style={{ textDecoration: "none" }}
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={homestay.cardImageUrl}
            title={homestay.name}
          />
          <CardContent>
            <Typography
              variant="h6"
              color="textPrimary"
              component={nameComponent}
              noWrap={true}
              className={classes.name}
            >
              {homestay.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              noWrap={true}
              className={classes.content}
            >
              {
                [homestay.city, homestay.nbrhood].reduce(
                  (acc, attr) => {
                    if (!attr) {
                      return acc;
                    }
                    if (acc === null) {
                      return [attr];
                    }
                    return [
                      acc,
                      <span aria-hidden="true" key={`attrspan-${homestay.uid}`}> · </span>,
                      attr,
                    ];
                  },
                  null,
                )
              }
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              noWrap={true}
              className={classes.content}
            >
              <AttrsLine attrs={attrs} labels={["GuestRooms", "MinStay"]} t={t}/>
            </Typography>
            {
              homestay.fxPrice && billingPeriod && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  noWrap={true}
                  className={classes.content}
                >
                  {localizeRate(homestay.fxPrice, billingPeriod, currency, t)}
                </Typography>
              )
            }
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}

export default function HostMarkerPopup(props) {
  const classes = useStyles();
  const { homestay, billingPeriod, currency, t } = props;
  return (
    <Popup
      autoPan={false}
      closeButton={false}
      className={classes.leafletPopup}
      minWidth={300}
      maxWidth={300}
      offset={[0, 28]}  // move the popup down closer to the marker's tooltip
    >
      <HostMapCard
        homestay={homestay}
        billingPeriod={billingPeriod}
        currency={currency}
        t={t}
      />
    </Popup>
  );
}
