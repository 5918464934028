import { DESTINATION_PATH } from "../constants/app";
import { encodeUrlParams } from "../common/url";

type PageParams = {
  currency: string | null,
  language: string,
}

export function parseUrlParams({search}, i18n): PageParams {
  const params = new URLSearchParams(search);
  const {language = "en"} = i18n; // fall back to what i18n detected
  let paramsLanguage = params.get("lng");
  const selectedLanguage = paramsLanguage || language;
  return {
    currency: params.get("ccy"),
    language: selectedLanguage,
  };
}

export function makeDestinationUrlWithParams(
  placeSlug: string, urlParams?: string,
): string {
  if (urlParams) {
    return `${DESTINATION_PATH}/${placeSlug}?${urlParams}`;
  }
  return `${DESTINATION_PATH}/${placeSlug}`;
}

export function makeDestinationUrlParams(
  language: string | null = null,
  currency: string | null = null,
): string {
  return encodeUrlParams({lng: language, ccy: currency});
}
