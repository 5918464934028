import React from "react";
import { Grid } from "@material-ui/core";
import { HowItWorksCard } from "./HowItWorksCard";

export function HowItWorksCards({ cards, classes }) {
  return <Grid
    container
    spacing={6}
    direction="row"
    justifycontent="space-evenly"
    alignItems="flex-start"
    className={classes.hostSignUpGrid}
  >
    {
      cards.map(
        (card, i: number) => <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          key={`howitworks${i}`}
        >
          <HowItWorksCard
            stepNumber={i + 1}
            title={card.title}
            description={card.description}
            img={card.img}
            classes={classes}
          />
        </Grid>,
      )
    }
  </Grid>;
}
