type PageParams = {
  currency: string | null,
  language: string,
}

export function parseUrlParams({search}, i18n): PageParams {
  const params = new URLSearchParams(search);
  const {ccy, lng, ...others} = Object.fromEntries(params);
  const {language = "en"} = i18n; // fall back to what i18n detected
  const selectedLanguage = lng || language;
  return {
    currency: ccy,
    language: selectedLanguage,
    ...others,
  };
}
