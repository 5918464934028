import React from "react";
import { useRouteError } from "react-router-dom";

export default function RouteRenderingError(props) {
  const error = useRouteError();
  const { message, stack } = error;
  return <div>
    <p>Error rendering page: {message}</p>
    <pre>{stack}</pre>
  </div>;
}
