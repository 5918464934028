import React from "react";
import { Grid } from "@material-ui/core";
import { TestimonialCard } from "../../component/TestimonialCard";

export default function Testimonials({ testimonials }) {
  return <Grid
    container
    spacing={3}
    direction="row"
    justifyContent="center"
    alignItems="flex-start"
  >
    {
      testimonials.map(
        (item) => <Grid container item xs={12} md={6} lg={4}>
          <TestimonialCard {...item}/>
        </Grid>,
      )
    }
  </Grid>;
}
