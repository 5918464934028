import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Trans } from "react-i18next";
import { encodeUrlParams } from "../../common/url";
import { HELP_PATH } from "../../constants/app";

export function Questions({ questions, lng, classes, t }) {
  // <p>
  //   For guidance, these are pricing examples from popular homestay destinations:
  //   <ul>
  //     <li>Vancouver, Canada: $1,000 CAD per month, including meals</li>
  //     <li>Toronto, Canada: $950 CAD per month, including meals</li>
  //   </ul>
  // </p>
  // <p>
  //   These rates are not an appraisal or estimate of property value. Prices can vary based on
  //   factors like location, amenities, and meal offerings. It's important to set a competitive
  //   rate that reflects your costs and the overall experience you provide.
  // </p>
  return <div itemScope itemType="https://schema.org/FAQPage">
    <h2 className={classes.h2}>{t("starthosting:faq.h")}</h2>
    {
      questions.map(
        question => <section
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
          key={`faq.${question}`}
        >
          <h3 itemProp="name" className={classes.h3}>{t(`starthosting:faq.${question}-q`)}</h3>
          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
            <div itemProp="text">
              {
                t(`starthosting:faq.${question}-a`).split("\n").map(
                  line => <p key={`faq.${question}.${line}`}>{line}</p>,
                )
              }
            </div>
          </div>
        </section>,
      )
    }
    <h3 className={classes.h3}>{t("starthosting:faq.more-q")}</h3>
    <p>{t("starthosting:faq.more-a1")}</p>
    <p>
      <Trans i18nKey="starthosting:faq.more-a2" t={t}>
        <RouterLink
          to={{
            pathname: HELP_PATH,
            search: encodeUrlParams({ lng, audience: "hosts" }),
          }}
        />
      </Trans>
    </p>
  </div>;
}
