import React, { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { makeApiserverClient } from "../../api/factory";
import type { AuthenticationResult } from "../../api/login";
import authenticateUser from "../../api/login";
import signOut from "../../api/logout";
import { queryService, useFetch } from "../../api/request.js";
import { usePrevious } from "../../common/hook";
import { makeMetaDescription } from "../../common/seotags";
import { setLngCookie } from "../../common/state";
import { MEDIA_MIN_WIDTH_600 } from "../../common/widths";
import DocumentHead, { makeTitleFromError } from "../../component/DocumentHead";
import { FetchError } from "../../component/FetchError";
import Footer from "../../component/Footer";
import Nav from "../../component/Nav.jsx";
import { useViewport } from "../../component/ViewportProvider";
import { DESTINATION_PATH } from "../../constants/app";
import { makeUrlParams, makeUrlWithParams } from "../../homestaysearch/url";
import { LOCALES } from "../../i18n";
import { makeDestinationUrlParams, makeDestinationUrlWithParams, parseUrlParams } from "../url";
import Content from "./Content";

const styles = theme => ({
  // This pushes the container below the navbar.
  content: {
    position: "relative",
    width: "100%",
    paddingTop: "56px", // this must be the height of the navbar
    [MEDIA_MIN_WIDTH_600]: {
      paddingTop: "64px",
    },
    display: "inline-block",
    height: "auto",
    overflow: "visible",
  },
  error: {
    margin: "20px",
  },
});

function makeTitle(breadcrumbs, error, t): string {
  if (error) {
    return makeTitleFromError(error, t);
  }
  if (!breadcrumbs || breadcrumbs.length === 0) {
    return t("Html.SearchTitle");
  }
  const name = breadcrumbs[0].name;
  const suffix = t("Html.TitleSuffix");
  if (breadcrumbs.length === 1) {
    return t("Destination.BestHomestaysIn", { name }).concat(suffix);
  }
  const country = breadcrumbs[breadcrumbs.length - 1].name;
  return t("Html.DestinationTitle", { name, country }).concat(suffix);
}

export function PlacePage(
  {
    user,
    t,
    setUser,
    signUpGuestDialog,
    onSignUpGuestOpen,
    i18n,
    classes,
  },
) {
  const routerParams = useParams();
  const placeSlugParam = routerParams.placeSlug || "Vancouver--BC--Canada";
  // const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(null);
  const [placeSlug, setPlaceSlug] = useState(placeSlugParam);
  if (placeSlugParam !== placeSlug) {
    // this fixes the state not changing when the browser's back/forward buttons are used
    setPlaceSlug(placeSlugParam);
  }

  const prevPlaceSlug = usePrevious(placeSlug);
  const { currency: parsedCurrency, ...parsedParams } = parseUrlParams(location, i18n);
  const [currency, setCurrency] = useState(parsedCurrency);
  const [params, setParams] = useState(parsedParams);
  const [state, setState] = useState({
    placePageData: null,
  });
  const { height, width } = useViewport();
  const navigate: NavigateFunction = useNavigate();

  // TODO rename to handleStateParamsChange
  const handleStateChange = (newParams, newState, newCurrency: ?string = null, newMarket: ?string = null) => {
    if (newCurrency) {
      setCurrency(newCurrency);
    }
    setLngCookie(newParams);
    if (newMarket) {
      const { language: newLanguage, guestCount, checkIn } = newParams;
      const { language } = parsedParams;
      const params = makeUrlParams(
        {
          checkIn,
          guestCount,
          language: newLanguage || language,
          followMap: true,
        },
        newCurrency || currency,
      );
      const url = makeUrlWithParams(newMarket, params);
      navigate(url);
    }
    if (newState) {
      // Overwrite current state with new state.
      setState({ ...state, ...newState });
    }
    setParams({ ...params, ...newParams });
  };

  // Init: query server for currencies and markets once.
  const { data: uiData } = useFetch("/ui/init?request=currencies,markets");
  // data can be null instead of undefined on network error
  const {
    values: { currencies, markets } = { currencies: [], markets: {} },
  } = uiData || {};

  // should only get called once on load, hence empty deps

  // Query for search results whenever the user changes a search parameter.
  useEffect(
    () => {
      const { language } = params;
      const urlParams = makeDestinationUrlParams(language, currency);
      const target = makeDestinationUrlWithParams(placeSlug, urlParams);
      const cur = location.pathname + location.search;
      if (cur !== target) {
        navigate(target);
      }

      const placeSlugChanged = prevPlaceSlug !== undefined && prevPlaceSlug !== placeSlug;
      // noinspection JSIgnoredPromiseFromCall
      fetchResults(placeSlugChanged);
    },
    // only re-run effect if user changes something that requires fetching results again
    [params, placeSlug, currency],
  );

  const fetchResults = async (placeSlugChanged: boolean) => {
    const client = makeApiserverClient(
      process.env.REACT_APP_API_SERVER_BASE_URL,
      // { Authorization: "Bearer token" }, // auth header goes here
      {},
    );
    const { language } = params;
    const getParams = [`lng=${language}`];
    if (currency) {
      getParams.push(`ccy=${currency}`);
    }
    const { result, error } = await queryService( // TODO handle redirect to parent slug if requested slug does not exist
      client,
      "GET",
      `/place/destination/${placeSlug}?${getParams.join("&")}`,
    );
    setError(error);
    setState({ placePageData: result });
    const { breadcrumbs } = result || {};
    if (breadcrumbs) {
      const placeSlugFromService = breadcrumbs[0].slug;
      if (placeSlug !== placeSlugFromService) {
        // Request to service issued a 301 redirect, therefore the slug parsed from
        // the query parameter in the GET request differs from the slug in the
        // server response.
        const urlParams = makeDestinationUrlParams(language, currency);
        const target = makeDestinationUrlWithParams(placeSlugFromService, urlParams);
        setPlaceSlug(placeSlugFromService);
        navigate(target);
      }
    }
  };

  const onSignIn = async (email: string, password: string): AuthenticationResult => {
    const result = await authenticateUser(email, password);
    setUser(result.user);
    return result.result;
  };

  const onSignOut = async (): boolean => {
    const signedOut = await signOut();
    if (signedOut) {
      setUser(null);
    }
    return signedOut;
  };

  const { placePageData } = state;
  const { market, breadcrumbs } = placePageData || {};
  const { slug: marketSlug } = market || {}; // if market exists, take its slug

  let stickySearchBoxOffset = 350;
  if (!placePageData) {
    stickySearchBoxOffset = 0;
  } else if (width < 960) {
    stickySearchBoxOffset = 260;
  }

  // Extract the market slug from the place data.
  const { market: placeMarket } = placePageData || {};
  const { slug: placeMarketSlug } = placeMarket || { slug: null };

  return (
    <>
      <DocumentHead
        title={makeTitle(breadcrumbs, error, t)}
        description={makeMetaDescription(placePageData, t)}
        canonical={{ path: `${DESTINATION_PATH}/${placeSlug}`, params: { lng: params.language } }}
        params={params}
        t={t}
      />
      <Nav
        onStateChange={handleStateChange}
        user={user}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
        t={t}
        saveTransKey="Search"
        i18n={i18n}
        markets={markets}
        market={marketSlug}
        countryName={breadcrumbs && breadcrumbs.length > 1 && breadcrumbs[breadcrumbs.length - 1].name}
        locales={LOCALES}
        currency={currency}
        params={params}
        currencies={currencies}
        onSignUpGuestOpen={onSignUpGuestOpen}
        stickySearchBoxOffset={stickySearchBoxOffset}
      />
      {signUpGuestDialog}
      <div className={classes.content}>
        {error && <FetchError error={error} t={t} classes={classes}/>}
        {
          placePageData && <Content
            t={t}
            language={i18n.language}
            i18n={i18n}
            placeSlug={placeSlug}
            currency={currency}
            placePageData={placePageData}
            setPlaceSlug={setPlaceSlug}
          />
        }
      </div>
      <Footer
        user={user}
        placeSlug={placeMarketSlug}
        market={market}
        markets={markets}
        onSignUpGuestOpen={onSignUpGuestOpen}
        t={t}
        locale={i18n.language}
      />
    </>
  );
}

export default withStyles(styles)(PlacePage);

// Enter dates to see full pricing. Additional fees apply. Taxes may be added.
