import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import HeroImage from "../../common/HeroImage";
import {
  IMAGE_WIDTHS,
  MEDIA_MIN_WIDTH_1256,
  MEDIA_MIN_WIDTH_480,
  MEDIA_MIN_WIDTH_768,
  MEDIA_MIN_WIDTH_960,
} from "../../common/widths";
import {
  H1,
  H2,
  HERO_IMAGE,
  HERO_IMAGE_CONTAINER,
  JUMBOTRON,
  JUMBOTRON_CONTENT_1,
  JUMBOTRON_H1_H2,
} from "../../constants/styles";
import Dialog from "./Dialog";

const useStyles = makeStyles(
  {
    jumbotron: {
      ...JUMBOTRON("450px", "550px"),
      [MEDIA_MIN_WIDTH_480]: {
        height: "450px",
      },
    },
    jumbotronContent: { ...JUMBOTRON_CONTENT_1, paddingTop: "60px" },
    heroImageContainer: HERO_IMAGE_CONTAINER,
    heroImage: HERO_IMAGE,
    "h1": H1,
    "h2": H2,
    "h_white": JUMBOTRON_H1_H2,
    hostSignUpGrid: {
      padding: "0 10px", // don't waste valuable space!
      textAlign: "center",
      [MEDIA_MIN_WIDTH_480]: {
        padding: "0 50px",
      },
      [MEDIA_MIN_WIDTH_768]: {
        padding: "0 80px",
      },
      [MEDIA_MIN_WIDTH_960]: {
        // this is when the sign up box gets positioned to the right
        textAlign: "left",
      },
      [MEDIA_MIN_WIDTH_1256]: {
        padding: "0 150px",
      },
    },
  },
);

export function Jumbotron({ lng, defaultSelectedMarket, markets, t }) {
  const classes = useStyles();
  return <div className={classes.jumbotron}>
    <div className={classes.heroImageContainer}>
      <HeroImage
        photo={
          {
            urls: IMAGE_WIDTHS.map(
              (width: number) => ({
                width: width,
                url: `https://s3.amazonaws.com/hsb-static/images/sofa-cactus_${width}.jpg`,
              }),
            ),
            rgb: "#260c0c",
          }
        }
        alt="Comfortable sofa with green cacti placed in front"
        classes={classes}
      />
    </div>
    <div className={classes.jumbotronContent}>
      <Grid
        container
        spacing={6}
        direction="row"
        justifycontent="space-evenly"
        alignItems="baseline"
        className={classes.hostSignUpGrid}
      >
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h1 className={`${classes.h1} ${classes.h_white}`}>{t("starthosting:h1")}</h1>
          <h2 className={`${classes.h2} ${classes.h_white}`}>{t("starthosting:h2")}</h2>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Dialog
            lng={lng}
            defaultSelectedMarket={defaultSelectedMarket}
            markets={markets}
            t={t}
          />
        </Grid>
      </Grid>
    </div>
  </div>;
}
