import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import HotelIcon from "@material-ui/icons/Hotel";
import { makeRegisterHostLink } from "../common/links";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  paper: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    boxShadow: "none",
    margin: "10px 0",
    padding: theme.spacing(2),
  },
  box: {
    textAlign: "center",
  },
}));

export default function SignUpDialog({ t, onClose, open, openGuest, locale }) {
  const classes = useStyles();

  const handleGuest = (e: React.MouseEvent<HTMLElement>) => {
    onClose(e);
    openGuest(e);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("JoinOurCommunity")}</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                <Typography component="div">
                  <Box component="h5" className={classes.box}>
                    {t("HomestayHosts")}
                  </Box>
                  <Box>
                    <Button
                      component={RouterLink}
                      to={makeRegisterHostLink(locale)}
                      color="primary"
                      // TODO use VillaIcon when on mui v5
                      startIcon={<HotelIcon/>}
                      variant="contained"
                      fullWidth
                    >
                      {t("GetStartedHosting")}
                    </Button>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs>
                <Typography component="div">
                  <Box component="h5" className={classes.box}>
                    {t("StudentsAndGuests")}
                  </Box>
                  <Button
                    onClick={handleGuest}
                    color="primary"
                    // TODO use LuggageIcon when on mui v5
                    startIcon={<CardTravelIcon/>}
                    variant="contained"
                    fullWidth
                  >
                    {t("CreateYourProfile")}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  );
}
