import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";


export default function CurrencyDialog(
  { t, currencies = [], onClose, open, value, setValue },
) {
  const [currency, setCurrency] = useState(value);
  if (!currency && value) {
    // This allows currency to initially be null on page load and then for an
    // API response to set the currency.
    setCurrency(value);
  }

  const handleChange = (event: React.ChangeEvent) => {
    const { target: { value } } = event;
    setCurrency(value);
  };

  const handleCancel = () => {
    setCurrency(value);  // reset to input value
    onClose();
  };

  const handleSave = () => {
    setValue(currency);  // set value in parent
    onClose();
  };

  const makeLabel = c => {
    return <>{c} - {t(`currency.${c}`)}</>;
  };

  return (
    <Dialog disableBackdropClick open={open} onClose={onClose}>
      <DialogTitle>{t("Currency")}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label={t("Currency")}
            name="currency"
            value={currency}
            onChange={handleChange}
          >
            {
              currencies.map(
                c => <FormControlLabel
                  key={c}
                  value={c}
                  control={<Radio color="primary"/>}
                  label={makeLabel(c)}
                />,
              )
            }
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
