import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Error from "../../component/Error.jsx";
import MatchesPane from "./MatchesPane.jsx";
import HostMap from "./HostMap.jsx";
import { useViewport } from "../../component/ViewportProvider";
import { MEDIA_MIN_WIDTH_1128 } from "../../common/widths";

// Minimum pixel width of the viewport for the host map to be displayed.
// Note the media reference in scrollingContainer below.
const MAP_MIN_VIEWPORT_WIDTH = 1128;

const styles = theme => ({
  likeBody: {
    position: "relative",
    width: "100%",
    paddingTop: "64px", // this must be the height of the navbar
    display: "inline-block",
    height: "auto",
    overflow: "visible",
  },
  mainContainer: {
    width: "100%",
    position: "relative",
    display: "inline-block",
    height: "auto",
    overflow: "visible",
    // fixes a gap at the bottom - no idea where this comes from
    marginBottom: "-6px",
  },
  // encloses the search results
  scrollingContainer: {
    position: "relative",
    display: "block",
    float: "left",
    height: "auto",
    overflow: "visible",
    background: "#fdfdfd",
    paddingBottom: "25px",
    width: "100%", // 100% width when the map is not displayed
    [MEDIA_MIN_WIDTH_1128]: {
      // 804px width when the map is displayed
      width: "840px",
    },
  },
  // encloses the map and stays statically on the screen while it scrolls
  stickyContainer: {  // not rendered when width < 1128px
    position: "sticky",
    width: "calc(100% - 840px)",  // when: @media (min-width: 1128px)
//    minHeight: "100vh - 64px", // 64px is the height of the appbar
//    width: calc(100% - 840px) !important;
    height: "calc(100vh - 64px)",
    minHeight: "calc(100vh - 64px)",
    background: "lightblue",
    top: "64px",
    float: "right",
    "@media (max-width: 1127px)": {
      display: "none",
    },
  },
});

export function Content(props) {
  const [hoveredMatch, setHoveredMatch] = useState(null);
  const { width } = useViewport();
  const hostMapVisible = width >= MAP_MIN_VIEWPORT_WIDTH;
  const [hostMapEverVisible, setHostMapEverVisible] = useState(hostMapVisible);

  // Handles user event when panning or zooming the map.
  const handleBoundsChange = (geoBounds, mapBounds) => {
    onMapEventBoundsChange(geoBounds, mapBounds);
  };

  const handleMatchHoverChange = hoveredMatch => {
    setHoveredMatch(hoveredMatch);
  };

  const {
    classes,
    t,
    searchResult,
    error,
    mapBounds,
    params,
    market,
    currency,
    onStateChange,
    onMapEventBoundsChange,
  } = props;

  if (hostMapVisible && !hostMapEverVisible) {
    setHostMapEverVisible(true);
  }
  // TODO this all uses bootstrap and needs to be replaced with material-ui
  return (
    <div className={classes.likeBody}>
      <div className={classes.mainContainer}>
        <div className={classes.scrollingContainer}>
          {
            // Show loading dialog.
            (searchResult === null && error === null) && (
              <div class="m-4">{t("Loading")}</div>
            )
          }
          {
            // Show search results when query completes.
            searchResult && (
              <MatchesPane
                t={t}
                params={params}
                market={market}
                searchResult={searchResult}
                currency={currency}
                onStateChange={onStateChange}
                onMatchHoverChange={handleMatchHoverChange}
              />
            )
          }
          {
            // Show error message if no results are fetched.
            (error !== null) && (
              <div class="m-4"><Error error={error}/></div>
            )
          }
        </div>
        {
          (hostMapEverVisible) && <div className={classes.stickyContainer}>
            <HostMap
	            visible={hostMapVisible}
              searchResult={searchResult}
              mapBounds={mapBounds}
              currency={currency}
              hoveredMatch={hoveredMatch}
              onBoundsChange={handleBoundsChange}
              t={t}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default withStyles(styles)(Content);
