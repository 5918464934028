import { HOME_PATH } from "../constants/app";
import { marshalDate } from "./date";

export type strObj = { [key: string]: string | number | Date | null }

// Encode given object into URL path parameters. Falsy values are excluded.
export function encodeUrlParams(params: strObj): string {
  const searchParams = new URLSearchParams();
  // "lng", "ccy", etc.
  Object.entries(params)
    .filter((entry: [string, string | number | Date | null]) => entry[1])
    .forEach(
      (entry: [string, string | number | Date | null]) => {
        if (entry[1] instanceof Date) {
          searchParams.set(entry[0], marshalDate(entry[1]));
        } else {
          searchParams.set(entry[0], entry[1]);
        }
      },
    );
  return searchParams.toString();
}

export function makeUrl(path: string, params: null | strObj): string {
  const base = `https://www.homestaybay.com${path}`;
  if (!params) {
    return base;
  }
  const paramsEncoded = encodeUrlParams(params);
  return [base, paramsEncoded].filter(Boolean).join("?");
}

export type urlParamsType = { path: string, params: null | strObj };

export function makeUrlFromObj(urlParams: urlParamsType): null | string {
  const {path, params} = urlParams;
  return makeUrl(path, params);
}

export function makeHomeUrlWithParams(urlParams?: string): string {
  if (urlParams) {
    return `${HOME_PATH}?${urlParams}`;
  }
  return `${HOME_PATH}`;
}
