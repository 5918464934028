import React from "react";
import { Box, Grid } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import InternalLink from "../../component/InternalLink";

function ArticleBox({ article, setPath, urlParams, classes }) {
  return <InternalLink
    path={article.path}
    setPath={setPath}
    urlParams={urlParams}
    className={classes.boxLink}
    key={`article-${article.path}`}
  >
    <Box className={classes.boxLink}>
      <section>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          spacing={2}
          alignItems="center"
        >
          <Grid item xs>
            <p className={classes.articleLink}>{article.label}</p>
            {article.excerpt && <p className={classes.excerpt}>{article.excerpt}</p>}
          </Grid>
          <Grid item style={{ width: "60px" }}>
            <KeyboardArrowRight className={classes.boxLinkArrow} fontSize="large"/>
          </Grid>
        </Grid>
      </section>
    </Box>
  </InternalLink>;
}

export default function ArticleBoxes({ articles, setPath, urlParams, classes }) {
  return articles.map(
    article => <ArticleBox
      article={article}
      setPath={setPath}
      urlParams={urlParams}
      classes={classes}
      key={`article-${article.path}`}
      id={`article-${article.path}`}
    />,
  );
}
