import React from "react";
import { Trans } from "react-i18next";

export function localizeRate(rate, period, currency, t) {
  return <Trans
    i18nKey="pricePerPeriod"
    values={{ context: period, price: rate, currency: currency }}
    t={t}
  >
    <strong>{{ rate }}</strong>
  </Trans>;
}

const currencyFormats = {
  // eslint-disable-next-line
  "AUD": "${{value}} AUD",
  // eslint-disable-next-line
  "BRL": "R${{value}}",
  // eslint-disable-next-line
  "CAD": "${{value}} CAD",
  // eslint-disable-next-line
  "COP": "${{value}} COP",
  "EUR": "€{{value}}",
  "GBP": "£{{value}}",
  "INR": "₹{{value}}",
  "JPY": "¥{{value}}",
  "KRW": "₩{{value}}",
  // eslint-disable-next-line
  "MXN": "${{value}} MXN",
  // eslint-disable-next-line
  "NZD": "${{value}} NZD",
  "SAR": "SR{{value}}",
  // eslint-disable-next-line
  "USD": "${{value}} USD",
};

const zeroDecimalCurrencies = new Set(["COP", "INR", "JPY", "KRW", "MXN"]);

export function makeFormatCurrencyOptions(currency) {
  if (zeroDecimalCurrencies.has(currency)) {
    return {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
  }
  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
}

export function formatNumber(value, lng, options) {
  return Intl.NumberFormat(lng, options).format(value);
}

export function formatCurrency(value, currency, lng, options) {
  if (currency === undefined) {
    console.warn("no currency for value: ", value);
    return value;
  }
  if (!currencyFormats.hasOwnProperty(currency)) {
    console.warn("unsupported currency: ", currency);
    return value;
  }
  const formatted = formatNumber(value, lng, options);
  return currencyFormats[currency].replace("{{value}}", formatted);
}
