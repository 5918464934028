import { GeoBounds } from "../common/geo";

// There are 3 geobounds:
// * search input: bounds set as input in the last API search request
//   * this is undefined when initiating a search by providing a market
//   * can be provided in the GET request params to override the market
//   * in this case, the bounds can be copied from the response
// * search output: returned by the server describing the outer bounds of all matches to the query
//   * only useful purpose is to initialize the map to a specific zoom and centre geopoint
// * map change events: provided by the user via zooming or panning the map
//   * this is undefined until the user starts moving the map around
//   * an "update search as I move the map" indicates if these bounds replicate to the search input
//   * this means panning the map won't change the GET request params unless the checkbox is ticked
//     and reloading the page would reset the map
//   * alternately, 2 sets of geopoints could be encoded in the request to represent the search input and the map view?
export function makeApiRequestGeoBounds(
  searchInput?: GeoBounds,
  searchOutput?: GeoBounds,
  mapView?: GeoBounds,
  followMap: boolean,
): GeoBounds | undefined {
  if (mapView !== undefined && followMap) {
    return mapView;
  }
  if (searchInput !== undefined) {
    return searchInput;
  }
  return searchOutput;
}
