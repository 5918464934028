import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { makeApiserverClient } from "../../api/factory";
import type { AuthenticationResult } from "../../api/login";
import authenticateUser from "../../api/login";
import signOut from "../../api/logout";
import { queryService, useFetch } from "../../api/request";
import { setLngCookie } from "../../common/state";
import { encodeUrlParams, makeHomeUrlWithParams } from "../../common/url";
import { MEDIA_MIN_WIDTH_600 } from "../../common/widths";
import DocumentHead, { makeTitleFromError } from "../../component/DocumentHead";
import { FetchError } from "../../component/FetchError";
import Footer from "../../component/Footer";
import Nav from "../../component/Nav";
import { useViewport } from "../../component/ViewportProvider";
import { HOME_PATH } from "../../constants/app";
import { parseUrlParams } from "../../destination/url";
import { makeUrlParams, makeUrlWithParams } from "../../homestaysearch/url";
import { LOCALES } from "../../i18n";
import { Content } from "./Content";

const useStyles = makeStyles({
  // This pushes the container below the navbar.
  content: {
    position: "relative",
    width: "100%",
    paddingTop: "56px", // this must be the height of the navbar
    [MEDIA_MIN_WIDTH_600]: {
      paddingTop: "64px",
    },
    display: "inline-block",
    height: "auto",
    overflow: "visible",
  },
  error: {
    margin: "20px",
  },
});

function makeTitle(error, t): string {
  if (error) {
    return makeTitleFromError(error, t);
  }
  // Book Your Homestay - Find furnished accommodation with a local host
  return "Book Your Homestay · Find quality furnished accommodation with a local host · HomestayBay";
}

function getSearchParams() {
  return new URLSearchParams(location.search);
}

export function HomePage(
  {
    user,
    t,
    setUser,
    signUpGuestDialog,
    onSignUpGuestOpen,
    i18n,
  },
) {
  const classes = useStyles();
  const location = useLocation();
  const [error, setError] = useState(null);
  const { currency: parsedCurrency, ...parsedParams } = parseUrlParams(location, i18n);
  const [currency, setCurrency] = useState(parsedCurrency);
  const [params, setParams] = useState(parsedParams);
  const [state, setState] = useState({ homePageData: null });
  const { width } = useViewport();
  const navigate: NavigateFunction = useNavigate();

  const handleStateParamsChange = (
    newParams, newState, newCurrency: ?string = null, newMarket: ?string = null,
  ) => {
    if (newCurrency) {
      setCurrency(newCurrency);
    }

    setLngCookie(newParams);
    if (newMarket) {
      const { language: newLanguage, guestCount, checkIn } = newParams;
      const { language } = parsedParams;
      const params = makeUrlParams(
        {
          checkIn,
          guestCount,
          language: newLanguage || language,
          followMap: true,
        },
        newCurrency || currency,
      );
      const url = makeUrlWithParams(newMarket, params);
      navigate(url);
    }

    setParams({ ...params, ...newParams });
  };

  // Init: query server for currencies and markets once.
  const { data: uiData } = useFetch("/ui/init?request=currencies,markets");
  // data can be null instead of undefined on network error
  const {
    values: { currencies, markets } = { currencies: [], markets: {} },
  } = uiData || {};

  // Query for search results whenever the user changes a search parameter.
  useEffect(
    () => {
      const { language } = params;
      const locationParams = getSearchParams();
      const urlParams = encodeUrlParams({
        lng: language,
        ccy: currency,
        // This can be removed when there's a proper sign up page instead of the dialog.
        openSignUp: locationParams.get("openSignUp"), // host or guest
        mkt: locationParams.get("mkt"), // optional market to default to
      });
      const target = makeHomeUrlWithParams(urlParams);
      const cur = location.pathname + location.search;
      if (cur !== target) {
        navigate(target);
      }
      // noinspection JSIgnoredPromiseFromCall
      fetchResults();
    },
    // only re-run effect if user changes something that requires fetching results again
    [params, currency],
  );

  useEffect(
    () => {
      const params = getSearchParams();
      if (params.has("openSignUp")) {
        const openSignUp = params.get("openSignUp");
        const mkt = params.get("mkt");
        if (openSignUp === "guest") {
          onSignUpGuestOpen(null, mkt, markets);
        }
      }
    },
    [state],
  );

  const fetchResults = async () => {
    const client = makeApiserverClient(
      process.env.REACT_APP_API_SERVER_BASE_URL,
      // { Authorization: "Bearer token" }, // auth header goes here
      {},
    );
    const { language } = params;
    const urlParams = encodeUrlParams({ lng: language, ccy: currency });
    const { result, error } = await queryService(
      client,
      "GET",
      `/ui/home?${urlParams}`,
    );
    setError(error);
    setState({ homePageData: result });
  };

  const onSignIn = async (email: string, password: string): AuthenticationResult => {
    const result = await authenticateUser(email, password);
    setUser(result.user);
    return result.result;
  };

  const onSignOut = async (): boolean => {
    const signedOut = await signOut();
    if (signedOut) {
      setUser(null);
    }
    return signedOut;
  };

  const { homePageData } = state;

  let stickySearchBoxOffset = 350;
  if (width < 960) {
    stickySearchBoxOffset = 260;
  }

  return <>
    <DocumentHead
      title={makeTitle(error, t)}
      description={t("Html.SearchMetaDescription")}
      canonical={{ path: HOME_PATH, params: { lng: params.language } }}
      params={params}
      t={t}
    />
    <Nav
      onStateChange={handleStateParamsChange}
      user={user}
      onSignIn={onSignIn}
      onSignOut={onSignOut}
      t={t}
      saveTransKey="Search"
      i18n={i18n}
      markets={markets}
      market={null}
      countryName={null}
      locales={LOCALES}
      currency={currency}
      params={params}
      currencies={currencies}
      onSignUpGuestOpen={onSignUpGuestOpen}
      stickySearchBoxOffset={stickySearchBoxOffset}
    />
    {signUpGuestDialog}
    <div className={classes.content}>
      {error && <FetchError error={error} t={t} classes={classes}/>}
      {
        homePageData && <Content
          urlParams={{ lng: i18n.language, ccy: currency }}
          homePageData={homePageData}
          t={t}
        />
      }
    </div>
    <Footer
      user={user}
      placeSlug={null}
      markets={markets}
      onSignUpGuestOpen={onSignUpGuestOpen}
      t={t}
      locale={i18n.language}
    />
  </>;
}
