import React from "react";
import Matches from "./Matches.jsx";
import MatchesPagination from "./MatchesPagination.jsx";
import SecondarySearch from "./SecondarySearch.jsx";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { LandingPageBreadcrumb } from "../../destination/component/Breadcrumbs";

const useStyles = makeStyles({
  h1: {
    fontSize: "2rem",
    fontWeight: "bold",
    "@media (max-width: 800px)": {
      // reduce the h1 size on mobile
      fontSize: "1.8rem",
    },
  },
  description: {
    margin: "20px",
    marginBottom: 0,
    fontSize: "0.85rem",
  },
});

function MatchesCount(props) {
  const { t, searchResult } = props;
  const { countExceeded = false, count } = searchResult;
  if (countExceeded) {
    return <p>{t("NHomestayExceededMax", { count })}</p>;
  }
  return <p>{t("NHomestay", { count })}</p>;
}

export default function MatchesPane(
  { t, params, market, searchResult, currency, onStateChange, onMatchHoverChange },
) {
  // TODO "Homestays in {market}" vs "Homestays in selected map area"
  // use presence of geobounds in api request to determine which one
  const classes = useStyles();

  // set to true when no matches to prevent the hint from appearing
  const searchingWithPrices = searchResult && (
    (searchResult.priceLower && searchResult.priceUpper) || !searchResult.count
  );
  return (
    <>
      <div class="m-4">
        {(searchResult !== null) && <MatchesCount t={t} searchResult={searchResult}/>}
        <h1 className={classes.h1}>
          {t("HomestaysIn", { market: t(`markets:${market}`) })}
        </h1>
      </div>
      <SecondarySearch
        t={t}
        onStateChange={onStateChange}
        searchResult={searchResult}
        params={params}
        currency={currency}
      />
      {
        !searchingWithPrices && <div class="m-4">
          <Info fontSize="small" color="action"/> {t("AddCheckInForPrices")}
        </div>
      }
      <Matches
        searchResult={searchResult}
        t={t}
        onMatchHoverChange={onMatchHoverChange}
      />
      {searchResult && !searchResult.count && <div class="m-4">{t("NoMatchesHint")}</div>}
      <MatchesPagination
        t={t}
        searchResult={searchResult}
        onStateChange={onStateChange}
        params={params}
        market={market}
      />
      {
        searchResult && searchResult.description &&
        <div class={classes.description}>{searchResult.description}</div>
      }
      {
        searchResult && searchResult.searchPath && <div className="m-4">
          <LandingPageBreadcrumb
            nodes={searchResult.searchPath}
            renderLeafAsLink={true}
            urlParams={{ lng: params.language, ccy: currency }}
            t={t}
          />
        </div>
      }
    </>
  );
}
