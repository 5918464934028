import { Grid } from "@material-ui/core";
import React from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PowerIcon from "@material-ui/icons/Power";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import MapIcon from "@material-ui/icons/Map";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TranslateIcon from "@material-ui/icons/Translate";
import GroupIcon from "@material-ui/icons/Group";
import {
  formatCurrency,
  formatNumber,
  makeFormatCurrencyOptions,
} from "../../common/money";
import Insight from "../../component/Insight";

// Construct a label such as "$10 CAD = £6.55".
function makeCurrencyLabel(placePageData, currency: string | null = null, i18n): string {
  const {
    localCurrency,
    fxBasePrice,
    fxPrice = null,
  } = placePageData;
  if (fxPrice && currency && currency !== localCurrency) {
    const from = formatCurrency(fxBasePrice, localCurrency, i18n.language);
    const options = makeFormatCurrencyOptions(currency);
    const to = formatCurrency(fxPrice, currency, i18n.language, options);
    return `${from} = ${to}`;
  }
  return localCurrency;
}

function makeLocalLanguageLabel(placePageData, t): string | null {
  const { localLanguageId = null } = placePageData;
  if (localLanguageId) {
    return t(`languages:${localLanguageId}`);
  }
  return null;
}

function makeAverageMonthlyPriceLabel(placePageData, t): string | null {
  const { localCurrency, rateMonthlyWithMeals = null } = placePageData;
  if (rateMonthlyWithMeals) {
    return t("Destination.PriceWithMeals", {
      price: rateMonthlyWithMeals,
      currency: localCurrency,
    });
  }
  return null;
}

function makePopulationLabel(placePageData, i18n): string | null {
  const { pop, popYear } = placePageData;
  if (!pop) {
    return null;
  }

  const popFormatted = formatNumber(pop, i18n.language);
  if (popYear) {
    return `${popFormatted} (${popYear})`;
  }
  return popFormatted;
}

export default function AboutCity({ placePageData, currency, t, i18n }) {
  const { distanceUnit, drivingSide, mainsVoltage } = placePageData;
  const items = [
    {
      icon: <AttachMoneyIcon/>,
      title: t("Destination.LocalCurrency"),
      value: makeCurrencyLabel(placePageData, currency, i18n),
    },
    {
      icon: <TranslateIcon/>,
      title: t("Language"),
      value: makeLocalLanguageLabel(placePageData, t),
    },
    // TODO not yet supported on server side
    // {
    //   icon: <CalendarTodayIcon/>,
    //   title: t("Destination.PopularDates"),
    //   value: "September to November",
    // },
    {
      icon: <LocalOfferIcon/>,
      title: t("Destination.AvgMonthlyPrice"),
      value: makeAverageMonthlyPriceLabel(placePageData, t),
    },
    {
      icon: <GroupIcon/>,
      title: t("Destination.Population"),
      value: makePopulationLabel(placePageData, i18n),
    },
    {
      icon: <MapIcon/>,
      title: t("Destination.DistanceUnit"),
      value: t(`DistanceUnit.${distanceUnit}`),
    },
    {
      icon: <DirectionsCarIcon/>,
      title: t("Destination.DrivingSide"),
      value: t(`Direction.${drivingSide}`),
    },
    {
      icon: <PowerIcon/>,
      title: t("Destination.MainsVoltage"),
      value: `${mainsVoltage}V`,
    },
  ];

  return <Grid container spacing={2}
               direction="row"
               justifycontent="space-evenly"
               alignItems="center"
  >
    {
      items.map(
        (item, i) => {
          return item.value && <Grid
            item xs={12} sm={6} md={4} lg={3}
            key={`about-${i}`}
          >
            <Insight item={item}/>
          </Grid>;
        },
      )
    }
  </Grid>;
};
