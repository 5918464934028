import React, { Component } from "react";
import { localizeRate } from "../../common/money";
import { homestayWhere } from "../common/strings";
import HomestayMatchAttrs from "./HomestayMatchAttrs.jsx";
import { Card, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Flare } from "@material-ui/icons";
import { useViewport } from "../../component/ViewportProvider";

// Pixel width of the viewport at which text starts being removed due to the
// screen being too small.
const ABBREVIATE_CARD_VIEWPORT_WIDTH = 800;
// TODO use nowrap=true so we don't need to remove these attributes

const useStyles = makeStyles({
  card: {
    width: "100%",
    maxWidth: "100%",
    height: "170px",
    boxShadow: "0 1px 4px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.24)",
    transition: "all 0.5s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 1px 12px rgba(0,0,0,0.25), 0 1px 5px rgba(0,0,0,0.22)",
    },
  },
  grid: {
    height: "100%",
  },
  media: {
    maxHeight: "100%",
    backgroundColor: "#eee",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100%",
    minWidth: "100%",
  },
  featured: {
    display: "inline-block",
    padding: "1px 5px",
    margin: "4px",
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "#fff",
    borderRadius: "5px",
    textShadow: "1px 1px #000",
  },
  content: {
    padding: "5px 15px",
  },
  name: {
    fontFamily: "'Open Sans', 'Roboto', sans-serif",
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  divider: {
    width: "32px",
    margin: "8px 0 4px 0",
  },
});

function Match({ match, billingPeriod, currency, onMatchHoverChange, t }) {
  const classes = useStyles();
  const { width } = useViewport();
  const reduceText = width < ABBREVIATE_CARD_VIEWPORT_WIDTH;

  const handleMouseEnter = event => {
    onMatchHoverChange(event.currentTarget.dataset["homestayId"]);
  };
  const handleMouseLeave = event => {
    onMatchHoverChange(null);
  };

  const img = `url(${match.cardImageUrl}), url(${process.env.REACT_APP_URL_HS_NO_IMAGE})`;
  // eslint-disable-next-line
  const { featured = false, region, attrs = {} } = match;
  return (
    <>
      <a class="row m-4 text-decoration-none"
         itemScope
         itemType="https://schema.org/LodgingBusiness"
         itemProp="url"
         href={match.url}
         target="_blank"
         id={`match-homestay-${match.uid}`}
         data-homestay-id={match.uid}
         onMouseEnter={handleMouseEnter}
         onFocus={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         onBlur={handleMouseLeave}
      >
        <Card className={classes.card}>
          <Grid container className={classes.grid} alignItems="stretch">
            <Grid item className={classes.media} xs={4}>
              <meta itemProp="image" content={match.cardImageUrl}/>
              <div
                className={classes.image}
                style={{ backgroundImage: img }}
              >
                {featured && <div className={classes.featured}>
                  <Flare fontSize="small"/> {t("Featured")}
                </div>}
              </div>
            </Grid>
            <Grid item className={classes.content} xs={8}>
              <meta itemProp="numberOfRooms" content={attrs.GuestRooms.count}/>
              <meta itemProp="currenciesAccepted" content={match.LocalCurrency}/>
              <meta
                itemProp="smokingAllowed"
                content={String(attrs.hasOwnProperty("SmokingAllowed"))}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                noWrap={true}
                itemProp="address"
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                {homestayWhere(match, t)}
                <meta itemProp="addressLocality" content={match.city}/>
                <meta itemProp="addressCountry" content={match.countryId}/>
                {region && <meta itemProp="addressRegion" content={region}/>}
                {/*<meta itemProp="postalCode" content=""/>*/}
              </Typography>
              <Typography
                variant="h5"
                color="textPrimary"
                component="h5"
                className={classes.name}
                itemProp="name"
                noWrap={true}
              >
                {match.name}
              </Typography>
              <Divider light className={classes.divider} />
              <HomestayMatchAttrs uid={match.uid} attrs={match.attrs} t={t} reduceText={reduceText}/>
              {
                match.fxPrice && billingPeriod && (
                  <p class="text-dark">
                    {localizeRate(match.fxPrice, billingPeriod, currency, t)}
                  </p>
                )
              }
            </Grid>
          </Grid>
        </Card>
      </a>
    </>
  );
}

export default class Matches extends Component {
  render() {
    const { searchResult, t, onMatchHoverChange } = this.props;
    if (!searchResult) {
      return <div><Divider/></div>;
    }
    const { matches, fxCurrency, billingPeriod } = searchResult;
    if (matches === undefined) {
      return <div><Divider/></div>;
    }
    return matches.map(match => (
      <div key={`match-${match.uid}`}>
        <Match
          match={match}
          billingPeriod={billingPeriod}
          currency={fxCurrency}
          t={t}
          onMatchHoverChange={onMatchHoverChange}
        />
      </div>
    ));
  }
}
