import { parseFloatStrict } from "./number";

export type LatLon = {
  lat: number,
  lon: number,
}

export type GeoBounds = {
  NorthWest: LatLon,
  SouthEast: LatLon,
}

export function parseLatLon(lat: number, lon: number): LatLon {
  if (lat === null || lon === null) {
    return null;
  }
  const latParsed = parseFloatStrict(lat);
  const lonParsed = parseFloatStrict(lon);
  if (isNaN(latParsed) || isNaN(lonParsed)) {
    return null;
  }
  return { lat: latParsed, lon: lonParsed };
}

export function latLonEqual(l1: LatLon, l2: LatLon): boolean {
  return l1.lat === l2.lat && l1.lon === l2.lon;
}

export function geoBoundsEqual(b1: GeoBounds, b2: GeoBounds): boolean {
  return (
    latLonEqual(b1.NorthWest, b2.NorthWest)
    && latLonEqual(b1.SouthEast, b2.SouthEast)
  );
}

export function calculateLonDiff(w: number, e: number): number {
  if (w <= e) {
    return e - w;
  }
  return (180 - w) + (180 + e);
}
