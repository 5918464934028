import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";


export default function LanguageDialog(
  { t, locales, onClose, open, value, setValue },
) {
  const [language, setLanguage] = useState(value);

  const handleChange = (event: React.ChangeEvent) => {
    const { target: { value } } = event;
    setLanguage(value);
  };

  const handleCancel = () => {
    setLanguage(value);  // reset to input value
    onClose();
  };

  const handleSave = () => {
    setValue(language);  // set value in parent
    onClose();
  };

  return (
    <Dialog disableBackdropClick open={open} onClose={onClose}>
      <DialogTitle>{t("Language")}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label={t("Language")}
            name="language"
            value={language}
            onChange={handleChange}
          >
            {
              Object.entries(locales).map(
                entry => <FormControlLabel
                  key={entry[0]}
                  value={entry[0]}
                  control={<Radio color="primary"/>}
                  label={entry[1]}
                />,
              )
            }
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
