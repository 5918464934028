import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default function GuestCountSelection(props) {
  const handleChange = (event, { props: changeProps }, reason: string) => {
    const { value } = changeProps;
    props.onGuestCountChange(value);
  };

  const { autofocus, style, t, maxGuests, guestCount } = props;
  return (
    <FormControl variant="outlined">
      <InputLabel id="guestCountLabel">{t("Guests")}</InputLabel>
      <Select
        id="guestCount"
        autoFocus={autofocus}
        value={guestCount || ""}
        onChange={handleChange}
        style={style}
        label={t("Guests")}
      >
        <MenuItem value={1}>{t("1Guest")}</MenuItem>
        {
          Array(maxGuests - 1).fill().map(
            (_, i) => <MenuItem value={i + 2} key={i + 2}>
              {t("NGuests", { n: i + 2 })}
            </MenuItem>,
          )
        }
      </Select>
    </FormControl>
  );
}
