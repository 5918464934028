import React, { useEffect, useState } from "react";
import { Map, Marker, ScaleControl, TileLayer, Tooltip, ZoomControl } from "react-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import { getMapPixelSize, makeBufferedBounds } from "../../common/leaflet";
// import ResponsivePopup from "../../component/ResponsivePopup.jsx";
import HostMarkerPopup from "./HostMarkerPopup.jsx";
import { distanceAtLat } from "../../common/geo";
// TODO upgrade react-leaflet from 2.7.0 to 3.x
// this is a breaking change: https://github.com/PaulLeCam/react-leaflet/releases/tag/v3.0.0

// Create a class for each z-index to force layering of the tooltips such that
// earlier matches are above later matches.
const zIndexes = Object.fromEntries(
  [...Array(20).keys()].map(
    i => [`z${i}`, { zIndex: i }],
  ),
);

const useStyles = makeStyles(
  theme => ({
    marker: {
      fontWeight: "bold",
      borderRadius: "12px",
      boxShadow: "0 1px 3px rgba(0,0,0,0.6)",
      padding: "5px 8px",
      fontSize: "110%",
      fontFamily: "'Roboto', sans-serif",
      color: "#555",
      zIndex: 1,
      overflow: "hidden",
    },
    featured: {
      color: "#000",
    },
    triangle: {
      width: 0,
      height: 0,
      borderStyle: "solid",
      position: "absolute",
      borderWidth: "0 14px 14px 0",
      borderColor: "transparent #228b22 transparent transparent",
      right: 0,
      top: 0,
    },
    ...zIndexes,
    // This must be defined after zIndexes to ensure that the z-index here
    // on hover takes precedence over the fixed non-hover z-indexes.
    markerHover: {
      zIndex: 1000,
      backgroundColor: "#000",
      color: "#fff",
      transform: "scale(1.5)",
    },
  }),
);

function HostMarkerTooltip(props) {
  const { fxPrice, currency, highlighted, featured, zIndexOffset, classes, t } = props;
  if (!fxPrice) {
    return null;
  }

  const tooltipClassNames = [classes.marker, classes[`z${zIndexOffset}`]];
  if (highlighted) {
    tooltipClassNames.push(classes.markerHover);
  }
  if (featured) {
    tooltipClassNames.push(classes.featured);
  }
  return <Tooltip
    direction="center"
    offset={[-15, 25]}
    zIndexOffset={zIndexOffset}
    className={tooltipClassNames.join(" ")}
    interactive
    permanent
  >
    {t("{{price, price}}", { price: fxPrice, currency: currency })}
    {featured && <div className={classes.triangle}/>}
  </Tooltip>;
}

function HostMarker(props) {
  const { match, hoveredMatch, billingPeriod, zIndexOffset, currency, t, classes } = props;
  const { uid, fxPrice, approxLoc, featured = false } = match;
  const [mouseOver, setMouseOver] = useState(false);
  if (!approxLoc) {
    return null;
  }
  const position = [approxLoc.lat, approxLoc.lon];

  const handleMouseEnter = event => {
    setMouseOver(true);
  };
  const handleMouseLeave = event => {
    setMouseOver(false);
  };
  const handleClick = event => {
//    onClick(match.uid);
  };

  const opacity = fxPrice ? 0 : 1;
  return (
    <Marker
      position={position}
      opacity={opacity}
      key={`marker-${uid}`}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      onClick={handleClick}
    >
      <HostMarkerTooltip
        fxPrice={fxPrice}
        currency={currency}
        highlighted={uid === hoveredMatch || mouseOver}
        featured={featured}
        zIndexOffset={zIndexOffset}
        classes={classes}
        t={t}
      />
      <HostMarkerPopup
        homestay={match}
        billingPeriod={billingPeriod}
        currency={currency}
        t={t}
      />
    </Marker>
  );
}
//      <ResponsivePopup autoPan={false} />

function makeHostMarkers(
  matches,
  hoveredMatch?: string,
  openedMarker?: string,
  onClick,
  billingPeriod,
  currency: string,
  t,
  classes,
) {
  return matches.map(
    (match, idx: number) => <HostMarker
      key={match.uid}
      match={match}
      hoveredMatch={hoveredMatch}
      openedMarker={openedMarker}
      onClick={onClick}
      billingPeriod={billingPeriod}
      currency={currency}
      zIndexOffset={matches.length - idx - 1}
      t={t}
      classes={classes}
    />,
  );
}

function makeBoundsFromGeoBounds(geoBounds) {
  const n = geoBounds.NorthWest.lat;
  const w = geoBounds.NorthWest.lon;
  const s = geoBounds.SouthEast.lat;
  const e = geoBounds.SouthEast.lon;
  return [[n, w], [s, e]];
}

function makeGeoBoundsFromLatLngBounds(bounds) {
  return {
    NorthWest: { lat: bounds.getNorthWest().lat, lon: bounds.getNorthWest().lng },
    SouthEast: { lat: bounds.getSouthEast().lat, lon: bounds.getSouthEast().lng },
  };
}

export default function HostMap(props) {
//  const map = useMap();
  // hoveredMatch when set indicates that the mouse currently hovers over the
  // card associated to a marker on the map and that the marker should be
  // highlighted.
  const { visible, searchResult, mapBounds, currency, hoveredMatch, onBoundsChange, t } = props;
  const classes = useStyles();
  // Tracks which marker was clicked to open the match's popup.
  const [openedMarker, setOpenedMarker] = useState(null);

  // update the map on a search change
  useEffect(() => {
  }, [mapBounds, searchResult]);

//  const [mapPixelSize, setMapPixelSize] = useState(null);

  if (!searchResult) {
    return <p></p>;
  }
  // need to know if the bounds came from the user panning the map or api response from a market search
  // search type:
  // * market provided and geobounds generated from api response
  //   * set map bounds to api response + padding buffer
  // * user panned/zoomed the map
  //   * outer bounds would be the bounds of the map
  //   * inner bounds would be the search area with a padding buffer applied (e.g., 25px)
  //   * set the state to the inner bounds, which also propagates to the search params
  //   * a page reload can show a slightly different map but the results will be the same
  // * bookmarkable page loaded with geobounds specified in search params
  //   * should be the same as user panning/zooming the map
  //
  // presence of lat/lon in search params discriminates between the two
  //
  // internally we track 3 states:
  // * market_bounds: bounds in the api response from a market search
  //   * rendered in <Map> with pad buffer
  // * search_bounds: bounds from the search params
  //   * overrides whatever is in the api response and should always enclose the api response
  //   * rendered in <Map> with pad buffer
  //   * cleared when a new search is done on a market
  // * map_event_bounds: bounds from the latest map event
  //   * sets the search params on each map event by taking the selected map bounds and setting to smaller bounds to handle pad buffer
  //   * because the map bounds differ from the search params they generate, they override whatever is in the search params
  //   * cleared when a new search is done on a market

  const { geoBounds, matches, billingPeriod } = searchResult;

  // Determine which bounds to use on the map.
  let bounds;
  let buffer: number;
  if (mapBounds) {
    // Bound has been set due to pan/zoom interaction so we "pin" it to what
    // the user selected. The bounds sent to the API are smaller than these
    // bounds to provide a pad buffer and therefore it's not necessary to add
    // padding here.
    bounds = mapBounds;
    buffer = 0;
  } else if (false) {
    // Bounds were given in the bookmarkable GET request params, which were set
    // in a previous pan/zoom interaction and then the page was reloaded. Use
    // these bounds and add a pad buffer.
    // TODO
  } else {
    // No bounds were specified which means we're using the requested market's
    // bounds defined in the API response.
    bounds = geoBounds;
    buffer = 22;
  }

  const xBounds = makeBoundsFromGeoBounds(bounds);

  // TODO differentiate between a pan/zoom and window resize
  const handleResize = event => {
    if (!visible) {
      // don't change anything when the map isn't visible to the user
      return;
    }

    // don't care what the new map bounds are after resize
    // just want to fit its bounds using whatever previous bounds were specified
    // this is either the bounds sent in the api request or bounds in the api response
    const b = 20;
    const map = event.target;
    const { x, y } = getMapPixelSize(map);
    const bounds = makeBufferedBounds({ x, y }, geoBounds, b);
    // debugger;
    setOpenedMarker(null);
    map.fitBounds(bounds);
    map.invalidateSize();
    // TODO limit the aspect ratio so a very vertical map only searches toward the middle of the map
    // |-----------------------------------------------|
    // |        this is N pixels                       |
    // |    |-------------------------------------|    |
    // |    |  this is the search area:           |    |
    // |    |  n/s lat + w/e lon                  |    |
    // |    |                                     |    |
    // |    |-------------------------------------|    |
    // |                                               |
    // |-----------------------------------------------|
  };

  // Fires on a pan or zoom event.
  const handleBoundsChange = event => {
    if (!visible) {
      // Don't change anything when the map isn't visible to the user. Otherwise
      // mapBounds below will contain the same NW and SE points representing
      // nothing.
      return;
    }
    const buffer2 = 40;
    const mapBounds = event.target.getBounds();
    const { x, y } = getMapPixelSize(event.target);
    // Derive new bounds to search by applying a pad buffer to the bounds
    // selected by the user.
    const pnw = [buffer2, buffer2];
    const pse = [x - buffer2, y - buffer2];
    const lnw = event.target.containerPointToLatLng(pnw);
    const lse = event.target.containerPointToLatLng(pse);
    const searchBounds = {
      NorthWest: { lat: lnw.lat, lon: lnw.lng },
      SouthEast: { lat: lse.lat, lon: lse.lng },
    };
    const mapBoundsx = makeGeoBoundsFromLatLngBounds(mapBounds);
    setOpenedMarker(null);
    onBoundsChange(searchBounds, mapBoundsx);
//    setMapPixelSize({x, y});
  };

  const handleDragEnd = event => handleBoundsChange(event);
  const handleZoomEnd = event => handleBoundsChange(event);
  const handleMarkerClick = uid => setOpenedMarker(uid);
  const handleMapClick = event => setOpenedMarker(null);

  return (
    <Map
      bounds={xBounds}
      boundsOptions={{ padding: [buffer, buffer] }}
      scrollWheelZoom={false}
      zoomControl={false}
      minZoom={4}  // restricts zoom out to roughly a continent
      onDragend={handleDragEnd}
      onZoomend={handleZoomEnd}
      onResize={handleResize}
      onClick={handleMapClick}
      id="map"
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="topright"/>
      <ScaleControl position={"bottomleft"}/>
      {matches && makeHostMarkers(matches, hoveredMatch, openedMarker, handleMarkerClick, billingPeriod, currency, t, classes)}
    </Map>
  );
}
//        <Rectangle bounds={xBounds} pathOptions={{backgroundColor: "red"}} />
//        <Rectangle bounds={makeBoundsFromGeoBounds(geoBounds)} pathOptions={{backgroundColor: "red"}} />
