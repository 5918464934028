import { HostMapCard } from "../../homestaysearch/component/HostMarkerPopup";
import React from "react";
import { Grid } from "@material-ui/core";

export default function HomestayCarousel(
  { homestays, billingPeriod, currency, keyPrefix, t },
) {
  return <Grid
    container spacing={2}
    direction="row"
    justifycontent="space-evenly"
    alignItems="center"
  >
    {
      homestays.map(
        homestay => <Grid
          item xs={12} sm={6} md={4} lg={3}
          key={`homestay-${keyPrefix}-${homestay.uid}`}
        >
          <HostMapCard
            homestay={homestay}
            billingPeriod={billingPeriod}
            currency={currency}
            nameComponent="h3"
            t={t}
          />
        </Grid>,
      )
    }
  </Grid>;
}
