import React, { Component } from "react";
import SearchOption from "./SearchOption.jsx";
import Rheostat from "rheostat";
import "rheostat/initialize";
import "./react_rheostat_overrides.css";
import { Column, Histogram, ColumnWrapper } from "./styled";
import "./rheostat.css";  // source: https://unpkg.com/rheostat@4.1.1/css/rheostat.css

function Columns(props) {
  const { frequencies } = props;
  const width = `${100 / frequencies.length}%`;
  return (
    <ColumnWrapper>
      {frequencies.map(value => <Column key={value} height={value} width={width}/>)}
    </ColumnWrapper>
  );
}

export default class SearchPriceOption extends Component {
  constructor(props) {
    super(props);
    this.isDirtyState = this.isDirtyState.bind(this);
    this.clearState = this.clearState.bind(this);
    this.revertState = this.revertState.bind(this);
    this.saveState = this.saveState.bind(this);
    this.handleValuesChanged = this.handleValuesChanged.bind(this);
    const { priceMin, priceMax } = props;
    this.state = { priceMin, priceMax };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      // reset state when the price range is reset outside this component
      const { priceMin, priceMax } = this.props;
      this.setState({ priceMin, priceMax });
    }
  }

  searchResultPriceRange() {
    const { searchResult } = this.props;
    if (searchResult === null) {
      return null;
    }
    const { priceLower, priceUpper } = searchResult;
    if (priceLower !== undefined && priceUpper !== undefined) {
      return [priceLower, priceUpper];
    }
    return null;
  }

  isDirtyState() {
    const priceRange = this.searchResultPriceRange();
    if (!priceRange) {
      return false;
    }
    const { priceMin, priceMax } = this.state;
    return (
      (priceMin > 0 && priceMin > priceRange[0])
      || (priceMax > 0 && priceMax < priceRange[1])
    );
  }

  clearState() {
    this.setState({ priceMin: 0, priceMax: 0 });
  }

  revertState() {
    const { priceMin, priceMax } = this.props;
    this.setState({ priceMin, priceMax });
  }

  saveState() {
    const { onStateChange } = this.props;
    const { priceMin, priceMax } = this.state;
    const [priceLower, priceUpper] = this.searchResultPriceRange();
    onStateChange({
      // set each to 0 to indicate unselected/unbounded
      priceMin: (priceMin <= priceLower) ? 0 : priceMin,
      priceMax: (priceUpper <= priceMax) ? 0 : priceMax,
    });
  }

  handleValuesChanged(sliderState) {
    this.setState({ priceMin: sliderState.values[0], priceMax: sliderState.values[1] });
  }

  startValues(priceRange) {
    const { priceMin, priceMax } = this.state;
    const startMin = Math.max(priceMin, priceRange[0]);
    const startMax = priceMax === 0 ? priceRange[1] : Math.min(priceMax, priceRange[1]);
    return [startMin, startMax];
  }

  makeButtonLabel() {
    const { t, searchResult } = this.props;

    const priceRange = this.searchResultPriceRange();
    if (!priceRange) {
      return t("Price");
    }

    const { priceMin, priceMax } = this.state;
    const priceMinSet = priceMin > 0 && priceMin > priceRange[0];
    const priceMaxSet = priceMax > 0 && priceMax < priceRange[1];

    if (!priceMinSet && !priceMaxSet) {
      return t("Price");
    }
    const { fxCurrency } = searchResult;
    if (priceMinSet && priceMaxSet) {
      return t("PriceRange", { min: priceMin, max: priceMax, currency: fxCurrency });
    }
    if (priceMinSet) {
      return t("PriceMinOnly", { min: priceMin, currency: fxCurrency });
    }
    return t("PriceMaxOnly", { max: priceMax, currency: fxCurrency });
  }

  makeRangeLabel() {
    const { t, currency, searchResult } = this.props;
    const { priceMin, priceMax } = this.state;
    const priceRange = this.searchResultPriceRange();
    const unboundedMax = priceMax === 0 || priceMax >= priceRange[1];
    const { longTailPrices = false } = searchResult;
    return t(
      unboundedMax && longTailPrices ? "PriceRangeUnboundedMax" : "PriceRange",
      {
        min: Math.max(priceMin, priceRange[0]),
        max: unboundedMax ? priceRange[1] : priceMax,
        currency,
      },
    );
  }

  render() {
    const { t, searchResult } = this.props;
    if (searchResult === null) {
      // rendering before a search has been completed
      return null;
    }
    const { count } = searchResult;
    if (count === 0) {
      // search produces no matches and therefore we don't have a range to select between
      return (
        <SearchOption
          t={t}
          label={this.makeButtonLabel()}
          title={t("SearchNoMatches")}
          actionLabel={t("GotIt")}
        >
        </SearchOption>
      );
    }

    const priceRange = this.searchResultPriceRange();
    if (priceRange === null) {
      return (
        <SearchOption
          t={t}
          label={this.makeButtonLabel()}
          title={t("AddCheckInForPrices")}
          actionLabel={t("GotIt")}
        >
        </SearchOption>
      );
    }

    return (
      <SearchOption
        t={t}
        optimalWidth={600}
        label={this.makeButtonLabel()}
        title={t("SearchPriceTitle")}
        isDirtyState={this.isDirtyState}
        clearState={this.clearState}
        revertState={this.revertState}
        saveState={this.saveState}
      >
        <div class="text-center">{this.makeRangeLabel()}</div>
        <div class="p-3">
          <Histogram>
            <Columns frequencies={searchResult.frequencies}/>
            <Rheostat
              onValuesUpdated={this.handleValuesChanged}
              min={priceRange[0]}
              max={priceRange[1]}
              values={this.startValues(priceRange)}
            />
          </Histogram>
        </div>
      </SearchOption>
    );
  }
}
