import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { encodeUrlParams, makeUrl } from "../../common/url";
import { DESTINATION_PATH, HOME_PATH } from "../../constants/app";
import PlaceLink from "./PlaceLink";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    link: {
      fontWeight: 300,
      fontSize: "0.85rem",
      color: "#555",
    },
    leaf: {
      fontWeight: 400,
      fontSize: "0.85rem",
    },
  }),
);

export interface BreadcrumbNode {
  name: string;
  slug: string;
}

export interface BreadcrumbsNodes {
  // Ordered from leaf to root.
  nodes: BreadcrumbNode[];
}

// TODO support flags in Windows:
// https://stackoverflow.com/questions/62729729/how-do-i-view-country-flags-on-windows-10-through-html
export function LandingPageBreadcrumb(
  { nodes, renderLeafAsLink = false, urlParams, setPlaceSlug, t },
) {
  const classes = useStyles();
  // nodes are ordered where first element is the leaf, so reverse here for rendering
  const schemaId = (nodes && nodes[0].slug) || "";
  const structuredBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": `https://www.homestaybay.com/destination/${schemaId}#breadcrumb`,
    "name": "DestinationBreadcrumb",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": t("Homestays"),
        "item": makeUrl("", urlParams),
      },
      ...nodes.map(
        (node: BreadcrumbNode, i: number) => {
          return {
            "@type": "ListItem",
            "position": nodes.length - i + 1,
            "name": node.name,
            "item": makeUrl(`${DESTINATION_PATH}/${node.slug}`, urlParams),
          };
        },
      ).reverse(),
    ],
  };

  return (
    <div className={classes.root}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredBreadcrumbs) }}
      />
      <Breadcrumbs separator="›" aria-label="You are here:" className={classes.link}>
        <RouterLink
          to={{ pathname: HOME_PATH, search: encodeUrlParams(urlParams) }}
          color="inherit"
          className={classes.link}
        >{t("Homestays")}</RouterLink>
        {
          nodes.map(
            (node: BreadcrumbNode, i: number) => {
              if (i > 0 || renderLeafAsLink) {
                return <PlaceLink
                  slug={node.slug}
                  urlParams={urlParams}
                  setPlaceSlug={setPlaceSlug}
                  className={classes.link}
                  key={`breadcrumb-link-${node.slug}`}
                >
                  {node.name}
                </PlaceLink>;
              }
              // The first element is the current page so we don't render a link.
              return <Typography
                color="textPrimary"
                className={classes.leaf}
                key={`breadcrumb-${node.slug}`}
              >
                {node.name}
              </Typography>;
            },
          ).reverse()
        }
      </Breadcrumbs>
    </div>
  );
}
