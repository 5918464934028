import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, makeStyles } from "@material-ui/core";
import InternalLink from "../../component/InternalLink";
import { MAIN } from "../../constants/styles.ts";
import { HelpBreadcrumb } from "./Breadcrumbs";
import ArticleBoxes from "./ArticleBoxes";
import {
  ARTICLE_LINK,
  BOX_LINK,
  BOX_LINK_ARROW,
  EXCERPT,
  HELP_LINK,
} from "./styles";

const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      root: {
        flexGrow: 1,
      },
      helpLink: HELP_LINK,
      boxLink: BOX_LINK,
      boxLinkArrow: BOX_LINK_ARROW,
      excerpt: EXCERPT,
      articleLink: ARTICLE_LINK,
      tabs: {
        position: "absolute",
        bottom: "0",
        display: "flex",
      },
      main: MAIN(),
    },
  ),
);

function TopicLinks({ topics, setPath, urlParams, classes }) {
  return <Box>
    <section>
      {
        topics.map(
          topic => <p key={`topic-${topic.path}`}>
            <InternalLink
              path={topic.path}
              setPath={setPath}
              urlParams={urlParams}
              className={classes.helpLink}
            >
              {topic.label}
            </InternalLink>
          </p>,
        )
      }
    </section>
  </Box>;
}

function TopicsBoxes({ setPath, topics, urlParams, classes }) {
  return topics.map(
    topic => <Box key={`topic-${topic.path}`}>
      <section>
        <h2>{topic.label}</h2>
        <ArticleBoxes
          articles={topic.articles}
          setPath={setPath}
          urlParams={urlParams}
          classes={classes}
        />
      </section>
    </Box>,
  );
}

export function TopicContent({ data, setPath, urlParams, t }) {
  const classes = useStyles();
  const { parents, topic } = data;
  const { articles, childPaths, children, related = [], siblings = [] } = topic;
  // TODO filter siblings to same audience
  return (
    <>
      <main className={classes.main}>
        <HelpBreadcrumb
          parents={parents || []}
          title={topic.label}
          urlParams={urlParams}
          setPath={setPath}
          t={t}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <h1>{topic.label}</h1>
            {
              articles && <ArticleBoxes
                articles={articles}
                setPath={setPath}
                urlParams={urlParams}
                classes={classes}
              />
            }
            {
              childPaths && <TopicLinks
                topics={childPaths}
                setPath={setPath}
                urlParams={urlParams}
                classes={classes}
              />
            }
            {
              children && <TopicsBoxes
                setPath={setPath}
                topics={children}
                urlParams={urlParams}
                classes={classes}
              />
            }
          </Grid>
          <Grid item xs={12} md={4}>
            {
              (related || siblings) && (
                <>
                  <h2>{t("RelatedTopics")}</h2>
                  <TopicLinks
                    topics={[...related, ...siblings]}
                    setPath={setPath}
                    urlParams={urlParams}
                    classes={classes}
                  />
                </>
              )
            }
          </Grid>
        </Grid>
      </main>
    </>
  );
}
