import React from "react";
import { makeAttrsLine } from "../common/strings";

// Defines which attributes appear on which line and their ordering.
const attrsLabels = [
  ["GuestRooms", "SmokingAllowed", "NonSmoking", "HostSmokes", "Children"],
  [
    "Meals",
    "Breakfast",
    "Lunch",
    "Dinner",
    "Laundry",
    "PetsInHome",
    "WelcomesCouples",
    "WelcomesFamilies",
  ],
  ["Wifi", "WiredInternet", "NoInternet", "MinStay"],
];
// Attributes to exclude when the text is reduced due to a narrow viewport.
const excludeWhenReducedText = [
  "Children",
  "PetsInHome",
  "WelcomesCouples",
  "WelcomesFamilies",
];

export default function HomestayMatchAttrs({ uid, attrs, t, reduceText }) {
  return (
    <div key={`${uid}-000`} itemProp="description">
      {
        attrsLabels.map(
          (labels: string[], i: number) => {
            // It might be possible to cut off the spans exceeding the width of
            // the card:
            // https://stackoverflow.com/questions/57974995/use-css-to-separate-groups-of-words-and-force-line-break
            const labelsFiltered = labels.filter(
              label => !reduceText || !excludeWhenReducedText.includes(label),
            );
            const line = makeAttrsLine({ labels: labelsFiltered, attrs, t });
            if (line) {
              return (
                <p class="m-0 text-secondary" key={`${uid}-${i}`}>
                  <small>{line}</small>
                </p>
              );
            }
            return null;
          },
        )
      }
    </div>
  );
}
