import React from "react";

export default function HeroImage({ photo, alt, classes, ...props }) {
  const { urls, rgb } = photo;
  return <picture {...props}>
    {
      // Render all but the last URL with max-width media attributes.
      urls.slice(0, -1).map(
        url => <source
          key={`hero-${url.width}`}
          media={`(max-width: ${url.width}px)`}
          srcSet={url.url}
        />,
      )
    }
    {
      // If only one URL is provided, no need to add any <source> tags.
      // The <img> tag below handles it.
      urls.length > 1 && <source
        media={`(min-width: ${urls[urls.length - 2].width + 1}px)`}
        srcSet={urls[urls.length - 1].url}
      />
    }
    <img src={urls[urls.length - 1].url} alt={alt} className={classes.heroImage}/>
  </picture>;
}
