import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { toFlagEmoji } from "../../common/country";
import PlaceLink from "./PlaceLink";

const useStyles = makeStyles({
  img: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform .5s",
    transform: "scale(1)",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0) 30%)",
  },
  label: {
    position: "absolute",
    bottom: 15,
    left: 15,
    color: "#fff",
    textShadow: "0 0px 7px rgb(0 0 0)",
  },
  box: {
    position: "relative",
    backgroundColor: "#888",
    width: "100%",
    height: "100%",
    "&:hover": {
      textDecoration: "none",
    },
    "&:hover img": {
      transform: "scale(1.03)",
    },
    "& *": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& h5": {
      fontSize: "1.15rem",
      fontWeight: 700,
      "@media (max-width: 1200px)": {
        fontSize: "1.05rem",
      },
    },
  },
  gridouter: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1rem",
    "@media (max-width: 800px)": {
      gridTemplateColumns: "1fr",
    },
  },
  gridinner: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1rem",
    "@media (max-width: 600px)": {
      gridTemplateColumns: "1fr",
    },
  },
  item: {
    display: "flex",
    overflow: "hidden",
    borderRadius: "5px",
    "&::before": {
      content: "\"\"", // must be double-quoted: https://stackoverflow.com/a/53772369
      display: "block",
      height: 0,
      width: 0,
      paddingBottom: "calc(9/16 * 100%)",
    },
  },
});

// Defines the sizes attribute of the source tag.
// * Up to 600px: every image fills the width
// * Up to 800px:
//   * primary image remains 1 column above secondary images (100% of width)
//   * the secondary images split to 2 columns (50% of width)
// * > 800px:
//   * primary image is split into left side of 2 columns (50% of width)
//   * secondary images split across 2 columns on right column (25% of width)
const pictureSizes = {
  0: "(max-width: 600px) 100vw, (max-width: 800px) 50vw, 25vw",
  1: "(max-width: 800px) 100vw, 50vw",
};

function Picture({ photo, isPrimary, alt, classes }) {
  const { urls, rgb } = photo;
  const srcSet = urls.map(url => `${url.url} ${url.width}w`).join(", ");
  return <picture className={classes.img}>
    <source srcSet={srcSet} sizes={pictureSizes[isPrimary ? 1 : 0]}/>
    <img src={urls[urls.length - 1].url} alt={alt} className={classes.img}/>
  </picture>;
}

function GridItem(
  { city, urlParams, setPlaceSlug, isPrimary, classes, ...props },
): PlaceLink {
  const { name, slug, photo, countryCode } = city;
  return <PlaceLink
    slug={slug}
    urlParams={urlParams}
    setPlaceSlug={setPlaceSlug}
    className={classes.box}
    {...props}
  >
    <Picture
      photo={photo}
      isPrimary={isPrimary}
      alt={name}
      classes={classes}
    />
    <Box
      component="span"
      className={classes.overlay}
    />
    <Typography variant="h5" color="textPrimary" className={classes.label}>
      {countryCode && toFlagEmoji(countryCode)} {name}
    </Typography>
  </PlaceLink>;
}

export function CityPile({ cities, urlParams, setPlaceSlug }) {
  const classes = useStyles();
  return <div className={classes.gridouter}>
    <div className={classes.item}>
      <GridItem
        city={cities[0]}
        urlParams={urlParams}
        setPlaceSlug={setPlaceSlug}
        isPrimary={true}
        classes={classes}
      />
    </div>
    <div className={classes.item}>
      <div className={classes.gridinner}>
        {
          cities.slice(1).map(
            city => <div
              className={classes.item}
              key={`city-${city.slug}`}
            >
              <GridItem
                city={city}
                urlParams={urlParams}
                setPlaceSlug={setPlaceSlug}
                isPrimary={false}
                classes={classes}
              />
            </div>,
          )
        }
      </div>
    </div>
  </div>;
}
