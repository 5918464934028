import { MEDIA_MIN_WIDTH_1128, MEDIA_MIN_WIDTH_768 } from "../../common/widths";
import { H1 } from "../../constants/styles";

// General H1 centered at the top of the page.
export const H1_CENTER = {
  textAlign: "center", // align items conditional on page width
  marginTop: "1em",
  marginBottom: "16px",
  ...H1,
};
export const HELP_LINK = {
  color: "#212529",
  textDecoration: "underline",
  textDecorationColor: "#717579",
  textDecorationThickness: "1px",
  textUnderlineOffset: "0.1em",
  "&:hover": {
    color: "#212529",
    textDecorationColor: "#717579",
    textDecorationThickness: "1.5px",
  },
};
export const BOX_LINK = {
  padding: "1.5em 0",
  borderBottom: "1px solid #ddd",
  color: "#212529",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none", // affects <Link>
    backgroundColor: "#f8f8f8", // affects <Box>
  },
  "&:hover svg": {
    color: "#fff",
    backgroundColor: "#6ac82c",
    // padding: theme.spacing(1.5),
    borderRadius: "50%",
  },
  "& p": {
    marginLeft: "0.5em",
  },
};
export const BOX_LINK_ARROW = {
  color: "#6ac82c",
};
export const EXCERPT = {
  color: "#818589",
  margin: 0,
  fontSize: "0.9em",
};
export const ARTICLE_LINK = {
  margin: 0,
};
