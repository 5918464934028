import React, { Component } from "react";
import {
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import SearchOption from "../../component/SearchOption.jsx";

// All of the secondary filters under "more filters".
export default class SearchMoreOption extends Component {
  constructor(props) {
    super(props);
    this.isDirtyState = this.isDirtyState.bind(this);
    this.clearState = this.clearState.bind(this);
    this.revertState = this.revertState.bind(this);
    this.saveState = this.saveState.bind(this);
    this.handleNoPetsChange = this.handleNoPetsChange.bind(this);
    this.handleLaundryChange = this.handleLaundryChange.bind(this);
    this.handleSmokingChange = this.handleSmokingChange.bind(this);
    const { noPets, laundry, smoking } = props;
    this.state = { noPets, laundry, smoking };
  }

  isDirtyState() {
    const { noPets, laundry, smoking } = this.state;
    return noPets || laundry || smoking === "non_smoking" || smoking === "smoking";
  }

  clearState() {
    this.setState({ noPets: false, laundry: false, smoking: "no_preference" });
  }

  revertState() {
    const { noPets, laundry, smoking } = this.props;
    this.setState({ noPets, laundry, smoking });
  }

  saveState() {
    const { onStateChange } = this.props;
    const { noPets, laundry, smoking } = this.state;
    onStateChange({ noPets, laundry, smoking });
  }

  handleNoPetsChange(event) {
    const { checked } = event.target;
    this.setState({ noPets: checked });
  }

  handleLaundryChange(event) {
    const { checked } = event.target;
    this.setState({ laundry: checked });
  }

  handleSmokingChange(event) {
    const { value } = event.target;
    this.setState({ smoking: value });
  }

  makeButtonLabel() {
    const { t } = this.props;
    const { noPets, laundry, smoking } = this.state;
    const selected = [];
    if (noPets) {
      selected.push(t("NoPets"));
    }
    if (laundry) {
      selected.push(t("Laundry"));
    }
    if (smoking === "non_smoking") {
      selected.push(t("NonSmoking"));
    } else if (smoking === "smoking") {
      selected.push(t("Smoking"));
    }

    if (selected.length > 0) {
      return selected.join(", ");
    }
    return t("MoreFilters");
  }

  render() {
    const { t } = this.props;
    const { noPets, laundry, smoking } = this.state;
    return (
      <SearchOption
        t={t}
        label={this.makeButtonLabel()}
        title={t("MoreFilters")}
        isDirtyState={this.isDirtyState}
        clearState={this.clearState}
        revertState={this.revertState}
        saveState={this.saveState}
      >
        <form class="m-3">
          <div class="mb-4">
            <FormControlLabel
              control={
                <Switch
                  size="medium"
                  color="primary"
                  checked={noPets}
                  onChange={this.handleNoPetsChange}
                />
              }
              label={t("FilterPetsLabel")}
            />
          </div>
          <div class="mb-4">
            <FormControlLabel
              control={
                <Switch
                  size="medium"
                  color="primary"
                  checked={laundry}
                  onChange={this.handleLaundryChange}
                />
              }
              label={t("FilterLaundryLabel")}
            />
          </div>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("FilterSmokingLabel")}</FormLabel>
            <RadioGroup
              aria-label={t("FilterSmokingLabel")}
              name="smoking"
              value={smoking}
              onChange={this.handleSmokingChange}
            >
              <FormControlLabel
                value="non_smoking"
                control={<Radio color="primary"/>}
                label={t("NonSmoking")}
              />
              <FormControlLabel
                value="smoking"
                control={<Radio color="primary"/>}
                label={t("Smoking")}
              />
              <FormControlLabel
                value="no_preference"
                control={<Radio color="primary"/>}
                label={t("NoPreference")}
              />
            </RadioGroup>
          </FormControl>
        </form>
      </SearchOption>
    );
  }
}
