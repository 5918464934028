// Convert country code to its flag emoji.
export function toFlagEmoji(countryCode: string): string {
  // TODO flags on Windows: https://lepture.com/en/2022/windows-country-flags-emoji
  // https://stackoverflow.com/questions/62729729/how-do-i-view-country-flags-on-windows-10-through-html
  // https://stackoverflow.com/questions/54519758/flag-emojis-not-rendering/54663926
  // https://github.com/talkjs/country-flag-emoji-polyfill
  // https://stackoverflow.com/a/72731942
  const s = countryCode.toUpperCase();
  if (s.length !== 2) {
    return s;
  }
  return (
    String.fromCodePoint(127397 + s[0].charCodeAt(0))
    + String.fromCodePoint(127397 + s[1].charCodeAt(0))
  );
}

// TODO this could be used to serve the flag on Windows as a react component
// const flagEmojiToPNG = (flag) => {
//   // see: https://flagpedia.net/download/api
//   const countryCode = Array.from(
//     flag, (codeUnit) => codeUnit.codePointAt(),
//   ).map(char => String.fromCharCode(char-127397).toLowerCase()).join("")
//   return <img src={`https://flagcdn.com/24x18/${countryCode}.png`} alt='flag' />;
// }
