import React from "react";
import { AppBar, Grid, Toolbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import StickyBox from "react-sticky-box";
import {
  makeHelpLink,
  makeHomeLink,
  makeMyAccountLink,
  makeRegisterHostLink,
} from "../common/links";
import { NAVBAR_HEIGHT_REDUCES, NAVBAR_SHOW_HOST_SIGNUP } from "../common/widths";
import NavLocaleSelection from "./NavLocaleSelection.jsx";
import NavMainNavigationMenu from "./NavMainNavigationMenu.jsx";
import PrimarySearch from "./PrimarySearch";
import { useViewport } from "./ViewportProvider";
import { Link as RouterLink } from "react-router-dom";
import { makeCookie } from "../common/cookie";

const useStyles = makeStyles({
  root: {
    display: "inline-flex",
    alignItems: "center",
    height: "100%",
  },
  stickyBoxWrapper: {
    position: "absolute",
    justifyContent: "space-around",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  stickyBox: {
    marginTop: "350px",
    zIndex: 1101,
    float: "left",
    top: 0,
    position: "sticky",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${process.env.REACT_APP_URL_LOGO})`,
    width: "223px",
    height: "32px",
    transition: "all 0.1s cubic-bezier(.25,.8,.25,1)",
    "@media (max-width: 1050px)": { // NAVBAR_HEIGHT_REDUCES
      backgroundImage: `url(${process.env.REACT_APP_URL_LOGO_SMALL})`,
      width: "27px",
      height: "28px",
    },
  },
  button: {
    background: "transparent",
    height: "48px",
    padding: "0 16px",
    display: "inline-flex",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "1rem",
    textDecoration: "none !important",
    color: "#000 !important",
  },
});
// 930px is the min width on airbnb before the littlesearch box disappears and the site goes mobile

// function ImgLogo(props) {
//   const classes = useStyles();
//   const { t } = props;
//   const { width } = useViewport();
//   return <img
//     style={{ width: "223px", height: "32px" }}
//     // style={{ width: x, height: "32px" }}
//     src={process.env.REACT_APP_URL_LOGO}
//     alt={t("HomestayBay")}
//     className={classes.root}
//   />;
// }

export default function Nav(
  {
    onStateChange,
    i18n,
    user,
    t,
    saveTransKey,
    params: { checkIn, guestCount, language },
    markets,
    market, // this is the market slug, not its name
    countryName = null,
    locales,
    currencies,
    onSignIn,
    onSignOut,
    currency,
    onSignUpGuestOpen,
    stickySearchBoxOffset = 0,
  },
) {
  const classes = useStyles();
  const { width } = useViewport();

  const handleSignUpGuestOpen = (e: React.MouseEvent<HTMLElement>) => {
    onSignUpGuestOpen(e, market, markets);
  };

  const handleLanguageChange = language => {
    onStateChange({ language });
    i18n.changeLanguage(language);
  };

  const handleCurrencyChange = currency => {
    onStateChange({ priceMin: 0, priceMax: 0 }, null, currency);
  };

  const handlePrimarySearchChange = (market, checkIn, guestCount) => {
    document.cookie = makeCookie("mkt", market);
    onStateChange(
      { checkIn, guestCount, priceMin: 0, priceMax: 0, geoBounds: null },
      { mapBounds: null },  // reset any pan/zoom on the map
      null,
      market,
    );
  };

  const primarySearch = <PrimarySearch
    width={width}
    saveTransKey={saveTransKey}
    t={t}
    locale={i18n.language}
    market={market}
    checkIn={checkIn}
    guestCount={guestCount}
    onChange={handlePrimarySearchChange}
    markets={markets}
    countryName={countryName}
  />;

  // const img = <img
  //   style={{ width: "223px", height: "32px" }}
  //   src={process.env.REACT_APP_URL_LOGO}
  //   alt={t("HomestayBay")}
  // />;
  // const img = <ImgLogo t={t}/>;
  // const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return (
    <>
      <div className={classes.stickyBoxWrapper}>
        <StickyBox className={classes.stickyBox}
                   style={{ marginTop: `${stickySearchBoxOffset}px` }}>
          {primarySearch}
        </StickyBox>
      </div>
      <AppBar
        position="fixed"
        color="inherit"
        className="flex-no-shrink"
        elevation={4}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={3}>
              {
                user !== null && <a
                  href={makeMyAccountLink(i18n.language)}
                  target="_blank"
                  className={classes.root}
                >
                  <div className={classes.image}/>
                </a>
              }
              {
                user === null && <Link
                  to={makeHomeLink(i18n.language)}
                  component={RouterLink}
                  className={classes.root}
                >
                  <div className={classes.image}/>
                </Link>
              }
            </Grid>
            <Grid container xs={6} justify="center">
              <div style={{
                position: "absolute",
                paddingTop: "350px",
                minHeight: "100%",
                display: "flex",
              }}>
              </div>
            </Grid>
            <Grid container xs={3} justify="flex-end">
              <div>
                {
                  !user && width > NAVBAR_HEIGHT_REDUCES && <Button
                    component={RouterLink}
                    to={makeRegisterHostLink(i18n.language)}
                    className={classes.button}
                  >
                    {t("BecomeAHost")}
                  </Button>
                }
                {
                  // Collapse into single menu when width < 700px
                  width >= NAVBAR_SHOW_HOST_SIGNUP && <NavLocaleSelection
                    locales={locales}
                    language={language}
                    onLanguageChange={handleLanguageChange}
                    currencies={currencies}
                    currency={currency}
                    onCurrencyChange={handleCurrencyChange}
                    t={t}
                  />
                }
                {
                  width >= NAVBAR_SHOW_HOST_SIGNUP && <IconButton
                    aria-label={t("Help")}
                    color="inherit"
                    variant="contained"
                    component={RouterLink}
                    to={makeHelpLink(user, i18n.language)}
                  >
                    <HelpOutlineOutlinedIcon titleAccess={t("Help")}/>
                  </IconButton>
                }
                <NavMainNavigationMenu
                  user={user}
                  onSignIn={onSignIn}
                  onSignOut={onSignOut}
                  locale={i18n.language}
                  showLocaleOptions={width < 600}
                  locales={locales}
                  market={market}
                  markets={markets}
                  language={language}
                  currencies={currencies}
                  currency={currency}
                  onLanguageChange={handleLanguageChange}
                  onCurrencyChange={handleCurrencyChange}
                  onSignUpGuestOpen={handleSignUpGuestOpen}
                  t={t}
                />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
