import React, { Suspense, useEffect, useState } from "react";
import Modal from "react-modal";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.css";
import { makeApiserverClient } from "./api/factory.js";
import RouteRenderingError from "./component/RouteRenderingError";
import { ViewportProvider } from "./component/ViewportProvider";
import {
  DESTINATION_PATH,
  HELP_PATH,
  HOME_PATH,
  REGISTER_HOST_PATH,
  ROUTER_PATH,
} from "./constants/app";
import SearchPage from "./homestaysearch/component/SearchPage";
import PlacePage from "./destination/component/PlacePage";
import { HelpPage } from "./help/component/HelpPage";
import { HomePage } from "./home/component/HomePage";
import SignUpGuestDialog from "./component/SignUpGuestDialog";
import { RegisterHostPage } from "./starthosting/component/RegisterHostPage";

Modal.setAppElement("#root");

function App() {
  const { t, i18n } = useTranslation(["common", "markets", "countries", "languages"]);
  const [user, setUser] = useState(null);
  const [signUpGuestOpen, setSignUpGuestOpen] = useState(false);
  // Selected market for the host or guest in SignUpGuestDialog.
  const [signUpMarket, setSignUpMarket] = useState(null);
  const [signUpMarkets, setSignUpMarkets] = useState(null);

  const handleSignUpGuestOpen = (e, market, markets) => {
    if (e) {
      e.preventDefault();
    }
    setSignUpMarket(market);
    setSignUpMarkets(markets);
    setSignUpGuestOpen(true);
  };

  const handleSignUpGuestClose = () => {
    setSignUpGuestOpen(false);
  };

  useEffect(
    () => {
      async function fetchData() {
        const client = makeApiserverClient(
          process.env.REACT_APP_JAVA_API_SERVER_BASE_URL,
          {},
        );
        try {
          const response = await client.get("/auth/whoami");
          if (response.status !== 200) {
            if (response.status !== 401) {
              // TODO https://stackoverflow.com/questions/48743474/how-to-detect-a-401-with-axios-and-stop-the-console-error
              // don't log 401 since that just means unauthenticated
              console.log(response);
            }
            setUser(null);
          }
          setUser(response.data);
        } catch (error) {
          console.log(error);
          setUser(null);
        }
      }

      fetchData();
    },
    [], // only fetch on load
  );

  const signUpGuestDialog = <SignUpGuestDialog
    markets={signUpMarkets}
    market={signUpMarket}
    setMarket={setSignUpMarket}
    t={t}
    onClose={handleSignUpGuestClose}
    open={signUpGuestOpen}
    locale={i18n.language}
  />;

  const searchPage = <SearchPage
    user={user}
    setUser={setUser}
    t={t}
    i18n={i18n}
    signUpGuestDialog={signUpGuestDialog}
    onSignUpGuestOpen={handleSignUpGuestOpen}
  />;
  const placePage = <PlacePage
    user={user}
    setUser={setUser}
    t={t}
    i18n={i18n}
    signUpGuestDialog={signUpGuestDialog}
    onSignUpGuestOpen={handleSignUpGuestOpen}
  />;
  const helpPage = <HelpPage
    user={user}
    setUser={setUser}
    t={t}
    i18n={i18n}
    signUpGuestDialog={signUpGuestDialog}
    onSignUpGuestOpen={handleSignUpGuestOpen}
  />;
  const homePage = <HomePage
    user={user}
    setUser={setUser}
    t={t}
    i18n={i18n}
    signUpGuestDialog={signUpGuestDialog}
    onSignUpGuestOpen={handleSignUpGuestOpen}
  />;
  const registerHostPage = <RegisterHostPage
    user={user}
    setUser={setUser}
    signUpGuestDialog={signUpGuestDialog}
    onSignUpGuestOpen={handleSignUpGuestOpen}
  />;

  const router = createBrowserRouter(
    createRoutesFromElements(
      // Render 2 routes for the optional market parameter.
      // https://stackoverflow.com/questions/70005601/alternate-way-for-optional-parameters-in-v6
      <>
        <Route
          exact
          path={HOME_PATH}
          element={homePage}
          errorElement={<RouteRenderingError/>}
        />
        <Route path={ROUTER_PATH}>
          <Route index element={searchPage}/>
          <Route path=":market" element={searchPage}/>
        </Route>,
        <Route
          path={`${DESTINATION_PATH}/:placeSlug`}
          element={placePage}
          errorElement={<RouteRenderingError/>}
        />,
        <Route
          // '*' allows us to match everything following the slash:
          // https://stackoverflow.com/questions/75979407/react-router-param-with-token-having-forward-slash/75979963#75979963
          path={`${HELP_PATH}/*`}
          element={helpPage}
          errorElement={<RouteRenderingError/>}
        />,
        <Route
          exact
          path={REGISTER_HOST_PATH}
          element={registerHostPage}
          errorElement={<RouteRenderingError/>}
        />,
      </>,
      // <Route
      //   path="/"
      //   element={<Root />}
      //   loader={rootLoader}
      //   action={rootAction}
      //   errorElement={<ErrorPage />}
      // >
      //   <Route errorElement={<ErrorPage />}>
      //     <Route index element={<Index />} />
      //     <Route
      //       path="contacts/:contactId"
      //       element={<Contact />}
      //       loader={contactLoader}
      //       action={contactAction}
      //     />
      //     <Route
      //       path="contacts/:contactId/edit"
      //       element={<EditContact />}
      //       loader={contactLoader}
      //       action={editAction}
      //     />
      //     <Route
      //       path="contacts/:contactId/destroy"
      //       action={destroyAction}
      //     />
      //   </Route>
      // </Route>
    ),
  );

// back button:
// https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router/45373907
  // TODO can Suspense be removed? what does fallback do?
  return (
    <ViewportProvider>
      <Suspense fallback="loading">
        <div>
          <RouterProvider router={router}/>
        </div>
      </Suspense>
    </ViewportProvider>
  );
}

export default App;
