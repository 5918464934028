import React from "react";
import { makeFullUrl, makeUrlWithParams } from "../homestaysearch/url.js";
import { Helmet } from "react-helmet";
import { SUPPORTED_LANGUAGES } from "../i18n";
import { makeUrlFromObj } from "../common/url";

// Transform the market ID into a localized name, including the country if it
// can be resolved.
export function makeMarketUiName(market, markets, t): string {
  const matchingCountry = Object.entries(markets).filter(
    (
      [country: string, markets: string[]],
    ) => markets.filter(m => m === market).length > 0,
  );
  if (matchingCountry.length === 0) {
    return t(`markets:${market}`);
  }
  const country = matchingCountry[0][0];
  return `${(t(`markets:${market}`))} · ${(t(`countries:${country}`))}`;
}

export function makeTitleFromError(error, t): string {
  const { response } = error;
  if (!response) {
    // Network error such as invalid SSL cert will not contain a response.
    const { message } = error;
    return `Request failed: ${message}`;
  }
  const { status } = response;
  if (status === 404) {
    return `404 ${t("PageNotFound")}`;
  }
  const { statusText, data = {} } = response;
  const { message } = data;
  return `${status} ${message || statusText}`;
}

// Add tags inside html HEAD tag.
export default function DocumentHead(
  {
    title,
    description, // content of <meta name=description> tag
    canonical, // object with path and params
    placeName,
    params: { language, lng }, // some pages pass in 'lng'
    t,
  },
) {
  const canonicalUrl = makeUrlFromObj(canonical);
  // http://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.12
  // The Content-Language entity-header field describes the natural language(s)
  // of the intended audience for the enclosed entity. Note that this might not
  // be equivalent to all the languages used within the entity-body.
  const lngResolved = language || lng;
  return <Helmet>
    <html lang={lngResolved}/>
    <meta name="locale" content={lngResolved}/>
    <meta httpEquiv="content-language" content={lngResolved}/>
    <title>{title}</title>
    <link rel="canonical" href={canonicalUrl}/>
    {
      SUPPORTED_LANGUAGES.map(
        language => <link
          key={`alt-${language}`}
          rel="alternate"
          type="text/html"
          href={
            makeUrlFromObj(
              {
                path: canonical.path,
                params: { ...canonical.params, lng: language },
              },
            )
          }
          hrefLang={language}
        />,
      )
    }
    <link
      rel="alternate"
      type="text/html"
      href={
        makeUrlFromObj(
          {
            path: canonical.path,
            params: { ...canonical.params, lng: null },
          },
        )
      }
      hrefLang="x-default"
    />
    <meta name="description" content={description}/>
    <meta
      name="keywords"
      content={t("Html.SearchMetaKeywords", placeName)}
    />
    <meta name="application-name" content={t("HomestayBay")}/>
    <meta name="apple-mobile-web-app-title" content={t("HomestayBay")}/>
    <meta property="og:site_name" content={t("HomestayBay")}/>
    <meta property="og:locale" content={lngResolved}/>
    <meta property="og:url" content={canonicalUrl}/>
    <meta property="og:title" content={title}/>
    <meta property="og:description" content={description}/>
    <meta property="og:type" content="website"/>
  </Helmet>;
}
