import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PlaceLink from "./PlaceLink";

const styles = theme => ({
  box: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    borderRadius: "5px",
    padding: "10px 12px",
    "&:hover": {
      backgroundColor: "#eee",
      textDecoration: "none",
    },
    "& *": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& h5": {
      color: theme.palette.primary,
      fontSize: "1rem",
      fontWeight: 300,
    },
    "& p": {
      color: theme.palette.secondary,
      fontSize: "0.9rem",
      fontWeight: 300,
      flexShrink: 1,
    },
  },
  showMore: {
    marginTop: "10px",
  },
});

// TODO clipCount should be an object specifying clip sizes to mui breakpoints
// https://stackoverflow.com/questions/72826309/get-current-material-ui-breakpoint-name
// https://mui.com/material-ui/react-use-media-query/
// https://mui.com/material-ui/customization/breakpoints/#main-content
function LinkPile(
  {
    items,
    clipCount = null,
    makeSecondaryText = null,
    language,
    currency,
    setPlaceSlug,
    t,
    classes,
  },
) {
  // makeSecondaryText is a `Function`
  const showMoreEnabled = clipCount && items.length > clipCount;
  const [showMore, setShowMore] = useState(showMoreEnabled);
  let count = items.length;
  if (showMore) {
    count = clipCount;
  }

  return <Grid
    container spacing={0}
    direction="row"
    justifycontent="space-evenly"
    alignItems="center"
  >
    {
      items.slice(0, count).map(
        item => <Grid
          item xs={6} sm={4} md={3} lg={2}
          key={`placelink-${item.slug}`}
        >
          <PlaceLink
            slug={item.slug}
            urlParams={{ lng: language, ccy: currency }}
            setPlaceSlug={setPlaceSlug}
            className={classes.box}
          >
            <Box component="span">
              <Typography variant="h5" color="textPrimary">
                {item.name}
              </Typography>
              {
                makeSecondaryText && <Typography variant="body1" color="textSecondary">
                  {makeSecondaryText(item)}
                </Typography>
              }
            </Box>
          </PlaceLink>
        </Grid>,
      )
    }
    {
      showMore && <Grid item xs={12}>
        <Box width="100%"/>
        <Button
          variant="outlined"
          onClick={() => setShowMore(false)}
          className={classes.showMore}
        >{t("ShowMoreItems")}</Button>
      </Grid>
    }
  </Grid>;
}

export default withStyles(styles)(LinkPile);
