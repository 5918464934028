import React from "react";
import { Grid } from "@material-ui/core";
import Insight from "../../component/Insight";

export function Benefits({benefits, classes, t}) {
  return <section>
    <h2 className={classes.h2}>{t("starthosting:benefits.h1")}</h2>
    <p>{t("starthosting:benefits.h2")}</p>
    <Grid
      container
      spacing={3}
      direction="row"
      justifycontent="space-evenly"
      alignItems="flex-start"
      className={classes.hostSignUpGrid}
    >
      {
        benefits.map(
          (item, i: number) => {
            return <Grid
              item xs={12} sm={12} md={6} lg={4}
              key={`benefit-${i}`}
            >
              <Insight item={item} noWrap={false}/>
            </Grid>;
          },
        )
      }
    </Grid>
  </section>;
}
