import React, { Component } from "react";
import { Pagination, PaginationItem, PaginationRenderItemParams } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { makeFullUrl, makeUrlParams } from "../url";
import { Link } from "react-router-dom";

export default class MatchesPagination extends Component {
  constructor(props) {
    super(props);
    this.handlePageChanged = this.handlePageChanged.bind(this);
  }

  handlePageChanged(event, page) {
    const { onStateChange, searchResult } = this.props;
    const { startIndex, pageSize } = searchResult;
    const i = pageSize * (page - 1);
    if (startIndex !== i) {
      onStateChange({ startIndex: i });
    }
  }

  render() {
    const { t, searchResult, params, market } = this.props;
    if (searchResult === null) {
      // rendering before a search has been completed
      return null;
    }
    const { count, startIndex, pageSize, countExceeded = false, fxCurrency } = searchResult;
    if (count === 0) {
      // no matches
      return null;
    }
    const pageCount = Math.ceil(count / pageSize);
    const currentPage = (startIndex / pageSize) + 1;
    const end = Math.min(startIndex + pageSize, count);
    // TODO generate an href url for each page link for search engines
    return (
      <Grid container spacing={3} alignContent="center" alignItems="center">
        <Helmet>
          {
            // link to prev page
            startIndex > 0 && <link
              rel="prev"
              href={
                makeFullUrl(
                  {...params, startIndex: startIndex - pageSize},
                  fxCurrency,
                )
              }
            />
          }
          {
            // link to next page
            currentPage < pageCount && <link
              rel="next"
              href={
                makeFullUrl(
                  {...params, startIndex: startIndex + pageSize},
                  fxCurrency,
                )
              }
            />
          }
        </Helmet>
        <Grid item xs={12} style={{ display: "flex" }}>
          {
            // omit pagination when only one page of matches
            (
              (count > pageSize) && (
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={this.handlePageChanged}
                  color="primary"
                  style={{ margin: "auto" }}
                  // Generate <a> links instead of buttons so that search
                  // engines have links to follow and don't get stuck on just
                  // the first page.
                  renderItem={(item: PaginationRenderItemParams) => (
                    <PaginationItem
                      component={Link}
                      to={
                        "?" + makeUrlParams(
                          {
                            ...params,
                            market,
                            startIndex: (item.page - 1) * pageSize,
                          },
                          fxCurrency,
                        )
                      }
                      {...item}
                    />
                  )}
                />
              )
            )
          }
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {
            t(
              countExceeded ? "XtoYofZHomestayExceededMax" : "XtoYofZHomestay",
              { count, start: startIndex + 1, end },
            )
          }
        </Grid>
      </Grid>
    );
  }
}
