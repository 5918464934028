import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  NavigateFunction, useLocation, useNavigate, useParams,
} from "react-router-dom";
import type { AuthenticationResult } from "../../api/login";
import { makeApiserverClient } from "../../api/factory";
import authenticateUser from "../../api/login";
import signOut from "../../api/logout";
import { queryService, useFetch } from "../../api/request.js";
import { resolveMarket } from "../../common/market";
import { setLngCookie } from "../../common/state";
import { encodeUrlParams, strObj } from "../../common/url";
import { MEDIA_MIN_WIDTH_600 } from "../../common/widths";
import DocumentHead, { makeTitleFromError } from "../../component/DocumentHead";
import { FetchError } from "../../component/FetchError";
import Footer from "../../component/Footer";
import Nav from "../../component/Nav.jsx";
import { useViewport } from "../../component/ViewportProvider";
import { HELP_PATH } from "../../constants/app";
import { makeUrlParams, makeUrlWithParams } from "../../homestaysearch/url";
import { LOCALES } from "../../i18n";
import { parseUrlParams } from "../url";
import { ArticleContent } from "./ArticleContent";
import { HelpContent } from "./HelpContent";
import { TopicContent } from "./TopicContent";

const useStyles = makeStyles(
  {
    // This pushes the container below the navbar.
    content: {
      position: "relative",
      width: "100%",
      paddingTop: "56px", // this must be the height of the navbar
      [MEDIA_MIN_WIDTH_600]: {
        paddingTop: "64px",
      },
      display: "inline-block",
      height: "auto",
      overflow: "visible",
    },
    error: {
      margin: "20px",
    },
  },
);

// const styles = theme => ({
//   // This pushes the container below the navbar.
//   content: {
//     position: "relative",
//     width: "100%",
//     paddingTop: "56px", // this must be the height of the navbar
//     [MEDIA_MIN_WIDTH_600]: {
//       paddingTop: "64px",
//     },
//     display: "inline-block",
//     height: "auto",
//     overflow: "visible",
//   },
//   error: {
//     margin: "20px",
//   },
// });

function makeTitle(page: string, state, error, t): string {
  if (error) {
    return makeTitleFromError(error, t);
  }

  const suffix = t("Html.HelpTitleSuffix");
  const { serverData, page: statePage } = state;
  if (page === "topic" && statePage === page) {
    const { topic } = serverData;
    const { label } = topic;
    return label.concat(suffix);
  }
  if (page === "article" && statePage === page) {
    const { article } = serverData;
    const { title } = article;
    return title.concat(suffix);
  }
  return t("Help").concat(suffix);
}

function makeDescription(page: string, state, error, t): string {
  const { serverData, page: statePage } = state;
  if (page === "article" && statePage === page) {
    const { article: { excerpt } = {} } = serverData || {};
    if (excerpt) {
      return excerpt;
    }
  }

  return t("Html.HelpDescription");
}

function parsePageParams({ "*": helpSlug }) {
  const [page, ...pagePath] = helpSlug.split("/");
  return { page, pagePath: pagePath.join("/") };
}

// Construct subpath under "/help" and omit any trailing slash.
function makeLocationPath(page: string, pagePath: string): string {
  if (page) {
    return `${HELP_PATH}/${page}/${pagePath}`;
  }
  return HELP_PATH;
}

// Construct path with query params for client location.
function makeLocationPathWithParams(
  page: string, pagePath: string, urlParams: strObj,
): string {
  const urlParamsEncoded = encodeUrlParams(urlParams);
  const path = makeLocationPath(page, pagePath);
  return [path, urlParamsEncoded].filter(Boolean).join("?");
}

export function HelpPage(
  {
    user,
    t,
    setUser,
    signUpGuestDialog,
    onSignUpGuestOpen,
    i18n,
  },
) {
  const classes = useStyles();
  const { page, pagePath } = parsePageParams(useParams());
  const location = useLocation();
  const [error, setError] = useState(null);
  const { currency: parsedCurrency, ...parsedParams } = parseUrlParams(location, i18n);
  const [currency, setCurrency] = useState(parsedCurrency);
  const [params, setParams] = useState(parsedParams); // ccy, audience
  const [state, setState] = useState({
    // /help:               main page: getting started, popular, all topics
    // /help/topic/{topic}: show topic name, children, related topics
    // /help/article/{id}:  show article
    serverData: null,
    // these three describe the context of the data
    page: null,
    pagePath: null,
    language: null,
  });
  const [[savedLocationPath, savedLocationSearch], setSavedLocation] = useState(
    [location.pathname, location.search],
  );
  if (location.pathname !== savedLocationPath || location.search !== savedLocationSearch) {
    // this fixes the state not changing when the browser's back/forward buttons are used
    setSavedLocation([location.pathname, location.search]);
  }

  const [audience, setAudience] = React.useState(params.audience || "guests");

  const handleAudienceChange = (e: Event, audience: string) => {
    setAudience(audience);
    const urlParamsEncoded = encodeUrlParams(
      { lng: language, ccy: currency, audience },
    );
    const queryParams = `?${urlParamsEncoded}`;
    setSavedLocation([location.pathname, queryParams]);
    navigate(queryParams);
    e.preventDefault();
  };

  const { height, width } = useViewport();
  const navigate: NavigateFunction = useNavigate();

  const handleStateParamsChange = (
    newParams, newState, newCurrency: ?string = null, newMarket: ?string = null,
  ) => {
    if (newCurrency) {
      setCurrency(newCurrency);
    }

    setLngCookie(newParams);
    if (newMarket) {
      const { language: newLanguage, guestCount, checkIn } = newParams;
      const { language } = parsedParams;
      const params = makeUrlParams(
        {
          checkIn,
          guestCount,
          language: newLanguage || language,
          followMap: true,
        },
        newCurrency || currency,
      );
      const url = makeUrlWithParams(newMarket, params);
      navigate(url);
    }

    setParams({ ...params, ...newParams });
  };

  // Init: query server for currencies and markets once.
  const { data: uiData } = useFetch("/ui/init?request=currencies,markets");
  // data can be null instead of undefined on network error
  const {
    values: { currencies, markets } = { currencies: [], markets: {} },
  } = uiData || {};

  // should only get called once on load, hence empty deps

  // Construct the apiserver path to query for the page data.
  const makeApiserverPath = (page: string, pagePath: string, lng: string): string => {
    const queryParams = encodeUrlParams({ lng, w: width, h: height });
    if (page === "") {
      return `${HELP_PATH}?${queryParams}`;
    }
    if (page === "topic" || page === "article") {
      return `${HELP_PATH}/${page}/${pagePath}?${queryParams}`;
    }
    // TODO redirect everything else to the main page
    return `${HELP_PATH}?${queryParams}`;
  };

  // Navigate to target path if it differs to the current path.
  function navigateMaybeToTarget() {
    const { language } = params;
    const target = makeLocationPathWithParams(
      page, pagePath, { lng: language, ccy: currency, audience },
    );
    const cur = location.pathname + location.search;
    if (cur !== target) {
      navigate(target);
    }
  }

  const setPath = (e: Event, path: string, queryParams: string) => {
    setSavedLocation([path, "?" + queryParams]);
    navigate(`${path}?${queryParams}`);
    e.preventDefault();
  };

  // Query for search results whenever the user changes a search parameter.
  useEffect(
    () => {
      navigateMaybeToTarget();
      // noinspection JSIgnoredPromiseFromCall
      // TODO conditionally fetch if location or params have changed (excluding audience)
      // TODO then merge the two effects
      fetchResults();
    },
    // only re-run effect if user changes something that requires fetching results again
    [params, page, pagePath],
  );

  // Update browser location when audience changes.
  useEffect(
    () => {
      navigateMaybeToTarget();
    },
    [audience],
  );

  const fetchResults = async () => {
    const client = makeApiserverClient(
      process.env.REACT_APP_API_SERVER_BASE_URL,
      // { Authorization: "Bearer token" }, // auth header goes here
      {},
    );
    const { language } = params;
    const { result, error } = await queryService(
      client,
      "GET",
      makeApiserverPath(page, pagePath, language),
    );
    setError(error);
    setState({ serverData: result, page, pagePath, language });
  };

  const onSignIn = async (email: string, password: string): AuthenticationResult => {
    const result = await authenticateUser(email, password);
    setUser(result.user);
    return result.result;
  };

  const onSignOut = async (): boolean => {
    const signedOut = await signOut();
    if (signedOut) {
      setUser(null);
    }
    return signedOut;
  };

  const { serverData } = state;
  const { language } = params;
  // Check if request for page data is complete and matches the requested page.
  // During a transition, serverData will exist but for the page we're
  // transitioning away from.
  const ready = (
    serverData
    && state.page === page
    && state.pagePath === pagePath
    && state.language === language
  );
  return (
    <>
      <DocumentHead
        title={makeTitle(page, state, error, t)}
        description={makeDescription(page, state, error, t)}
        canonical={
          {
            path: makeLocationPath(page, pagePath),
            // only include audience for main help page where it changes the page content
            params: { lng: language, ...(page === "" && { audience }) },
          }
        }
        params={params}
        t={t}
      />
      <Nav
        onStateChange={handleStateParamsChange}
        user={user}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
        t={t}
        saveTransKey="Search"
        i18n={i18n}
        markets={markets}
        // market/country are null so the user picks a destination
        market={null}
        countryName={null}
        locales={LOCALES}
        currency={currency}
        params={params}
        currencies={currencies}
        onSignUpGuestOpen={onSignUpGuestOpen}
      />
      {signUpGuestDialog}
      <div className={classes.content}>
        {error && <FetchError error={error} t={t} classes={classes}/>}
        {
          page === "" && ready && <HelpContent
            data={serverData}
            setPath={setPath}
            language={language}
            currency={currency}
            audience={audience}
            handleAudienceChange={handleAudienceChange}
            t={t}
          />
        }
        {
          page === "topic" && ready && <TopicContent
            data={serverData}
            setPath={setPath}
            urlParams={{ lng: language, ccy: currency, audience }}
            t={t}
          />
        }
        {
          page === "article" && ready && <ArticleContent
            data={serverData}
            setPath={setPath}
            urlParams={{ lng: language, ccy: currency, audience }}
            market={resolveMarket(user)}
            markets={markets}
            onSignUpGuestOpen={onSignUpGuestOpen}
            t={t}
            language={language}
          />
        }
      </div>
      {
        // Only render when the content itself renders. This prevents the flicker
        // effect of the footer relocating to the top and then back to the bottom
        // of the screen when the content clears very briefly while waiting for the
        // apiserver query to complete and then repopulates with new content.
        // A skeleton screen might be nicer:
        // https://www.smashingmagazine.com/2020/04/skeleton-screens-react/
        (error || ready) && <Footer
          user={user}
          placeSlug={null}
          markets={markets}
          onSignUpGuestOpen={onSignUpGuestOpen}
          t={t}
          locale={i18n.language}
        />
      }
    </>
  );
}
