import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  makeAboutLink,
  makeContactLink,
  makeGuestListLink,
  makeHelpAudience,
  makeHomeLink,
  makePrivacyLink,
  makeTermsLink,
} from "../common/links";
import { resolveMarket } from "../common/market";
import { encodeUrlParams } from "../common/url";
import { HELP_PATH, REGISTER_HOST_PATH, ROUTER_PATH } from "../constants/app";
import { popularDestinations } from "../constants/markets";
import InternalLink from "./InternalLink";

const useStyles = makeStyles((theme: Theme) => createStyles({
  footer: {
    flexGrow: 1,
    borderTop: "1px solid #E1E1E1",
    backgroundColor: "#f1f1f1",
    display: "inline-block",
    position: "static",
    height: "auto",
    overflow: "visible",
    clear: "left",
    padding: theme.spacing(2),
    fontSize: "90%",
    width: "100%",
  },
  container: {
    maxWidth: "980px",
    // stickybox has z-index putting it on top and a bottom:0. The parent has
    // position:static which means it sits behind the z-index therefore the
    // footer is behind a div and nothing is clickable. position:relative here
    // fixes this.
    position: "relative",
  },
  column: {
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    "& h5": {
      fontSize: "18px",
      fontWeight: "normal",
      borderBottom: "1px solid #ccc",
      margin: "0 0 10px 0",
      color: "#333",
    },
  },
  link: {
    maxWidth: "270px",
  },
  copyright: {
    paddingTop: theme.spacing(2),
  },
}));

function CityCountryLink({ legacySlug, countryCode, setPath, lng, t }) {
  return <InternalLink
    path={`${ROUTER_PATH}/${legacySlug}`}
    urlParams={{ lng }}
    setPath={setPath}
  >
    {
      // CityCountry defines the order to display the city/country in that is
      // natural for the language.
      t(
        "CityCountry",
        {
          city: t(`markets:${legacySlug}`),
          country: t(`countries:${countryCode}`),
        },
      )
    }
  </InternalLink>;
}

// Country is the 2-char ISO code, uppercased.
export default function Footer(
  { user, placeSlug, markets, setPath, onSignUpGuestOpen, t, locale },
) {
  const classes = useStyles();
  const market = resolveMarket(user);

  const handleSignUpGuestOpen = (e: Event) => {
    onSignUpGuestOpen(e, resolveMarket(user, placeSlug), markets);
  };

  return <footer className={classes.footer}>
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className={classes.column}>
            <h5>{t("Homestays")}</h5>
            <Grid container spacing={1} xs={12}>
              <Grid item xs={12} className={classes.link}>
                <a href={makeHomeLink(locale)}>{t("FindAHomestay")}</a>
              </Grid>
              <Grid item xs={12} className={classes.link}>
                <a
                  target="_blank"
                  href={makeGuestListLink(market, locale)}
                >{t("FindAGuest")}</a>
              </Grid>
              <Grid item xs={12} className={classes.link}>
                <RouterLink
                  to={{
                    pathname: REGISTER_HOST_PATH,
                    search: encodeUrlParams({ lng: locale }),
                  }}
                >{t("BecomeAHost")}</RouterLink>
              </Grid>
              <Grid item xs={12} className={classes.link}>
                <a
                  href="#"
                  onClick={handleSignUpGuestOpen}
                >{t("CreateAGuestProfile")}</a>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.column}>
            <h5>{t("HelpAndSupport")}</h5>
            <Grid container spacing={1} xs={12}>
              <Grid item xs={12} className={classes.link}>
                <RouterLink
                  to={{
                    pathname: HELP_PATH,
                    search: encodeUrlParams(
                      { lng: locale, audience: makeHelpAudience(user) },
                    ),
                  }}
                >{t("Help")}</RouterLink>
              </Grid>
              <Grid item xs={12} className={classes.link}>
                <a
                  target="_blank"
                  href={makeAboutLink(locale)}
                >{t("AboutUs")}</a>
              </Grid>
              <Grid item xs={12} className={classes.link}>
                <a
                  target="_blank"
                  href={makeContactLink(market, locale)}
                >{t("ContactUs")}</a>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.column}>
            <h5>{t("PopularDestinations")}</h5>
            <Grid container spacing={1} xs={12}>
              {
                popularDestinations.map(
                  (destination: [string, string, string]) => (
                    // destination: marketId, legacySlug, countryCode
                    <Grid item xs={12} className={classes.link} key={`popdest-${destination[0]}`}>
                      <CityCountryLink
                        legacySlug={destination[1]}
                        countryCode={destination[2]}
                        setPath={setPath}
                        lng={locale}
                        t={t}
                      />
                    </Grid>
                  ),
                )
              }
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div className={classes.copyright}>
        © 2010-{new Date().getFullYear()} HomestayBay. · <a
        target="_blank"
        href={makePrivacyLink(locale)}
      >{t("PrivacyPolicy")}</a> · <a
        target="_blank"
        href={makeTermsLink(locale)}
      >{t("TermsOfUse")}</a>
      </div>
    </Container>
  </footer>;
}
