import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import type { AuthenticationResult } from "../../api/login";
import authenticateUser from "../../api/login";
import signOut from "../../api/logout";
import { useFetch } from "../../api/request.js";
import { readMarketCookie } from "../../common/cookie";
import { MEDIA_MIN_WIDTH_600 } from "../../common/widths";
import DocumentHead from "../../component/DocumentHead";
import Footer from "../../component/Footer";
import Nav from "../../component/Nav.jsx";
import { REGISTER_HOST_PATH } from "../../constants/app";
import { LOCALES } from "../../i18n";
import { parseUrlParams } from "../url";
import { Content } from "./Content";
import { encodeUrlParams, strObj } from "../../common/url";
import { setLngCookie } from "../../common/state";
import { makeUrlParams, makeUrlWithParams } from "../../homestaysearch/url";


const useStyles = makeStyles(
  {
    // This pushes the container below the navbar.
    content: {
      position: "relative",
      width: "100%",
      paddingTop: "56px", // this must be the height of the navbar
      [MEDIA_MIN_WIDTH_600]: {
        paddingTop: "64px",
      },
      display: "inline-block",
      height: "auto",
      overflow: "visible",
    },
    error: {
      margin: "20px",
    },
  },
);

function makeTitle(t): string {
  return t("starthosting:title");
}

// Construct path with query params for client location.
function makeLocationPathWithParams(urlParams: strObj): string {
  return [REGISTER_HOST_PATH, encodeUrlParams(urlParams)].filter(Boolean).join("?");
}

// Take market specified by 'mkt' URL param when specified.
// Otherwise, check for the 'mkt' cookie and set from this.
function identifyDefaultMarket(mktParam: string | null): string | null {
  return mktParam || readMarketCookie();
}

export function RegisterHostPage(
  {
    user,
    setUser,
    signUpGuestDialog,
    onSignUpGuestOpen,
  },
) {
  const classes = useStyles();
  const { t, i18n } = useTranslation(
    ["common", "markets", "countries", "languages", "starthosting"],
  );

  const location = useLocation();
  // This gets the 'lng' query param into parsedParams.
  const { currency: parsedCurrency, mkt, ...parsedParams } = parseUrlParams(location, i18n);
  const [currency, setCurrency] = useState(parsedCurrency);
  const [params, setParams] = useState(parsedParams); // ccy, lng
  const [[savedLocationPath, savedLocationSearch], setSavedLocation] = useState(
    [location.pathname, location.search],
  );
  if (location.pathname !== savedLocationPath || location.search !== savedLocationSearch) {
    // this fixes the state not changing when the browser's back/forward buttons are used
    setSavedLocation([location.pathname, location.search]);
  }
  const navigate: NavigateFunction = useNavigate();

  // Init: query server for currencies and markets once.
  const { data: uiData } = useFetch("/ui/init?request=currencies,markets");
  // data can be null instead of undefined on network error
  const {
    values: { currencies, markets } = { currencies: [], markets: {} },
  } = uiData || {};

  const handleStateParamsChange = (
    newParams, newState, newCurrency: ?string = null, newMarket: ?string = null,
  ) => {
    if (newCurrency) {
      setCurrency(newCurrency);
    }

    setLngCookie(newParams);

    if (newMarket) {
      const { lng } = parsedParams;
      const { language: newLanguage, guestCount, checkIn } = newParams;
      const params = makeUrlParams(
        {
          checkIn,
          guestCount,
          language: newLanguage || lng,
          ccy: newCurrency || currency,
          mkt,
          followMap: true,
        },
      );
      const url = makeUrlWithParams(newMarket, params);
      navigate(url);
    }

    setParams({ ...params, ...newParams });
  };

  // Navigate to target path if it differs to the current path.
  function navigateMaybeToTarget() {
    const { lng, language } = params;
    const target = makeLocationPathWithParams({ lng: language || lng, ccy: currency, mkt });
    const cur = location.pathname + location.search;
    if (cur !== target) {
      navigate(target);
    }
  }

  // Update location whenever the user changes a search parameter.
  useEffect(
    () => navigateMaybeToTarget(),
    // only re-run effect if params change
    [params],
  );

  const onSignIn = async (email: string, password: string): AuthenticationResult => {
    const result = await authenticateUser(email, password);
    setUser(result.user);
    return result.result;
  };

  const onSignOut = async (): boolean => {
    const signedOut = await signOut();
    if (signedOut) {
      setUser(null);
    }
    return signedOut;
  };

  return (
    <>
      <DocumentHead
        title={makeTitle(t)}
        description={t("Html.HostDescription")}
        canonical={{ path: `${REGISTER_HOST_PATH}`, params: { lng: params.lng } }}
        params={params}
        t={t}
      />
      <Nav
        onStateChange={handleStateParamsChange}
        user={user}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
        t={t}
        saveTransKey="Search"
        i18n={i18n}
        markets={markets}
        // market/country are null so the user picks a destination
        market={null}
        countryName={null}
        locales={LOCALES}
        currency={currency}
        params={params}
        currencies={currencies}
        onSignUpGuestOpen={onSignUpGuestOpen}
      />
      {signUpGuestDialog}
      <div className={classes.content}>
        <Content
          lng={i18n.language}
          defaultSelectedMarket={identifyDefaultMarket(mkt)}
          markets={markets}
          t={t}
        />
      </div>
      {
        // Only render when the content itself renders. This prevents the flicker
        // effect of the footer relocating to the top and then back to the bottom
        // of the screen when the content clears very briefly while waiting for the
        // apiserver query to complete and then repopulates with new content.
        // A skeleton screen might be nicer:
        // https://www.smashingmagazine.com/2020/04/skeleton-screens-react/
        <Footer
          user={user}
          placeSlug={null}
          markets={markets}
          onSignUpGuestOpen={onSignUpGuestOpen}
          t={t}
          locale={i18n.language}
        />
      }
    </>
  );
}
