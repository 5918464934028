import React from "react";
import { CityPile } from "../../destination/component/CityPile";

export default function TrendingDestinations(
  { countryName, trendingDestinations, urlParams, classes, t },
) {
  return <>
    <h2>{t("homepage.trendingDestH")}</h2>
    {
      countryName && <h3 className={classes.h3}>
        {t("homepage.trendingDestP", { country: countryName })}
      </h3>
    }
    <CityPile
      cities={trendingDestinations.slice(0, 5)}
      urlParams={urlParams}
    />
  </>;
}
