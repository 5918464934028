import { marshalDate } from "../common/date";
import { makeApiRequestGeoBounds } from "./geobounds";
import { mapLocale } from "../common/links";

// Geobounds are set in the params or otherwise a default is given.
// marketChanged controls whether geobounds are included (changing the market clears the geobounds).
export function makeSearchHomestaysRequestData(
  marketChanged: boolean,
  market,
  {
    language,
    checkIn,
    guestCount,
    meals,
    priceMin,
    priceMax,
    noPets,
    laundry,
    smoking,
    startIndex,
    geoBounds,
    followMap,
  },
  currency: string | null,
  searchResultGeoBounds,
  { height, width }, // viewport
) {
  const data = {
    Language: mapLocale(language),
    Start: startIndex,
    //Limit: 20,
    Market: market,
    NoPetsInHome: noPets,
    LaundryAvailable: laundry,
    PrivateBathroom: false,
    UserAgent: {
      Height: height,
      Width: width,
    },
  };
  if (!marketChanged) {
    // When the market changes, don't send geobounds that would restrict to
    // whatever previous market the user was looking at.
    const requestGeoBounds = makeApiRequestGeoBounds(
      geoBounds,
      searchResultGeoBounds,
      undefined,
      followMap,
    );
    if (requestGeoBounds !== undefined) {
      data["GeoBounds"] = requestGeoBounds;
    }
  }
  if (currency) {
    data["Currency"] = currency;
  }
  if (checkIn !== null && guestCount > 0) {
    data["Price"] = {
      Period: "MONTH",
      CheckIn: marshalDate(checkIn),
      Guests: guestCount,
    };
    if (priceMin > 0) {
      data["Price"]["Min"] = priceMin;
    }
    if (priceMax > 0) {
      data["Price"]["Max"] = priceMax;
    }
  }
  if (meals.length > 0) {
    data["Meals"] = Object.fromEntries(meals.map(meal => [meal, true]));
  }
  if (smoking === "non_smoking" || smoking === "smoking") {
    data["SmokingAllowed"] = smoking === "smoking";
  }
  return data;
}
