import React from "react";

export function homestayWhere({ city, nbrhood }, t): string {
  if (nbrhood) {
    return t("homestay.WhereWithNeighborhood", { city, nbrhood });
  }
  return t("homestay.Where", { city });
}

export function makeHomestayAttrT(attrs, label: string, t): string {
  return t(`homestay.attr.${label}`, attrs[label]);
}

export function makeAttrsLine({ labels, attrs, t }) {
  return labels
    .filter(label => attrs.hasOwnProperty(label))
    .map((label: string, i: number) => <span key={i}>{makeHomestayAttrT(attrs, label, t)}</span>)
    .reduce(
      (acc, attr, i: number) => acc === null ? [attr] : [
        acc,
        <span key={`sep-${i}`} aria-hidden="true"> · </span>, // TODO nanoid https://github.com/ai/nanoid/
        attr,
      ],
      null,
    );
}

export function AttrsLine(props) {
  const spans = makeAttrsLine(props);
  if (spans.length > 0) {
    return <>{spans}</>;
  }
  return null;
}
