import {
  MEDIA_MIN_WIDTH_1128,
  MEDIA_MIN_WIDTH_1256,
  MEDIA_MIN_WIDTH_768,
  MEDIA_MIN_WIDTH_960,
} from "../common/widths";

export const JUMBOTRON = (height: string, smallHeight: string) => (
  {
    // display + flex-flow result in centering of the h1/h2 tags in the div
    // https://stackoverflow.com/questions/52104825/center-both-h1-and-h2-horizontally-and-vertically
    display: "flex",
    position: "relative",
    overflow: "hidden",
    flexFlow: "column",
    justifyContent: "center",
    marginBottom: "20px",
    alignItems: "center",
    height: smallHeight,
    [MEDIA_MIN_WIDTH_960]: {
      height: height,
    },
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#888",
  }
);
export const HERO_IMAGE_CONTAINER = {
  width: "100%",
  height: "100%",
  position: "absolute",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
};
export const HERO_IMAGE = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};
export const HERO_IMAGE_SCREEN = {
  backgroundColor: "rgba(0, 0, 0, 0.15)",
  width: "100%",
  height: "100%",
  position: "absolute",
};
export const JUMBOTRON_H1_H2 = {
  color: "#FFF",
  marginTop: 0,
  marginBottom: "16px",
  textShadow: "0 0px 7px rgb(0 0 0)",
  textOverflow: "ellipsis",
};
export const H1 = {
  lineHeight: "1.25em",
  fontWeight: "700",
  fontSize: "1.5em",
  [MEDIA_MIN_WIDTH_768]: {
    fontSize: "2em",
  },
  [MEDIA_MIN_WIDTH_1128]: {
    fontSize: "3.1em",
  },
};
export const H2 = {
  lineHeight: "1.5em",
  fontSize: "1em",
  [MEDIA_MIN_WIDTH_768]: {
    fontWeight: "700",
    fontSize: "1.125em",
  },
  [MEDIA_MIN_WIDTH_1128]: {
    fontSize: "1.4em",
  },
};
export const MAIN_H3 = {
  fontSize: "1rem",
  [MEDIA_MIN_WIDTH_768]: {
    fontSize: "1.1rem",
  },
};
export const H3 = {
  ...MAIN_H3,
  lineHeight: "1.625rem",
  marginBottom: "14px",
  fontWeight: 700,
};
export const JUMBOTRON_CONTENT_1 = {
  position: "absolute",
  paddingBottom: "60px",
  margin: "0 5px",
  textAlign: "center",
  textWrap: "balance", // line lengths are more balanced and visually nicer
  [MEDIA_MIN_WIDTH_960]: {
    paddingBottom: "90px",
  },
};
export const JUMBOTRON_CONTENT = {
  ...JUMBOTRON_CONTENT_1,
  "& h1, h2": JUMBOTRON_H1_H2,
  "& h1": H1,
  "& h2": H2,
};
export const MAIN_H1 = {
  // this is added for help pages
  margin: "2rem 0",
  fontWeight: 600,
  fontSize: "1.5rem",
  [MEDIA_MIN_WIDTH_768]: {
    fontSize: "1.8rem",
  },
  [MEDIA_MIN_WIDTH_1128]: {
    fontSize: "2rem",
  },
};
export const MAIN_H2 = (h2Bottom: string = "1.5rem") => (
  {
    margin: `2.5rem 0 ${h2Bottom} 0`,
    fontSize: "1.15rem",
    fontWeight: 600,
    color: "#292929",
    [MEDIA_MIN_WIDTH_768]: {
      fontSize: "1.5rem",
    },
  }
);
export const MAIN = (h2Bottom: string = "1.5rem") => (
  {
    padding: "0 0 50px 0",
    position: "relative",
    margin: "0 10px",
    [MEDIA_MIN_WIDTH_960]: {
      margin: "0 64px",
    },
    [MEDIA_MIN_WIDTH_1256]: {
      margin: "0 calc(8.3333% + 16px)",
    },
    "& h1": MAIN_H1,
    "& h2": MAIN_H2(h2Bottom),
  }
);
