import React from "react";
import PropTypes from "prop-types";
import addDays from "date-fns/addDays";
import enLocale from "date-fns/locale/en-US";
import koLocale from "date-fns/locale/ko";
import jaLocale from "date-fns/locale/ja";
import esLocale from "date-fns/locale/es";
import zhLocale from "date-fns/locale/zh-CN";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const localeMap = {
  en: enLocale,
  es: esLocale,
  ja: jaLocale,
  ko: koLocale,
  zh: zhLocale,
};

const propTypes = {
  style: PropTypes.object.isRequired,
  autofocus: PropTypes.bool.isRequired,
  checkIn: PropTypes.instanceOf(Date),
  locale: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  t: PropTypes.func.isRequired,
};

function CheckInSelection(
  { style, autofocus, checkIn, minDate, locale, onCheckInChange, t },
) {
  const handleDateChange = date => {
    onCheckInChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <DatePicker
        allowKeyboardControl={true}
        autoFocus={autofocus}
        // undefined value will populate with now instead of require user to select a date
        value={checkIn || null}
        onChange={handleDateChange}
        variant="inline"
        inputVariant="outlined"
        format={makeFormat(locale)}
        label={t("CheckIn")}
        autoOk={true}
        minDate={minDate}
        maxDate={addDays(minDate, 365)}
        style={style}
      />
    </MuiPickersUtilsProvider>
  );
}

function makeFormat(locale): string {
  switch (locale) {
    case "ko":
      return "yyyy년 M월 d일";
    case "ja":
      return "yyyy年M月d日";
    case "zh":
      return "yyyy年M月d日";
    default:
      return "d MMM yyyy";
  }
}

CheckInSelection.propTypes = propTypes;

export default CheckInSelection;
