import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import CircleIcon from "./CircleIcon";

const useStyles = makeStyles({
  // This pushes the container below the navbar.
  box: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    alignItems: "flex-start",
  },
  icon: {
    marginRight: "10px",
  },
  content: {
    display: "grid",
    flex: 1,
    // "& *": {
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   whiteSpace: "nowrap",
    // },
    "& h5": {
      // color: theme.palette.primary,
      color: "#212529",
      fontSize: "1rem",
      fontWeight: 500,
    },
    "& p": {
      // color: theme.palette.secondary,
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: "0.9rem",
      fontWeight: 300,
      flexShrink: 1,
    },
  },
});

// An icon in a grey circle with a bold title and grey detail text next to it.
export default function Insight({ item, noWrap = true }) {
  const classes = useStyles();
  const { icon, title, value } = item;
  return <Box component="span" className={classes.box}>
    <div className={classes.icon}>
      <CircleIcon icon={icon}/>
    </div>
    <div className={classes.content}>
      <Typography noWrap variant="h5">{title}</Typography>
      <Typography noWrap={noWrap} variant="body1" color="textSecondary">
        {value}
      </Typography>
    </div>
  </Box>;
}
