import React from "react";
import { Divider, Tab, Tabs } from "@material-ui/core";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import HotelIcon from "@material-ui/icons/Hotel";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

// const photoId = "1533073526757-2c8ca1df9f1c"; // sign silhouette
// // const photoId = "1633889222252-a79bdc892e5c"; // compass close up
// // const photoId = "1597945161640-9366e6d4253b"; // compass on canvas
// // const photoId = "1505778276668-26b3ff7af103"; // compass in forest
// // const photoId = "1603623898218-0cb7f493309b"; // compass on map
// const photo1 = {
//   "urls": makeUnsplashUrls(
//     photoId, "focalpoint", [
//       { height: 150, width: 480 },
//       { height: 250, width: 1280 },
//       { height: 250, width: 1440 },
//       { height: 250, width: 1920 },
//     ],
//   ),
//   "rgb": "#f3f3f3",
// };

// function makeUnsplashUrls(
//   id: string, crop: string, dimensions: { height: number, width: number }[],
// ): { width: number, url: string }[] {
//   return dimensions.map(
//     dimension => {
//       return {
//         height: dimension.height,
//         url: `https://images.unsplash.com/photo-${id}?fm=jpg&q=60&w=${dimension.width}&h=${dimension.height}&cs=tinysrgb&fit=crop&crop=${crop}&ixid=MnwzNzg0NzV8MHwxfGFsbHx8fHx8fHx8fDE2NzU0NjY5NTQ&ixlib=rb-4.0.3`,
//       };
//     },
//   );
// }

// export default function Jumbotron(
//   { photo, classes, t, children },
// ) {
//   // photo: urls, rgb
//   return <div className={classes.jumbotron}>
//     <div className={classes.heroImageContainer}>
//       <HeroImage photo={photo} alt={t("Help")} classes={classes}/>
//     </div>
//     <div className={classes.heroImageScreen}/>
//     <div className={classes.jumbotronContent}>
//       <h1>{t("HowCanWeHelp")}</h1>
//     </div>
//     {children}
//   </div>;
// }

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      // color: "#fff", // text color
      // opacity: "0.7",
      // fontWeight: theme.typography.fontWeightBold,
      // fontSize: theme.typography.pxToRem(15),
      // marginRight: theme.spacing(1),
      // "&:focus": {
      //   opacity: 1,
      // },
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    // justifyContent: "center",
    // backgroundColor: "transparent",
    // "& > span": {
    //   maxWidth: 40,
    //   width: "100%",
    //   backgroundColor: "#00ff00",
    // },
  },
})(
  (props: StyledTabsProps) => <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span/>,
      // style sets the bar for the selected tab
      style: { backgroundColor: "#6ac82c", height: "6px" },
      // style: { backgroundColor: "#fff", borderBottom: "1px solid #ddd", height: "6px" },
    }}
  />,
);

export default function HelpJumbotron(
  { audience, onAudienceChange, hostsLabel, guestsLabel, classes, t },
) {
  return <>
    {/*<Jumbotron photo={photo1} classes={classes} t={t}>*/}
    {/*  <StyledTabs*/}
    {/*    value={currentTabIndex}*/}
    {/*    onChange={handleTabChange}*/}
    {/*    aria-label="audience selection"*/}
    {/*    class={classes.tabs}*/}
    {/*    centered*/}
    {/*  >*/}
    {/*    <StyledTab icon={<CardTravelIcon/>} value={0} label="Guests"/>*/}
    {/*    <StyledTab icon={<HotelIcon/>} value={1} label="Hosts"/>*/}
    {/*  </StyledTabs>*/}
    {/*</Jumbotron>*/}
    <h1 className={classes.h1}>{t("HowCanWeHelp")}</h1>
    <StyledTabs
      value={audience}
      onChange={onAudienceChange}
      aria-label="audience selection"
      centered
    >
      <StyledTab icon={<CardTravelIcon/>} value={"guests"} label={guestsLabel}/>
      <StyledTab icon={<HotelIcon/>} value={"hosts"} label={hostsLabel}/>
    </StyledTabs>
    <Divider/>
  </>;
}
