import React from "react";
import LinkPile from "./LinkPile";

export default function OtherNeighborhoods(
  { localities, language, currency, setPlaceSlug, t },
) {
  return <LinkPile
    items={localities}
    clipCount={18}
    makeSecondaryText={item => t("NHomestay", { count: item.count })}
    language={language}
    currency={currency}
    setPlaceSlug={setPlaceSlug}
    t={t}
  />;
}
