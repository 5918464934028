import { marshalDate, parseDate } from "../common/date";
import { GeoBounds, parseLatLon } from "../common/geo";
import { parseIntWithDefault } from "../common/number";
import { ROUTER_PATH } from "../constants/app";

type SearchOptions = {
  market?: string,
  checkIn?: Date,
  guestCount: number,  // int: 0 for no selection
  meals: string[],
  noPets: boolean,
  laundry: boolean,
  smoking: string,
  priceMin: number,
  priceMax: number,
  currency?: string,
  startIndex: number,
  language: string,
  geoBounds?: GeoBounds,
  followMap: boolean,
}

export function parseUrlParams({ search }, i18n): SearchOptions {
  const params = new URLSearchParams(search);
  const { language = "en" } = i18n; // fall back to what i18n detected
  let paramsLanguage = params.get("lng");
  const selectedLanguage = paramsLanguage || language;
  const parsedParams = {
    checkIn: parseDate(params.get("checkIn")),
    guestCount: parseIntWithDefault(params.get("guestCount"), 0),
    meals: params.getAll("meals[]"),
    noPets: params.get("noPets") === "1",
    laundry: params.get("laundry") === "1",
    smoking: params.get("smoking") || "no_preference",
    priceMin: Number(params.get("priceMin")),
    priceMax: Number(params.get("priceMax")),
    currency: params.get("ccy"),
    startIndex: Number(params.get("startIndex")),
    language: selectedLanguage,
    followMap: params.get("followMap") !== "0",  // default true
  };
  const nwLatLon = parseLatLon(params.get("nwLat"), params.get("nwLon"));
  const seLatLon = parseLatLon(params.get("seLat"), params.get("seLon"));
  if (nwLatLon !== null && seLatLon !== null) {
    parsedParams["geoBounds"] = { NorthWest: nwLatLon, SouthEast: seLatLon };
  }
  return parsedParams;
}

function addGeoBoundsParams(searchParams, geoBounds) {
  searchParams.set("nwLat", geoBounds.NorthWest.lat);
  searchParams.set("nwLon", geoBounds.NorthWest.lon);
  searchParams.set("seLat", geoBounds.SouthEast.lat);
  searchParams.set("seLon", geoBounds.SouthEast.lon);
}

export function makeFullUrl({ market, ...params }, currency): string {
  return makeUrlWithParams(
    market,
    makeUrlParams({ market, ...params }, currency, null),
  );
}

export function makeUrlWithParams(market: string, urlParams?: string): string {
  if (urlParams) {
    return `${ROUTER_PATH}/${market}?${urlParams}`;
  }
  return `${ROUTER_PATH}/${market}`;
}

export function makeUrlParams(
  {
    market, checkIn, guestCount, language, meals, priceMin, priceMax,
    noPets, laundry, smoking, startIndex, geoBounds, followMap,
  },
  currency,
  mapViewGeoBounds = null,
): string {
  const searchParams = new URLSearchParams();
  if (language) {
    searchParams.set("lng", language);
  }
  if (currency) {
    searchParams.set("ccy", currency);
  }
  if (checkIn) {
    searchParams.set("checkIn", marshalDate(checkIn));
  }
  if (guestCount) {
    searchParams.set("guestCount", guestCount);
  }
  if (mapViewGeoBounds && followMap) {
    // use the geobounds defined by the mapview when we're following the map
    addGeoBoundsParams(searchParams, mapViewGeoBounds);
  } else if (geoBounds) {
    addGeoBoundsParams(searchParams, geoBounds);
  }
  if (!followMap) {
    searchParams.set("followMap", "0");
  }
  if (priceMin > 0) {
    searchParams.set("priceMin", priceMin);
  }
  if (priceMax > 0) {
    searchParams.set("priceMax", priceMax);
  }
  if (meals) {
    meals.forEach(meal => searchParams.append("meals[]", meal));
  }
  if (noPets) {
    searchParams.set("noPets", "1");
  }
  if (laundry) {
    searchParams.set("laundry", "1");
  }
  if (smoking === "non_smoking" || smoking === "smoking") {
    searchParams.set("smoking", smoking);
  }
  if (startIndex > 0) {
    searchParams.set("startIndex", startIndex);
  }
  return searchParams.toString();
}
