import React from "react";
import LinkPile from "./LinkPile";

export default function RegionsInCountry(
  { country, regionsInCountry, regionNames, language, currency, setPlaceSlug, t },
) {
  const h2Label = t(
    "Destination.RegionsInCountry",
    { regionNames: t(`RegionNames.${regionNames}`), country },
  );
  return <>
    <h2>{h2Label}</h2>
    <LinkPile
      items={regionsInCountry}
      clipCount={18}
      language={language}
      currency={currency}
      setPlaceSlug={setPlaceSlug}
      t={t}
    />
  </>;
}
