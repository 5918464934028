import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { formatCurrency, formatNumber } from "./common/money";
// import common_en from "./translations/en/common.json";
// import common_ko from "./translations/ko/common.json";
// import markets_en from "./translations/en/markets.json";
// import markets_ko from "./translations/ko/markets.json";

import Backend from "i18next-http-backend";
//import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

//i18n
//  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
//  // learn more: https://github.com/i18next/i18next-http-backend
//  .use(Backend)
//  // detect user language
//  // learn more: https://github.com/i18next/i18next-browser-languageDetector
//  .use(LanguageDetector)
//  // pass the i18n instance to react-i18next.
//  .use(initReactI18next)
//  // init i18next
//  // for all options read: https://www.i18next.com/overview/configuration-options
//  .init({
//    fallbackLng: 'en',
//    debug: true,
//
//    interpolation: {
//      escapeValue: false, // not needed for react as it escapes by default
//    }
//  });

// const constructLoadPath = (languages, namespaces) => {
//   if (namespaces[0] === 'markets'){
//     return "https://api.local.homestaybay.com:8843/ui/translations?lng={{lng}}&ns={{ns}}";
//     // return 'http://localhost:8080/api/i18n/{{lng}}/'
//   }
//   return `./translations/${languages[0]}/${namespaces[0]}.json`;
//   // return '/translations/' + languages.join('+') + '/' + namespaces.join('+') + '.json';
// }

// TODO collapse these two structures into a single one
export const SUPPORTED_LANGUAGES = ["en", "ko", "ja", "zh", "es"];
export const LOCALES = {
  "en": "English",
  "es": "español",
  "ko": "한국어",
  "ja": "日本語",
  "zh": "简化",
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init(
    {
      interpolation: {
        escapeValue: false,  // React already does escaping
        format: (value, format, lng, { currency }) => {
          switch (format) {
            case "uppercase":
              return value.toUpperCase();
            case "price":
              return formatCurrency(value, currency, lng);
            case "number":
              return formatNumber(value, lng);
            default:
              return value;
          }
        },
      },
      fallbackLng: "en",
      supportedLngs: SUPPORTED_LANGUAGES,
      // resources: {
      //   en: { common: common_en, markets: markets_en },
      //   ko: { common: common_ko, markets: markets_ko },
      // },
      detection: {
        // a cookie is set when the user selects a different language
        order: ["querystring", "cookie", "navigator"],
        lookupQuerystring: "lng",
        lookupCookie: "lng",
      },
      preload: ["en"],
      backend: {
        debug: true,
        crossDomain: true,
        allowMultiLoading: true,
        loadPath: `${process.env.REACT_APP_API_SERVER_BASE_URL}ui/translations?lng={{lng}}&ns={{ns}}`,
        // TODO addPath for error reporting of missing resources
      },
      queryStringParams: { v: "0.0.1" },
      // debug: true,
    },
  );

export default i18n;
