import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import LogInDialog from "./LogInDialog.jsx";
import SignUpDialog from "./SignUpDialog.jsx";
import Link from "@material-ui/core/Link";
import { GlobalHotKeys } from "react-hotkeys";
import {
  makeGuestListLink,
  makeHelpLink,
  makeMyAccountLink,
  makeRegisterHostLink,
} from "../common/links";
import { resolveMarket } from "../common/market";
import { NAVBAR_SHOW_HOST_SIGNUP } from "../common/widths";
import LanguageDialog from "./LanguageDialog";
import CurrencyDialog from "./CurrencyDialog";
import { useViewport } from "./ViewportProvider";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


export default function NavMainNavigationMenu(
  {
    user,
    onSignIn,
    onSignOut,
    locale,
    showLocaleOptions,
    locales,
    market,
    markets,
    language,
    currencies,
    currency,
    onLanguageChange,
    onCurrencyChange,
    onSignUpGuestOpen,
    t,
  },
) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [logInOpen, setLogInOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const { width } = useViewport();

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignUpOpen = () => {
    handleMenuClose();
    setSignUpOpen(true);
  };

  const handleSignUpGuestOpen = (e) => {
    handleMenuClose();
    onSignUpGuestOpen(e, market, markets);
  };

  const handleSignUpClose = () => {
    setSignUpOpen(false);
  };

  const handleLogInOpen = () => {
    handleMenuClose();
    setLogInOpen(true);
  };

  const handleLogInClose = () => {
    setLogInOpen(false);
  };

  const handleSignOut = () => {
    onSignOut();
  };

  const handleLanguageOpen = () => {
    handleMenuClose();
    setLanguageOpen(true);
  };

  const handleLanguageClose = () => {
    setLanguageOpen(false);
  };

  const handleCurrencyOpen = () => {
    handleMenuClose();
    setCurrencyOpen(true);
  };

  const handleCurrencyClose = () => {
    setCurrencyOpen(false);
  };

  const keyHandlers = {
    FOCUS_MENU: event => {
      handleButtonClick(event);
    },
  };

  return (
    <GlobalHotKeys handlers={keyHandlers}>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        aria-label={t("MainNavigationMenu")}
        variant="contained"
        color="inherit"
        onClick={handleButtonClick}
      >
        <MenuIcon/>
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {
          user === null && <StyledMenuItem onClick={handleSignUpOpen}>
            <ListItemText primary={t("SignUp")}/>
          </StyledMenuItem>
        }
        {
          user === null && <StyledMenuItem onClick={handleLogInOpen}>
            <ListItemText primary={t("LogIn")}/>
          </StyledMenuItem>
        }
        {
          user !== null && <StyledMenuItem
            component={Link}
            href={makeMyAccountLink(locale)}
            target="_blank"
          >
            <ListItemText primary={t("MyAccount")}/>
          </StyledMenuItem>
        }
        {
          user !== null && <StyledMenuItem onClick={handleSignOut}>
            <ListItemText primary={t("LogOut")}/>
          </StyledMenuItem>
        }
        <Divider/>
        {
          user === null && <StyledMenuItem
            component={RouterLink}
            to={makeRegisterHostLink(locale)}
          >
            <ListItemText primary={t("HostYourHome")}/>
          </StyledMenuItem>
        }
        <StyledMenuItem
          component={Link}
          href={
            makeGuestListLink(
              resolveMarket(user),
              locale,
            )
          }
          target="_blank"
        >
          <ListItemText primary={t("FindAGuest")}/>
        </StyledMenuItem>
        {
          width < NAVBAR_SHOW_HOST_SIGNUP && <StyledMenuItem
            component={RouterLink}
            to={makeHelpLink(user, locale)}
          >
            <ListItemText primary={t("Help")}/>
          </StyledMenuItem>
        }
        {
          // These are each added individually to prevent the warning:
          // The Menu component doesn't accept a Fragment as a child.
          // Consider providing an array instead.
          showLocaleOptions && <Divider/>
        }
        {
          showLocaleOptions && <StyledMenuItem onClick={handleLanguageOpen}>
            <ListItemText primary={t("Language")}/>
          </StyledMenuItem>
        }
        {
          showLocaleOptions && <StyledMenuItem onClick={handleCurrencyOpen}>
            <ListItemText primary={t("Currency")}/>
          </StyledMenuItem>
        }
      </StyledMenu>
      <LogInDialog
        t={t}
        onClose={handleLogInClose}
        open={logInOpen}
        onSignIn={onSignIn}
        locale={locale}
      />
      <SignUpDialog
        t={t}
        onClose={handleSignUpClose}
        open={signUpOpen}
        openGuest={handleSignUpGuestOpen}
        locale={locale}
      />
      <LanguageDialog
        t={t}
        locales={locales}
        onClose={handleLanguageClose}
        open={languageOpen}
        value={language}
        setValue={onLanguageChange}
      />
      <CurrencyDialog
        t={t}
        currencies={currencies}
        onClose={handleCurrencyClose}
        open={currencyOpen}
        value={currency}
        setValue={onCurrencyChange}
      />
    </GlobalHotKeys>
  );
}
