import { AxiosInstance, Method } from "axios";
import { useEffect, useState } from "react";
import { makeApiserverClient } from "./factory";

// Async POST request to apiserver. Returns object containing 'error' and
// 'result', of which one is always null.
export async function queryService(client: AxiosInstance, method: Method, path: string, data) {
  // Load async data.
  try {
    const response = await client.request({ method, url: path, data });
    if (response.status !== 200) {
      console.log("!200", response);
      const error = { "response": response };
      return { result: null, error };
    }

    const result = response.data;
    return { result, error: null };
  } catch (error) {
    console.log("exc", error);
    return { result: null, error };
  }
}

export function useFetch(path: string, initialData, initialError) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(initialError);
  useEffect(() => {
    const fetchData = async function () {
      const client = makeApiserverClient(
        process.env.REACT_APP_API_SERVER_BASE_URL,
      );
      setLoading(true);
      const { result, error } = await queryService(client, "GET", path);
      setData(result);
      setError(error);
      setLoading(false);
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, [path]);
  return { loading, data, error };
}

export function makeErrorStringFromUndefinedResponse(error): string {
  // Network error such as invalid SSL cert will not contain a response.
  const { config, message } = error;
  const { baseURL, method, url } = config;
  const r = `${method.toUpperCase()} ${baseURL}${url}`;
  return `Failed to obtain response from request ${r}: ${message}`;
}
