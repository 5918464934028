import { makeApiserverClient } from "./factory";

export default async function signOut(): Promise<boolean> {
  const client = makeApiserverClient(
    process.env.REACT_APP_JAVA_API_SERVER_BASE_URL,
    {},
  );
  console.log("signing user out");
  try {
    const response = await client.post("/auth/sign-out", {});
    if (response.status === 200) {
      console.log("user signed out");
      return true;
    }
    console.error("network error signing out:", response);
    return false;
  } catch (error) {
    console.error("network error signing out", error);
    return false;
  }
};
