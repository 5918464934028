import React from "react";
import { Link } from "react-router-dom";
import { encodeUrlParams } from "../../common/url";
import { DESTINATION_PATH } from "../../constants/app";

export default function PlaceLink(
  {
    slug,
    urlParams, // should be an object generally containing lng+ccy
    setPlaceSlug = null,
    children,
    ...params
  },
): Link {
  return <Link
    to={{
      pathname: `${DESTINATION_PATH}/${slug}`,
      search: encodeUrlParams(urlParams),
    }}
    onClick={
      () => {
        if (setPlaceSlug) {
          setPlaceSlug(slug);
        }
        window.scrollTo(0, 0);  // scroll to the top when re-rendering search results
      }
    }
    id={`place-link-${slug}`}
    {...params}
  >{children}</Link>;
}
