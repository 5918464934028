interface PlacePageData {
  description?: string;
  descriptionMetaLen?: number;
}

// {lng: "en"} would provide the English string regardless of the locale.
type T = (key: string | string[], options?: object) => string;

export function makeMetaDescription(placePageData: PlacePageData, t: T): string {
  if (!placePageData) {
    return t("Html.SearchMetaDescription");
  }
  const {description = null, descriptionMetaLen = 0} = placePageData;
  if (!description || !descriptionMetaLen) {
    return t("Html.SearchMetaDescription");
  }
  return `${t("Html.SearchMetaDescription1")}${description.slice(0, descriptionMetaLen)}`;
}
