import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { MEDIA_MIN_WIDTH_1128, MEDIA_MIN_WIDTH_768 } from "../../common/widths";
import { useViewport } from "../../component/ViewportProvider";
import { MAIN } from "../../constants/styles.ts";
import ArticleBoxes from "./ArticleBoxes";
import HelpJumbotron from "./HelpJumbotron";
import TopicsGrid from "./TopicsGrid";
import {
  ARTICLE_LINK,
  BOX_LINK,
  BOX_LINK_ARROW,
  EXCERPT,
  H1_CENTER,
  HELP_LINK,
} from "./styles";

const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      root: {
        flexGrow: 1,
      },
      "h1": H1_CENTER,
      helpLink: HELP_LINK,
      boxLink: BOX_LINK,
      boxLinkArrow: BOX_LINK_ARROW,
      excerpt: EXCERPT,
      articleLink: ARTICLE_LINK,
      tabs: {
        position: "absolute",
        bottom: "0",
        display: "flex",
      },
      main: MAIN(),
      helpHomeH2: {
        // centered header tag on Help home page
        textAlign: "center",
        // fontSize: "2.5rem",
      },
    },
  ),
);

function ArticleGrid({ articles, setPath, urlParams, classes }) {
  // 1 or 2 columns depending on the viewport width exceeding the 'md' breakpoint
  const theme = useTheme();
  const { width } = useViewport();
  const n = width < theme.breakpoints.values["md"] ? 1 : 2;
  const i = (articles.length + 1) / n;
  return <Grid container spacing={1}>
    {
      Array.from(Array(n)).map(
        (_, column: number) => <Grid item xs={12} md={6}>
          <ArticleBoxes
            articles={articles.slice(column * i, (column + 1) * i)}
            setPath={setPath}
            urlParams={urlParams}
            classes={classes}
          />
        </Grid>,
      )
    }
  </Grid>;
}

function GettingStartedSection({ articles, setPath, urlParams, classes, t }) {
  return <>
    <h2 className={classes.helpHomeH2}>{t("GuidesForGettingStarted")}</h2>
    <ArticleGrid
      articles={articles}
      setPath={setPath}
      urlParams={urlParams}
      classes={classes}
    />
  </>;
}

function PopularSection({ articles, setPath, urlParams, classes, t }) {
  return <>
    <h2 className={classes.helpHomeH2}>{t("PopularHelpArticles")}</h2>
    <ArticleGrid
      articles={articles}
      setPath={setPath}
      urlParams={urlParams}
      classes={classes}
    />
  </>;
}

export function HelpContent(
  {
    data,
    setPath,
    language,
    currency,
    audience,
    handleAudienceChange,
    t,
  },
) {
  const classes = useStyles();
  const { topics, audiences, gettingStarted = {}, popular = {} } = data;
  const urlParams = { lng: language, ccy: currency, audience };
  const gettingStartedForAudience = gettingStarted[audience];
  const popularForAudience = popular[audience];
  return (
    <>
      <HelpJumbotron
        audience={audience}
        onAudienceChange={handleAudienceChange}
        hostsLabel={audiences.hosts}
        guestsLabel={audiences.guests}
        classes={classes}
        t={t}
      />
      <main className={classes.main}>
        {
          gettingStartedForAudience && <GettingStartedSection
            articles={gettingStartedForAudience}
            setPath={setPath}
            urlParams={urlParams}
            classes={classes}
            t={t}
          />
        }
        {
          popularForAudience && <PopularSection
            articles={popularForAudience}
            setPath={setPath}
            urlParams={urlParams}
            classes={classes}
            t={t}
          />
        }
        <h2 className={classes.helpHomeH2}>{t("AllHelpTopics")}</h2>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <TopicsGrid
              topics={topics}
              urlParams={urlParams}
              setPath={setPath}
              audience={audience}
              classes={classes}
            />
          </Grid>
        </Box>
      </main>
    </>
  );
}
