import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles";
import { Add, PeopleAlt, Star, TrendingUp } from "@material-ui/icons";
import { makeRegisterHostLink } from "../../common/links";
import Insight from "../../component/Insight";
import { MAIN_H3 } from "../../constants/styles";

const useStyles = makeStyles(
  (theme: Theme) => (
    {
      hostSignUp: {
        // backgroundColor: "#f8f8f8",
        // backgroundColor: "#f0f0f0",
        // backgroundColor: "#fff6e2",
        // backgroundColor: "#ffefd5",
        backgroundColor: "#f8f0e3", // off white
        borderRadius: "6px",
        display: "block",
        margin: "3rem 0 20px 0",
        padding: "20px 20px",
        "& a": {
          color: "#fff",
          margin: "18px 0 0 0",
        },
      },
      textleaf: {
        ...MAIN_H3,
        color: "#707070",
        marginBottom: "10px",
      },
      hostSignUpGrid: {
        padding: "1rem 0",
      },
    }
  ),
);

export default function HostSignUp({ lng, t }) {
  const classes = useStyles();
  const hostReasons = [
    { icon: <TrendingUp/>, title: t("hostSignUp.ei1"), value: t("hostSignUp.ei2") },
    { icon: <Star/>, title: t("hostSignUp.hc1"), value: t("hostSignUp.hc2") },
    { icon: <PeopleAlt/>, title: t("hostSignUp.df1"), value: t("hostSignUp.df2") },
    { icon: <Add/>, title: t("hostSignUp.lf1"), value: t("hostSignUp.lf2") },
  ];

  // The h2 has a hardcoded margin to override the h2 margin set by the Content parent.
  return <div className={classes.hostSignUp}>
    <h2 style={{ margin: 0 }}>{t("hostSignUp.h1")}</h2>
    <h3 className={classes.textleaf}>{t("hostSignUp.h2")}</h3>
    <Grid
      container
      spacing={2}
      direction="row"
      justifycontent="space-evenly"
      alignItems="center"
      className={classes.hostSignUpGrid}
    >
      {
        hostReasons.map(
          (item, i: number) => {
            return <Grid
              item xs={12} sm={12} md={6} lg={6}
              key={`hostReason-${i}`}
            >
              <Insight item={item} noWrap={false}/>
            </Grid>;
          },
        )
      }
    </Grid>
    <Button
      component={RouterLink}
      to={makeRegisterHostLink(lng)}
      variant="contained"
      color="primary"
      disableElevation
      aria-label={t("JoinAsAHost")}
    >
      {t("JoinAsAHost")}
    </Button>
  </div>;
}
