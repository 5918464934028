import React from "react";
import { Trans } from "react-i18next";
import PlaceLink from "./PlaceLink";

function makeStrArgs(placePageData) {
  const { breadcrumbs, localCurrency } = placePageData;
  return { name: breadcrumbs[0].name, currency: localCurrency };
}

function makeQuestionHowMuch(placePageData, t) {
  const {
    rateMonthlyNoMeals = null,
    rateMonthlyWithMeals = null,
    rateNightlyNoMeals = null,
    rateNightlyWithMeals = null,
  } = placePageData;
  const withMeals = !!(rateMonthlyWithMeals && rateNightlyWithMeals);
  const noMeals = !!(rateMonthlyNoMeals && rateNightlyNoMeals);
  if (!withMeals && !noMeals) {
    return null;
  }

  const strArgs = makeStrArgs(placePageData);
  return {
    question: t("Destination.Q.HowMuch", strArgs),
    answer: <p>
      {
        withMeals && t(
          "Destination.Q.HowMuchWithMeals",
          {
            ...strArgs,
            ...{
              perMonth: rateMonthlyWithMeals,
              perNight: rateNightlyWithMeals,
            },
          },
        )
      } {
      noMeals && t(
        "Destination.Q.HowMuchNoMeals",
        {
          ...strArgs,
          ...{
            perMonth: rateMonthlyNoMeals,
            perNight: rateNightlyNoMeals,
          },
        },
      )
    }
    </p>,
  };
}

function makeQuestionCouples(placePageData, t) {
  const { homestaysWelcomingCouples = null } = placePageData;
  if (!homestaysWelcomingCouples) {
    return null;
  }

  const strArgs = makeStrArgs(placePageData);
  return {
    question: t("Destination.Q.Couples", strArgs),
    answer: <>
      <p>
        {t("Destination.Q.CouplesHosts")}
        {" "}
        {t("Destination.Q.CouplesFoundHosts", { count: homestaysWelcomingCouples.length })}
      </p>
      <ul>
        {
          // The whitespace before <a> below is necessary or a link isn't rendered.
          homestaysWelcomingCouples.map(
            homestay => <li key={`couples-homestay-${homestay.uid}`}>
              <Trans
                i18nKey={
                  homestay.nbrhood ? "Destination.Q.CouplesHomestayWithLocality" : "Destination.Q.CouplesHomestay"
                }
                values={{
                  homestayName: homestay.name,
                  name: homestay.city,
                  localityName: homestay.nbrhood,
                }}
                t={t}
              > <a itemScope
                   itemType="https://schema.org/LodgingBusiness"
                   itemProp="url"
                   href={homestay.url}
                   target="_blank"
                // id={`couples-homestay-${homestay.uid}`}
              />
              </Trans>
            </li>,
          )
        }
      </ul>
    </>,
  };
}

function makeQuestionBestArea(placePageData, language, currency, setPlaceSlug, t) {
  const { cityLocalities = null } = placePageData;

  if (!cityLocalities) {
    return null;
  }

  // Sort mutates the array so copy first.
  const cityLocalitiesSorted = [...cityLocalities].sort(
    (a, b) => b.count - a.count,
  );
  const strArgs = makeStrArgs(placePageData);
  return {
    question: t("Destination.Q.BestArea", strArgs),
    answer: <>
      <p>{t("Destination.Q.BestAreaPopular", strArgs)}</p>
      <ul>
        {
          cityLocalitiesSorted.slice(0, 4).map(
            locality => <li key={`area-locality-li-${locality.slug}`}>
              <PlaceLink
                slug={locality.slug}
                urlParams={{ lng: language, ccy: currency }}
                setPlaceSlug={setPlaceSlug}
              >
                {locality.name}
              </PlaceLink>
            </li>,
          )
        }
      </ul>
    </>,
  };
}

export default function FAQ({ placePageData, language, currency, setPlaceSlug, classes, t }) {
  const questions = [
    makeQuestionHowMuch(placePageData, t),
    makeQuestionCouples(placePageData, t),
    makeQuestionBestArea(placePageData, language, currency, setPlaceSlug, t),
  ].filter(q => q); // remove nulls

  if (questions.length === 0) {
    return null;
  }

  const { breadcrumbs } = placePageData;
  const placeName = breadcrumbs[0].name;
  const strArgs = { name: placeName };
  return <div itemScope itemType="https://schema.org/FAQPage">
    <h2>{t("Destination.Questions", strArgs)}</h2>
    {
      questions.map(
        (item, i) => <div
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
          key={`question-${i}`}
        >
          <h3 itemProp="name" className={classes.h3}>{item.question}</h3>
          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
            <div itemProp="text">{item.answer}</div>
          </div>
        </div>,
      )
    }
  </div>;
}
