import React from "react";

function Checkbox({ name, label, isSelected, onCheckboxChange }) {
  return (
    <div className="form-check">
      <label>
        <input
          type="checkbox"
          name={name}
          checked={isSelected}
          onChange={onCheckboxChange}
          className="form-check-input"
        />
        {label}
      </label>
    </div>
  );
}

export default Checkbox;