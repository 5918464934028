import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Jumbotron from "../../common/Jumbotron";
import { MEDIA_MIN_WIDTH_768 } from "../../common/widths";
import { H3, MAIN } from "../../constants/styles.ts";
import AboutCity from "./AboutCity";
import { LandingPageBreadcrumb } from "./Breadcrumbs";
import { CityPile } from "./CityPile";
import FAQ from "./FAQ";
import HomestayCarousel from "./HomestayCarousel";
import NearbyCities from "./NearbyCities";
import NoHomestays from "./NoHomestays";
import OtherNeighborhoods from "./OtherNeighborhoods";
import RegionsInCountry from "./RegionsInCountry";
import Stats from "./Stats";

const styles = theme => ({
  main: MAIN(),
  h3: H3,
  nomatch: {
    margin: "2rem 0 1.5rem 0",
    fontSize: "1rem",
    [MEDIA_MIN_WIDTH_768]: {
      fontSize: "1.4rem",
    },
  },
  description: {
    marginTop: "10px",
    marginBottom: 0,
    fontSize: "0.85rem",
  },
});

// add to <head> section:
// <meta name="geo.region" content="country-subdivision">  e.g., "US-MN"
// <meta name="geo.position" content="latitude;longitude">
// <meta name="geo.placename" content="Place Name">
// <meta name="ICBM" content="44.980257, -93.270034" />  -- is this legit?
export function Content(
  {
    t,
    language,
    i18n,
    currency,
    classes,
    placeSlug,
    placePageData,
    setPlaceSlug,
  },
) {
  const {
    breadcrumbs,
    description = null,
    citiesInRegion = null,
    regionsInCountry = null,
    cityLocalities = null,
    homestayCount,
    homestaysFeatured = null,
    homestaysNotFeatured = null,
    homestaysOfferingMeals = null,
    photo,
    localCurrency,
    nearbyCities = null,
    regionNames = null,
  } = placePageData;
  const { name: placeName } = breadcrumbs[0];

  // TODO: Section: Stay near {city}'s top universities, colleges and language schools
  return (
    <>
      <Jumbotron
        h1Text={t("Destination.BestHomestaysIn", { name: placeName })}
        h2Text={t("Destination.WeFound", { count: homestayCount })}
        photo={photo}
        photoAlt={placeName}
        t={t}
      />
      <main className={classes.main}>
        <LandingPageBreadcrumb
          nodes={breadcrumbs}
          urlParams={{ lng: language, ccy: currency }}
          setPlaceSlug={setPlaceSlug}
          t={t}
        />
        {description && <p className={classes.description}>{description}</p>}
        {
          !homestayCount && breadcrumbs.length > 1 && <NoHomestays
            placeName={placeName}
            parent={breadcrumbs[1]}
            language={language}
            currency={currency}
            setPlaceSlug={setPlaceSlug}
            classes={classes}
            t={t}
          />
        }
        {
          homestaysFeatured && <>
            <h2>{t("Destination.FeaturedHomestays", { name: placeName })}</h2>
            <HomestayCarousel
              homestays={homestaysFeatured}
              billingPeriod="MONTH"
              currency={localCurrency}
              keyPrefix="featured"
              t={t}
            />
          </>
        }
        {
          // Display an alternate carousel when no homestays are featured.
          homestaysNotFeatured && <>
            <h2>{t("Destination.FindBestHomestay", { name: placeName })}</h2>
            <HomestayCarousel
              homestays={homestaysNotFeatured}
              billingPeriod="MONTH"
              currency={localCurrency}
              keyPrefix="best"
              t={t}
            />
          </>
        }
        {/* TODO map user can click on to navigate to map search page */}
        <h2>{t("Destination.About", { name: placeName })}</h2>
        {/* For locality: Good to know about Hastings-Sunrise in Vancouver */}
        {/* or: Vancouver at a glance */}
        <AboutCity currency={currency} placePageData={placePageData} t={t} i18n={i18n}/>

        <Stats
          placeName={placeName}
          placePageData={placePageData}
          t={t}
          i18n={i18n}
        />

        {
          citiesInRegion && <>
            <h2>{t("Destination.PopularDestinations", { name: placeName })}</h2>
            <CityPile
              cities={citiesInRegion}
              urlParams={{ lng: language, ccy: currency }}
              setPlaceSlug={setPlaceSlug}
              key={`nearby-${placeSlug}`}
            />
          </>
        }
        {
          regionsInCountry && <RegionsInCountry
            country={placeName}
            regionsInCountry={regionsInCountry}
            regionNames={regionNames}
            language={language}
            currency={currency}
            setPlaceSlug={setPlaceSlug}
            t={t}
          />
        }
        {
          homestaysOfferingMeals && <>
            <h2>{t("Destination.OfferingMeals", { name: placeName })}</h2>
            <HomestayCarousel
              homestays={homestaysOfferingMeals}
              billingPeriod="MONTH"
              currency={localCurrency}
              keyPrefix="meals"
              t={t}
            />
          </>
        }
        {
          // homestaysWelcomingPets && <>
          //   <h2>Pet friendly {placeName} homestays</h2>
          //   {/* Hosts who are happy to welcome a guest with a pet */}
          //   <HomestayCarousel
          //     homestays={homestaysWelcomingPets}
          //     billingPeriod="MONTH"
          //     currency={localCurrency}
          //     t={t}
          //   />
          // </>
        }

        {
          // TODO Explore communities surrounding {NeighbourhoodName}
          // Find nearby homestays which might match your needs
          // Search for localities (not necessarily part of the same city) with centroids within N km.
          // Sort by count descending?
          cityLocalities && <>
            <h2>{t("Destination.CityLocalities", { name: placeName })}</h2>
            <OtherNeighborhoods
              localities={cityLocalities}
              language={language}
              currency={currency}
              setPlaceSlug={setPlaceSlug}
              t={t}
              key={`localities-${placeSlug}`}
            />
          </>
        }

        {
          nearbyCities && <>
            <h2>{t("Destination.OtherCities", { name: placeName })}</h2>
            <NearbyCities
              cities={nearbyCities}
              language={language}
              currency={currency}
              setPlaceSlug={setPlaceSlug}
              t={t}
            />
          </>
        }

        <FAQ
          placePageData={placePageData}
          language={language}
          currency={currency}
          setPlaceSlug={setPlaceSlug}
          classes={classes}
          t={t}
        />
      </main>
    </>
  );
}

export default withStyles(styles)(Content);
