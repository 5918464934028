import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    marginTop: 50,
    paddingBottom: 20,
  },
  avatarContainer: {
    height: 100,
    width: 100,
    position: "absolute",
    top: -50,
    zIndex: 1,
    borderRadius: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: 90,
      width: 90,
      top: -45,
    },
    [theme.breakpoints.down("xs")]: {
      height: 80,
      width: 80,
      top: -40,
    },
  },
  avatar: {
    width: "85%",
    height: "85%",
    backgroundColor: "#06232a",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "& > p": {
      margin: 0,
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSize: "3.5rem",
      fontWeight: 700,
      color: "#fda12e",
      [theme.breakpoints.down("md")]: {
        fontSize: "3rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
  },
  body: {
    marginTop: 55,
    flex: 1,
    position: "relative",
    margin: "0.5em",
    padding: "0.5em 2.5em 0.5em 2.5em",
    "& > p": {
      "& footer": {
        padding: "0.5em 0.2em 0 0",
        textAlign: "right",
      },
      "& cite:before": {
        content: `"— "`,
        color: "#888",
      },
      "&:before": {
        fontFamily: "Georgia, serif",
        content: `"\\201C"`,
        color: "#6ac82c",
        fontSize: "5rem",
        position: "absolute",
        lineHeight: 1,
        top: 0,
        left: 0,
      },
      "&:after": {
        fontFamily: "Georgia, serif",
        content: `"\\201D"`,
        color: "#6ac82c",
        position: "absolute",
        // display: block; don't use this, it raised the quote too high from the bottom - defeated line-height?
        float: "right",
        fontSize: "5rem",
        lineHeight: 1,
        right: 0,
        bottom: "-0.5em",
      },
      margin: 0,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 55,
      fontSize: "0.98rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 45,
      fontSize: "0.94rem",
    },
  },
}));

export const TestimonialCard = (props) => {
  const classes = useStyle();
  const { className, cite, image, content, ...innerProps } = props;
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item className={[classes.root, className]} {...innerProps}>
        <Grid item className={[classes.avatarContainer, "testimonial-avatar-container"].join(" ")}>
          <img className={[classes.avatar, "testimonial-avatar"].join(" ")} src={image} alt={cite}/>
        </Grid>
        <Grid item xs={11} className={[classes.body, "testimonial-body"].join(" ")}>
          <p>
            {content}
            <footer>
              <cite>{cite}</cite>
            </footer>
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

TestimonialCard.propTypes = {
  cite: PropTypes.string.isRequired,
  image: PropTypes.string,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};
