import styled from "styled-components";

export const ColumnWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  left: -2px;
  position: relative;
`;

export const Column = styled.span`
  position: relative;
  bottom: -6px;
  display: inline-block;
  width: ${props => props.width};
  height: ${props => `${props.height}px`};
  background: rgba(118, 118, 118, 0.5);
`;

export const Histogram = styled.div`
  position: relative;
`;
