import React from "react";
import SearchPriceOption from "../../component/SearchPriceOption.jsx";
import SearchMealsOption from "./SearchMealsOption.jsx";
import SearchMoreOption from "./SearchMoreOption.jsx";

export default function SecondarySearch(props) {
  const { searchResult, params, currency, onStateChange, t } = props;
  const { meals, priceMin, priceMax, noPets, laundry, smoking } = params;
  return (
    <div class="m-4">
      <SearchPriceOption
        searchResult={searchResult}
        priceMin={priceMin}
        priceMax={priceMax}
        currency={currency}
        onStateChange={onStateChange}
        t={t}
      />
      <SearchMealsOption
        meals={meals}
        onStateChange={onStateChange}
        t={t}
      />
      <SearchMoreOption
        noPets={noPets}
        laundry={laundry}
        smoking={smoking}
        onStateChange={onStateChange}
        t={t}
      />
    </div>
  );
}
