import React from "react";
import { makeErrorStringFromUndefinedResponse } from "../api/request";

export default function Error({ error }) {
  const { response } = error;
  if (!response) {
    return <>{makeErrorStringFromUndefinedResponse(error)}</>;
  }
  const { status, data } = response;
  return (
    <>
      Error requesting data from server:
      <ul>
        <li>Server responded with status {status}.</li>
        {Object.entries(data).map(([k, v]) => <li>{k}: {v}</li>)}
      </ul>
    </>
  );
}
