import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { formatDateDMY } from "../common/date";
import { withStyles } from "@material-ui/core/styles";
import PrimarySearchDialog from "./PrimarySearchDialog";
import { GlobalHotKeys } from "react-hotkeys";
import { SEARCHBAR_ENTER_DATES_WIDTH } from "../common/widths";

const PRIMARY_SEARCH_MAXWIDTH_PX = 450;

const styles = theme => ({
  littlesearch: {
    backgroundColor: "#fff",
    marginTop: "7px",
    "@media (max-width: 1050px)": { // NAVBAR_HEIGHT_REDUCES
      // 1050 px is when the nav bar height reduces
      marginTop: "2px",
    },
    borderRadius: "24px",
    display: "inline-flex",
    overflow: "hidden",
    alignItems: "center",
    border: "1px solid #ddd",
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    position: "relative",
    "& button": {
      background: "transparent",
      height: "48px",
      padding: "0 16px",
      display: "flex",
      alignItems: "center",
      border: "none",
      cursor: "pointer",
      // zIndex: 10000,
      fontFamily: "Roboto",
      // fontWeight: props => props.selected ? 300 : 400,
      "&:focus": {
        outlineOffset: "-1px",
      },
    },
  },
  buttonContainer: {
    width: "100%",
  },
  description: {
    left: "15px",
    right: "45px",
    position: "absolute",
    textAlign: "center",
    padding: "6px 0",
    height: "48px",
    lineHeight: "1.2em",
  },
  location: {
    margin: 0,
    fontSize: "13px",
    fontWeight: 400,
    color: "#000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (min-width: 400px)": {
      fontSize: "15px",
    },
    "@media (min-width: 440px)": { // SEARCHBAR_ENTER_DATES_WIDTH
      fontSize: "16px",
    },
  },
  options: {
    margin: 0,
    fontSize: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#777",
    "@media (min-width: 440px)": { // SEARCHBAR_ENTER_DATES_WIDTH
      fontSize: "14px",
    },
  },
  searchIcon: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    background: "#6ac82c",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: "10px",
    position: "absolute",
    "& svg": {
      width: "20px",
      height: "20px",
      color: "#fff",
    },
  },
});

function formatLocation(
  market: string | null, countryName: string | null, width: number, t,
): string {
  if (!market) {
    return t("SearchOptions.ChooseDestination");
  }
  const placeName = t(`markets:${market}`);
  if (!countryName || width < 440) {
    return placeName;
  }
  // TODO localize
  // e.g., ja is "country、city"
  return `${placeName}, ${countryName}`;
}

function Options({ market, checkIn, guestCount, locale, width, t, ...props }) {
  if (!market || checkIn || width < SEARCHBAR_ENTER_DATES_WIDTH) {
    return <div {...props}>
      {formatCheckInDate(checkIn, t, locale)}
      <span aria-hidden="true"> · </span>
      {formatGuestCount(guestCount, t)}
    </div>;
  }

  // Show the longer "Enter your dates for availability" when the displayport
  // width allows it and a place has been selected with no check in. Otherwise
  // the more compact form above is rendered.
  return <div {...props}>{t("SearchOptions.EnterDates")}</div>;
}

function formatCheckInDate(checkIn?: Date, t, locale): string {
  if (!checkIn) {
    return t("SearchOptions.AnyDate");
  }
  return formatDateDMY(checkIn, locale);
}

function formatGuestCount(guestCount: number, t): string {
  if (!guestCount) {
    return t("SearchOptions.AddGuests");
  }
  if (guestCount === 1) {
    return t("1Guest");
  }
  return t("NGuests", { n: guestCount });
}

function makePrimarySearchDialogKey(market: string | null): string {
  if (market) {
    return `PrimarySearchDialog_${market}`;
  }
  return "PrimarySearchDialog_nomarket";
}

// Search button which should fit on mobile devices as narrow as 360px. Expands
// with the width of the viewport to a maximum width. As the button gets wider,
// more information is displayed and the text size increases.
function PrimarySearch(
  {
    saveTransKey,
    t,
    locale,
    market, // string | null
    checkIn,
    guestCount,
    onChange,
    markets,
    countryName = null,
    width,
    classes,
  },
) {
  // Where to place focus when opening the dialog.
  const [openFocus, setOpenFocus] = useState(null);

  const handleDialogOpen = focusElement => () => {
    setOpenFocus(focusElement);
  };

  const handleDialogClose = () => {
    setOpenFocus(null);
  };

  const keyHandlers = {
    FOCUS_PRIMARY_SEARCH: event => {
      setOpenFocus("market");
      event.preventDefault();
    },
  };

  const elementWidth = Math.min(width - 130, PRIMARY_SEARCH_MAXWIDTH_PX);
  return (
    <GlobalHotKeys handlers={keyHandlers}>
      <div className={classes.littlesearch} style={{ width: `${elementWidth}px` }}>
        <button onClick={handleDialogOpen("market")} id="searchMarket"
                className={classes.buttonContainer}>
          <div className={classes.description}>
            <div className={classes.location}>
              {formatLocation(market, countryName, width, t)}
            </div>
            <Options
              market={market}
              checkIn={checkIn}
              guestCount={guestCount}
              locale={locale}
              width={width}
              t={t}
              className={classes.options}
            />
          </div>
          <div className={classes.searchIcon}><SearchIcon/></div>
        </button>
      </div>
      <PrimarySearchDialog
        // Key has a special meaning here. By specifying a key, it forces react
        // to rerender this dialog component and therefore discard its internal
        // state. Otherwise, changing the state here from market A to market B
        // will not reset the state inside the search dialog, which will still
        // reference market A. Likewise, this dialog component can be
        // initialized with an undefined market and then have its state reset
        // when a market is identified after querying the apiserver.
        key={makePrimarySearchDialogKey(market)}
        saveTransKey={saveTransKey}
        onClose={handleDialogClose}
        onChange={onChange}
        openFocus={openFocus}
        locale={locale}
        market={market}
        markets={markets}
        checkIn={checkIn}
        guestCount={guestCount}
        t={t}
      />
    </GlobalHotKeys>
  );
}

export default withStyles(styles)(PrimarySearch);
