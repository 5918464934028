import React from "react";

// Reference:
// https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

const viewportContext = React.createContext({});

export const ViewportProvider = ({ children }) => {
  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    // https://medium.com/@renatorib/tackling-responsive-elements-in-react-and-why-ive-created-react-sizes-f7c87e3f9e64
    // import throttle from 'lodash.throttle';
    // return throttle(() => {
    //   this.setState({ isMobile: window.innerWidth < 480 })
    // }, 200);
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // We are dealing with a context instead of a Hook, so instead of returning
  // the width we store the values in the value of the Provider.
  return (
    <viewportContext.Provider value={{ height, width }}>
      {children}
    </viewportContext.Provider>
  );
};

// The "useViewport" hook pulls the width value out of the context.
export const useViewport = () => {
  // We can use the "useContext" Hook to access a context from within another
  // Hook, remember, Hooks are composable!
  const { height, width } = React.useContext(viewportContext);
  return { height, width };
};
