import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TranslateIcon from "@material-ui/icons/Translate";
import LanguageIcon from "@material-ui/icons/Language";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CurrencyDialog from "./CurrencyDialog.jsx";
import LanguageDialog from "./LanguageDialog.jsx";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function NavLocaleSelection(
  { t, locales, language, onLanguageChange, currency, currencies, onCurrencyChange },
) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [currencyOpen, setCurrencyOpen] = useState(false);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageOpen = () => {
    handleMenuClose();
    setLanguageOpen(true);
  };

  const handleLanguageClose = () => {
    setLanguageOpen(false);
  };

  const handleCurrencyOpen = () => {
    handleMenuClose();
    setCurrencyOpen(true);
  };

  const handleCurrencyClose = () => {
    setCurrencyOpen(false);
  };

  return (
    <>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        aria-label="language and currency"
        variant="contained"
        color="inherit"
        onClick={handleButtonClick}
      >
        <LanguageIcon/>
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <StyledMenuItem onClick={handleLanguageOpen}>
          <ListItemIcon>
            <TranslateIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={t("Language")}/>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleCurrencyOpen}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={t("Currency")}/>
        </StyledMenuItem>
      </StyledMenu>
      <LanguageDialog
        t={t}
        locales={locales}
        onClose={handleLanguageClose}
        open={languageOpen}
        value={language}
        setValue={onLanguageChange}
      />
      <CurrencyDialog
        t={t}
        currencies={currencies}
        onClose={handleCurrencyClose}
        open={currencyOpen}
        value={currency}
        setValue={onCurrencyChange}
      />
    </>
  );
}
