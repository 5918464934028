import React, { useEffect, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { makeApiserverClient } from "../../api/factory.js";
import type { AuthenticationResult } from "../../api/login";
import authenticateUser from "../../api/login";
import signOut from "../../api/logout";
import { queryService, useFetch } from "../../api/request.js";
import { geoBoundsEqual } from "../../common/geo";
import { usePrevious } from "../../common/hook";
import { makeMetaDescription } from "../../common/seotags";
import { setLngCookie } from "../../common/state";
import DocumentHead, {
  makeMarketUiName,
  makeTitleFromError,
} from "../../component/DocumentHead";
import Footer from "../../component/Footer";
import Nav from "../../component/Nav.jsx";
import { useViewport } from "../../component/ViewportProvider";
import { DESTINATION_PATH, ROUTER_PATH } from "../../constants/app";
import Content from "../../homestaysearch/component/Content.jsx";
import { LOCALES } from "../../i18n";
import { makeSearchHomestaysRequestData } from "../api";
import { makeUrlParams, parseUrlParams } from "../url";

function makeTitle(market, markets, error, t): string {
  if (!error) {
    return t(
      "Html.SearchMarketTitle",
      { market: makeMarketUiName(market, markets, t) },
    );
  }
  return makeTitleFromError(error, t);
}

// Markets to test canonicalization to the destination page.
const MARKET_DESTINATION_SLUGS = {
  // AU
  "Auckland--New-Zealand": "Auckland--AUK--New-Zealand",
  "Gold-Coast--QLD--Australia": "Gold-Coast-City--QLD--Australia",
  // CA
  "Charlottetown--PE--Canada": "Charlottetown--Queens-County--PE--Canada",
  // NZ
  "Adelaide--SA--Australia": "Adelaide--SA--Australia",
  // US
  "San-Antonio--TX--USA": "San-Antonio--Bexar-County--TX--United-States",
  "Seattle--WA--USA": "Seattle--King-County--WA--United-States",
};

function makeCanonical(marketSlug: string, lng: string, startIndex: number): Object {
  if (marketSlug in MARKET_DESTINATION_SLUGS) {
    return {
      path: `${DESTINATION_PATH}/${MARKET_DESTINATION_SLUGS[marketSlug]}`,
      params: { lng },
    };
  }
  return { path: `${ROUTER_PATH}/${marketSlug}`, params: { lng, startIndex } };
}

export default function SearchPage(
  {
    user,
    t,
    setUser,
    signUpGuestDialog,
    onSignUpGuestOpen,
    i18n,
  },
) {
  const routerParams = useParams();
  const marketParam = routerParams.market || "Vancouver--BC--Canada";
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [market, setMarket] = useState(marketParam);
  const prevMarket = usePrevious(market);
  const { currency: parsedCurrency, ...parsedParams } = parseUrlParams(location, i18n);
  const [currency, setCurrency] = useState(parsedCurrency);
  const [params, setParams] = useState(parsedParams);
  const [state, setState] = useState({
    searchResult: null,
    mapBounds: null,  // initialize to null, set on each map pan/zoom event
  });
  const { height, width } = useViewport();

  const handleMapEventBoundsChange = (geoBounds, mapBounds) => {
    // Set the bounds from the map pan/zoom event and also update the
    // geobounds in the state.
    const x = state.mapBounds;
    const y = params.geoBounds;
    const noChange1 = Boolean(x) && geoBoundsEqual(x, mapBounds);
    const noChange2 = Boolean(y) && geoBoundsEqual(y, geoBounds);
    const change = !noChange1 || !noChange2;
    if (change) {
      handleStateChange({ geoBounds }, { mapBounds });
    }
  };

  const handleStateChange = (newParams, newState, newCurrency: ?string = null, newMarket: ?string = null) => { // TODO rename to handleStateParamsChange
    if (newCurrency) {
      setCurrency(newCurrency);
    }
    if (newMarket) {
      setMarket(newMarket);
    }
    if (newState) {
      // Overwrite current state with new state.
      setState({ ...state, ...newState });
    }
    setLngCookie(newParams);
    setParams(
      {
        ...params,
        // always reset to first page of results unless overridden by newParams
        startIndex: 0,
        ...newParams,
      },
    );
  };

  const setPath = (e: Event, path: string, urlParams: string) => {
    if (path.startsWith(`${ROUTER_PATH}/`)) {
      const slug = path.substr(ROUTER_PATH.length + 1);
      setMarket(slug);
    }
  };


  // Init: query server for currencies and markets once.
  const {
    loading: loadingInit,
    data: uiData,
    error: errorInit,
  } = useFetch("/ui/init?request=currencies,markets");
  const {
    values: { currencies, markets } = { currencies: [], markets: {} },
  } = uiData || {};

  useEffect(
    () => {
      window.scrollTo(0, 0);  // scroll to the top when re-rendering search results
      const { mapViewGeoBounds } = state;  // TODO remove
      const url = makeUrlParams(
        { market, ...params },
        currency,
        mapViewGeoBounds,
      );
      const cur = location.pathname + location.search;
      const target = `${ROUTER_PATH}/${market}?${url}`;
      if (cur !== target) {
        navigate(target);
      }
      // TODO see if this fixes the back button
      // this.props.history.push({
      //   pathname: `./${market}`,
      //   search: `?${url}`,
      // });
    },
    [params],
  );

  // Query for search results whenever the user changes a search parameter.
  useEffect(
    () => {
      const marketChanged = prevMarket !== undefined && prevMarket !== market;
      // noinspection JSIgnoredPromiseFromCall
      fetchResults(marketChanged);
    },
    // only re-run effect if user changes something that requires fetching results again
    // TODO currency should only trigger this if changed by the user, not if the response changes the currency
    [params, market, currency],
  );

  // should only get called once on load, hence empty deps

  const fetchResults = async (marketChanged: boolean) => {
    const client = makeApiserverClient(
      process.env.REACT_APP_API_SERVER_BASE_URL,
      // { Authorization: "Bearer token" }, // auth header goes here
      {},
    );
    const { searchResult: x } = state;
    // If there is no user-provided geobounds but we've previously queried the server, use
    // the geobounds from that previous query.
    let geoBounds = undefined;
    if (x !== null) {
      geoBounds = x.geoBounds;
    }  // TODO use the geobounds function
    const data = makeSearchHomestaysRequestData(
      marketChanged,
      market,
      params,
      currency,
      geoBounds,
      { height, width },
    );
    const { result, error } = await queryService(client, "POST", "/place/searchhomestays", data);
    // TODO handle response count < input start
    // Update state with new data and re-render component.
    if (result) {
      const { fxCurrency = currency } = result;
      setCurrency(fxCurrency);
    }
    setError(error);
    setState({ searchResult: result });
  };

  const onSignIn = async (email: string, password: string): AuthenticationResult => {
    const result = await authenticateUser(email, password);
    setUser(result.user);
    return result.result;
  };

  const onSignOut = async (): boolean => {
    const signedOut = await signOut();
    if (signedOut) {
      setUser(null);
    }
    return signedOut;
  };

  const keyMap = {
    FOCUS_PRIMARY_SEARCH: "/",
    FOCUS_MENU: ".",
  };
  const { searchResult, mapBounds } = state;
  const { countryName, searchPath } = searchResult || {};
  const { language: lng, startIndex } = params;
  return (
    <GlobalHotKeys keyMap={keyMap}>
      <DocumentHead
        title={makeTitle(market, markets, error, t)}
        description={makeMetaDescription(searchResult, t)}
        canonical={makeCanonical(market, lng, startIndex)}
        params={params}
        t={t}
      />
      <Nav
        user={user}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
        t={t}
        saveTransKey="Save"
        i18n={i18n}
        markets={markets}
        market={market}
        countryName={
          countryName || (
            searchPath && searchPath.length > 1 && searchPath[searchPath.length - 1].label
          )
        }
        locales={LOCALES}
        currency={currency}
        params={params}
        currencies={currencies}
        onSignUpGuestOpen={onSignUpGuestOpen}
        onStateChange={handleStateChange}
      />
      {signUpGuestDialog}
      <Content
        t={t}
        params={params}
        market={market}
        currency={currency}
        searchResult={searchResult}
        error={errorInit || error}
        mapBounds={mapBounds}
        onStateChange={handleStateChange}
        onMapEventBoundsChange={handleMapEventBoundsChange}
      />
      <Footer
        user={user}
        placeSlug={null}
        markets={markets}
        setPath={setPath}
        onSignUpGuestOpen={onSignUpGuestOpen}
        t={t}
        locale={i18n.language}
      />
    </GlobalHotKeys>
  );
}

// Enter dates to see full pricing. Additional fees apply. Taxes may be added.
