import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { encodeUrlParams, makeUrl } from "../../common/url";
import InternalLink from "../../component/InternalLink";
import { HELP_PATH, HOME_PATH } from "../../constants/app";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2), // TODO inconsistent with destination breadcrumbs
      marginBottom: theme.spacing(2), // TODO inconsistent with destination breadcrumbs
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    link: {
      fontWeight: 300,
      fontSize: "0.85rem",
      color: "#555",
    },
    leaf: {
      fontWeight: 400,
      fontSize: "0.85rem",
    },
  }),
);

export interface BreadcrumbNode {
  label: string;
  path: string;
}

export interface BreadcrumbsNodes {
  // Ordered from root to leaf.
  nodes: BreadcrumbNode[];
}

export function HelpBreadcrumb(
  { parents, title, urlParams, setPath, t },
) {
  const classes = useStyles();
  const { audience, ...homeUrlParams } = urlParams;
  const structuredBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": t("Home"),
        "item": makeUrl("", homeUrlParams),
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": t("Help"),
        "item": makeUrl("/help", urlParams),
      },
      ...parents.map(
        (node: BreadcrumbNode, i: number) => {
          return {
            "@type": "ListItem",
            "position": i + 3,
            "name": node.label,
            "item": makeUrl(node.path, urlParams),
          };
        },
      ),
      { "@type": "ListItem", "position": parents.length + 3, "name": title },
    ],
  };
  return (
    <div className={classes.root}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredBreadcrumbs) }}
      />
      <Breadcrumbs separator="›" aria-label={t("YouAreHere")} className={classes.link}>
        <RouterLink
          to={{ pathname: HOME_PATH, search: encodeUrlParams(homeUrlParams) }}
          color="inherit"
          className={classes.link}
        >{t("Home")}</RouterLink>
        <InternalLink
          color="inherit"
          path={HELP_PATH}
          setPath={setPath}
          urlParams={urlParams}
          className={classes.link}
        >
          {t("Help")}
        </InternalLink>
        {
          parents && parents.map(
            (node: BreadcrumbNode, i: number) => {
              return <InternalLink
                path={node.path}
                setPath={setPath}
                urlParams={urlParams}
                className={classes.link}
                key={`breadcrumb-link-${i}`}
              >
                {node.label}
              </InternalLink>;
            },
          )
        }
        <Typography
          color="textPrimary"
          className={classes.leaf}
          key={`breadcrumb-title`}
        >
          {title}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}
