import { calculateLonDiff } from "./geo";

// Given a leaflet event, identify the pixel dimensions of the map.
export function getMapPixelSize(map) {
  const p = map.getPixelBounds();
  // p.min is top left (nw), p.max is bottom right (se)
  return { x: p.max.x - p.min.x, y: p.max.y - p.min.y };
}

// Calculate the map's buffer in degrees.
// Inputs specify the size of the plane and buffer in pixels and a span of
// degrees to render within the buffered area.
export function calculateBufferDegrees(degDiff, pixelLength, pixelBuffer) {
  return degDiff / (pixelLength - (2 * pixelBuffer)) * pixelBuffer;
}

// Increase bounds size to form a buffer of 'b' pixels around the given bounds.
// x and y specify the pixel size of the map being viewed.
export function makeBufferedBounds({ x, y }, bounds, b) {
  const n = bounds.NorthWest.lat;
  const w = bounds.NorthWest.lon;
  const s = bounds.SouthEast.lat;
  const e = bounds.SouthEast.lon;
  const lonDiff = calculateLonDiff(w, e);
  const lonBuffer = calculateBufferDegrees(lonDiff, x, b);
  const latBuffer = calculateBufferDegrees(n - s, y, b);

  // TODO handle lon 180
  return [[n + latBuffer, w - lonBuffer], [s - latBuffer, e + lonBuffer]];
}