import { makeErrorStringFromUndefinedResponse } from "../api/request";
import React from "react";

export function FetchError({ error, t, classes }) {
  const { response } = error;
  if (!response) {
    return <div className={classes.error}>
      {makeErrorStringFromUndefinedResponse(error)}
    </div>;
  }
  const { status } = response;
  if (status === 404) {
    return <h1 className={classes.error}>{t("PageNotFound")}</h1>;
  }
  if (status % 100 === 5) {
    return <div className={classes.error}>server error</div>;
  }
  if (status === 500) {
    return <div className={classes.error}>
      Server failure, please retry in a few moments
    </div>;
  }
  return <div className={classes.error}>
    Server reported an unexpected error status: {status}
  </div>;
}
