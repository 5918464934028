import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { encodeUrlParams } from "../../common/url";
import { REGISTER_HOST_PATH } from "../../constants/app";
import PlaceLink from "./PlaceLink";

export default function NoHomestays(
  {
    placeName,
    parent,
    language,
    currency,
    setPlaceSlug,
    classes,
    t,
  },
) {
  const { name, slug } = parent;
  return <>
    <p className={classes.nomatch}>
      {
        t(
          "Destination.NoHomestaysParentArea",
          { name: placeName, parentName: name },
        )
      }
    </p>
    <p>
      {t("Suggestions")}:
      <ul>
        <li>
          <PlaceLink
            slug={slug}
            urlParams={{ lng: language, ccy: currency }}
            setPlaceSlug={setPlaceSlug}
          >
            {t("FindAHomestayIn", { name })}
          </PlaceLink>
        </li>
        <li>
          {t("Destination.AreYouHostIn", { placeName })} <RouterLink
          to={{
            pathname: REGISTER_HOST_PATH,
            search: encodeUrlParams({ lng: language }),
          }}
        >{t("HostYourHome")}</RouterLink>
        </li>
      </ul>
    </p>
  </>;
}
