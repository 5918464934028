import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { Grid, TextField } from "@material-ui/core";
import type { AuthenticationResult } from "../api/login";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeForgotPasswordLink } from "../common/links";


export default function LogInDialog(props) {
  const { t, onClose, open, locale } = props;
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [
    authResult: AuthenticationResult,
    setAuthResult: Dispatch<SetStateAction<boolean>>,
  ] = useState(null);

  const resetState = () => {
    setEmail("");
    setPassword("");
    setValidEmail(true);
    setValidPassword(true);
    setAuthResult(null);
  };

  const handleCancel = () => {
    resetState();
    onClose();
  };

  const handleLogIn = async () => {
    let valid = validEmail;
    if (!password) {
      setValidPassword(false);
      valid = false;
    }
    if (!email) {
      setValidEmail(false);
      valid = false;
    }
    if (!valid) {
      return;
    }
    const authResult = await props.onSignIn(email, password);
    setAuthResult(authResult);
    if (authResult === "authenticated") {
      resetState();
      onClose();
    } else if (authResult === "invalid_credentials") {
      setValidEmail(false);
      setValidPassword(false);
    }
    // no action on network error
  };

  return (
    <Dialog disableBackdropClick open={open} onClose={onClose}>
      <DialogTitle>{t("LogIn")}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                type="email"
                label={t("Email")}
                fullWidth
                name="email"
                variant="outlined"
                value={email}
                onChange={(event) =>
                  setEmail(event.target.value)
                }
                onBlur={
                  (event) => {
                    setValidEmail(!event.target.value || /^.+@.+\..+$/.test(event.target.value));
                  }
                }
                InputLabelProps={{ required: false }}
                error={!validEmail}
                required
                autoFocus
              />
            </Grid>
            <Grid item>
              <TextField
                type="password"
                label={t("Password")}
                fullWidth
                name="password"
                variant="outlined"
                value={password}
                onChange={(event) =>
                  setPassword(event.target.value)
                }
                onBlur={() => setValidPassword(true)}
                error={!validPassword}
                InputLabelProps={{ required: false }}
                required
              />
            </Grid>
            <Grid item>
              <Typography color="error">
                {authResult === "network_error" && "Failed to log in due to network error"}
                {authResult === "invalid_credentials" && "Oops! The email address or password you entered was not correct. Please try again."}
              </Typography>
              {/* TODO i18n here */}
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          component={Link}
          href={makeForgotPasswordLink(locale)}
          target="_blank"
        >
          {t("ForgotPassword")}
        </Button>
        <Button onClick={handleCancel} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleLogIn} color="primary" variant="contained">
          {t("LogIn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
